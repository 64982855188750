export const relatedProductsGql = `... on landingPageContent2023_relatedProducts_BlockType {
    typeHandle
    displayInHeaderQuicklinks
    pageTitle
    relatedProduct {
      ... on product_productPage_Entry {
        uri
        relatedProductTitle
        relatedProductDescriptor
        relatedProductDescription
        relatedProductImage {
          ... on images_Asset {
            lpImageGridMasonry {
              srcWebp
              srcset
            }
          }
        }
      }
      ... on product_productPage2022_Entry {
        uri
        relatedProductTitle
        relatedProductDescriptor
        relatedProductDescription
        relatedProductImage {
          ... on images_Asset {
            lpImageGridMasonry {
              srcWebp
              srcset
            }
          }
        }
      }
    }
  }`;
