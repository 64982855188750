import Vue from 'vue';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';

Vue.use(AirbnbStyleDatepicker, {
  daysShort: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
  colors: {
    selected: 'rgb(179, 0, 0)',
    inRange: 'rgb(237, 0, 0)',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: 'rgb(237, 0, 0)',
    disabled: '#fff',
    hoveredInRange: 'rgba(237, 0, 0, .7)',
  },
});
