import { linksSuperTableGql } from '../../link_field/linksSuperTableGql.js';

export const productTabContentGql = `... on product_contentTab_Entry {
  tabTitle
  openOnMoreResourcesClick
  tabHeader
  contentTab {
    ... on contentTab_text_BlockType {
      typeHandle
      header
      text
      links: ${linksSuperTableGql('contentTabLinks')}
    }
    ... on contentTab_videos_BlockType {
      typeHandle
      header
      videos {
        header: title
        ... on productVideos_Asset {
          caption
          videoId
          videoSource
          galleryThumbnail {
            srcset
            srcsetWebp
          }
          galleryEnlarged {
            srcset
            srcsetWebp
          }
          lpImageGridLandscape{
            srcset
            srcsetWebp
          }
        }
      }
    }
    ... on contentTab_resources_BlockType {
      typeHandle
      header
      resources {
        title
        url
        ... on productVideos_Asset {
          videoId
          videoSource
        }
      }
    }
    ... on contentTab_imageGallery_BlockType {
      typeHandle
      header
      images {
        title
        ... on productImages_Asset {
          caption
          galleryThumbnail {
            srcset
            srcsetWebp
          }
          galleryEnlarged {
            srcset
            srcsetWebp
          }
        }
      }
      galleryLayout
    }
  }
}`;
