export const blogEntryFilterGql = `
  metaImage: blogFeaturedImage {
    ... on blogNews_Asset {
      title
      square: filterSquare {
        srcset
        srcsetWebp
      }
      landscape: relatedEntries {
        srcset
        srcsetWebp
      }
    }
    ... on images_Asset {
      title
      square: aspectRatio11 {
        srcset
        srcsetWebp
      }
    }
  }
  blogFilters(level: 2) {
    slug
    parent {
      slug
    }
  }
  blogFilterPages {
    title
  }
`;
