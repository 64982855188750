import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const textImageGql = `... on landingPageContent_textImage_BlockType {
  typeHandle
  eyebrow
  subheader
  text
  textAlignment
  backgroundOpacity
  ${linksSuperTableGql('textImageButton')}
  image {
    ... on landingPageImages_Asset {
      title
      lpImageTextUncropped {
        srcset
        srcsetWebp
      }
      lpBackgroundSquare {
        srcset
        srcsetWebp
      }
      lpBackgroundWide {
        srcset
        srcsetWebp
      }
    }
    ... on landingPageVideos_Asset {
      title
      videoId
      videoSource
      lpImageTextUncropped {
        srcset
        srcsetWebp
      }
      lpBackgroundSquare {
        srcset
        srcsetWebp
      }
      lpBackgroundWide {
        srcset
        srcsetWebp
      }
    }
  }
  colors
  layout
}`;
