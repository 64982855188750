<template>
  <div class="c_arrow_link">
    <Button
      :is="parsedLink ? 'Button' : 'div'"
      :unstyle="true"
      v-bind="parsedLink ? parsedLink : null"
      class="c_arrow_link__button c_button--bold c_button--pointer"
    >
      <span class="c_arrow_link__button__label"
        ><slot
          ><span v-if="parsedLink && parsedLink.labelText">{{ parsedLink.labelText }}</span></slot
        ></span
      >
      <IconSVG class="c_arrow_link__button__icon" handle="arrow_right" aria-hidden="true" />
    </Button>
  </div>
</template>

<script>
// import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
export default {
  components: {
    Button,
    IconSVG,
  },
  data() {
    return {};
  },
  props: {
    linkField: Object,
    linkJson: String,
  },
  computed: {
    parsedLink() {
      if (this.linkField) {
        return this.linkField;
      } else if (this.linkJson) {
        return JSON.parse(this.linkJson);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.c_arrow_link {
  $self: &;
  display: inline-block;

  @at-root #{$self}__button {
    display: grid;
    grid-gap: px_to_rem(6);
    grid-template-columns: auto px_to_rem(9);
    justify-content: flex-start;
    align-items: center;
    color: $color_red;
    .c_box--gray_247 & {
      color: $color_red_179;
    }
    //text-transform: uppercase;
    @at-root #{&}__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      grid-column: 2;
      grid-row: 1;
    }
    &:hover,
    &:focus {
      color: $color_red_179;
      text-decoration: underline;
      .c_box--gray_247 & {
        color: $color_red;
      }
    }
  }
}
</style>
