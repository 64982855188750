<template>
  <div class="c_search_result">
    <a v-if="name" :href="url || null" class="c_search_result__title" v-html="name"></a>
    <a v-if="url" :href="url" class="c_search_result__anchor">{{ url }}</a>
    <p v-if="snippet" v-html="snippet"></p>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SearchResult',
  data() {
    return {};
  },
  props: {
    name: { type: String, required: true },
    url: { type: String, required: true },
    displayUrl: { type: String, required: false },
    snippet: { type: String, required: true },
  },
});
</script>

<style lang="scss">
.c_search_result {
  $self: &;
  margin-top: px_to_rem(37);
  &:not(:last-of-type) {
    margin-bottom: px_to_rem(35);
  }
  @at-root #{&}__title {
    display: block;
    color: $color_black;
    font-size: px_to_rem(22);
    line-height: 1;
    margin-bottom: 1rem;
    text-decoration: none;
    @include mq($mq_grid_start) {
      margin-bottom: px_to_rem(7);
    }
  }
  @at-root #{&}__anchor {
    display: inline-block;
    @include fz(5, px_to_rem(14), 700);
    color: $color_red;
    line-height: 1;
    margin-bottom: 1rem;
    @include mq($mq_grid_start) {
      margin-bottom: px_to_rem(7);
    }
  }
  @at-root #{&}__snippet {
    @include fz(5, px_to_rem(14), 700);
    line-height: 1;
  }
  b {
    @include font('graphik_semibold');
    font-weight: bold;
  }
}
</style>
