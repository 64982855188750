import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const textImageGridGql = `... on landingPageContent_textImageGrid_BlockType{
  typeHandle
  eyebrow
  header
  text
  images{
    ... on images_BlockType{
      imageHeader
      imageText
      image{
        title
        ... on landingPageImages_Asset{
          caption
          lpImageGridSquare{
            srcset
            srcsetWebp
          }
          lpImageGridMasonry{
            srcset
            srcsetWebp
          }
          lpImageGridLandscape{
            srcset
            srcsetWebp
          }
        }
      }
    }
  }
  imageLayout
  ${linksSuperTableGql('textImageGridButton')}
  colors
  textAlignment
  style
}`;
