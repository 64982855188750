var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"c_global_header",class:{
    'c_box--pos--relative': ['lg'].includes(_vm.$mq),
    'c_global_header--section': _vm.navigationCurrentSectionLink && ['lg'].includes(_vm.$mq),
  }},[_c('div',{staticClass:"skip_to_navigation visually_hidden visually_hidden--show_on_focus"},[_c('ul',{staticClass:"skip_to_navigation__nav"},[_c('li',[_c('Button',{staticClass:"skip_to_navigation__nav__link",attrs:{"unstyle":"","reset":""},on:{"onClick":_vm.skipToMain}},[_vm._v(_vm._s(_vm.$t('Skip to main content')))])],1)])]),_vm._v(" "),_c('div',{staticClass:"c_global_header__wrapper c_wrapper",class:{ 'c_global_header__wrapper--visible': _vm.appHydrated }},[(
        _vm.navigationCurrentSectionLink &&
        _vm.navigationCurrentSectionLink.linksMatrix.length &&
        _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText &&
        ['lg'].includes(_vm.$mq)
      )?_c('nav',{staticClass:"c_global_header__navigation_and_links",attrs:{"aria-label":"Main menu"}},[_c('ul',[_c('li',{staticClass:"c_navigation__top_lvl_item"},[_c('span',{staticClass:"c_global_header__logo"},[_c('Button',{staticClass:"c_global_header__logo__icon",attrs:{"href":"/","unstyle":"","reset":""}},[_c('IconSVG',{attrs:{"handle":"logo_kodak","alt":_vm.$t('Navigate to Home'),"replacements":{
                  'Kodak Logo': _vm.$t('Kodak Logo'),
                }}})],1)],1)]),_vm._v(" "),_c('li',{staticClass:"c_navigation__top_lvl_item"},[_c('LinkField',{staticClass:"c_navigation__single_item c_navigation__top_lvl_item__link",class:{
              'c_header--3': _vm.useLargeContentSectionHeader(
                _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText
              ),
            },attrs:{"matrix-field":_vm.navigationCurrentSectionLink.linksMatrix[0],"button-attrs":{ unstyle: true, reset: true }}})],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c_global_header__navigation_and_links",class:{
        'c_global_header__navigation_and_links--left':
          (_vm.navigationCurrentSectionLink &&
            _vm.navigationCurrentSectionLink.contentSection &&
            _vm.navigationCurrentSectionLink.contentSection[0].slug === _vm.getContentSection) ||
          ['sm', 'md'].includes(_vm.$mq),
        'c_global_header__navigation_and_links--secondary': _vm.navigationCurrentSectionLink && ['lg'].includes(_vm.$mq),
        'c_global_header__navigation_and_links--hamburger': ['sm', 'md'].includes(_vm.$mq),
      }},[(
          _vm.navigationCurrentSectionLink &&
          _vm.navigationCurrentSectionLink.contentSection &&
          _vm.navigationCurrentSectionLink.contentSection[0].slug === _vm.getContentSection &&
          ['lg'].includes(_vm.$mq)
        )?_c('Navigation',{staticClass:"c_global_header__navigation",attrs:{"aria-label":"Section menu","nav-entries":_vm.navigationEntries}}):(_vm.navigationEntries && ['lg'].includes(_vm.$mq))?_c('nav',{attrs:{"aria-label":"Main menu"}},[_c('ul',{staticClass:"c_global_header__navigation"},[_c('li',[_c('span',{staticClass:"c_global_header__logo"},[_c('Button',{staticClass:"c_global_header__logo__icon",attrs:{"href":"/","unstyle":"","reset":""}},[_c('IconSVG',{attrs:{"handle":"logo_kodak","alt":_vm.$t('Navigate to Home'),"replacements":{
                    'Kodak Logo': _vm.$t('Kodak Logo'),
                  }}})],1)],1)]),_vm._v(" "),(
              _vm.navigationCurrentSectionLink &&
              _vm.navigationCurrentSectionLink.linksMatrix.length &&
              _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText
            )?_c('li',{staticClass:"c_navigation__top_lvl_item"},[_c('LinkField',{staticClass:"c_navigation__single_item c_navigation__top_lvl_item__link",class:{
                'c_header--3': _vm.useLargeContentSectionHeader(
                  _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText
                ),
              },attrs:{"matrix-field":_vm.navigationCurrentSectionLink.linksMatrix[0],"button-attrs":{ unstyle: true, reset: true }}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.navigationEntries),function(item,childIndex){return _c('li',{key:childIndex,staticClass:"c_navigation__top_lvl_item"},[(item.typeHandle === 'singleItem' && item.linksMatrix && item.linksMatrix[0])?_c('LinkField',{staticClass:"c_global_header__navigation__single_item c_navigation__top_lvl_item__link",class:_vm.activeNavIndex === childIndex && _vm.allowDrawersToOpen ? 'c_navigation__top_lvl_item__link--active' : '',attrs:{"aria-expanded":_vm.activeNavIndex === childIndex && _vm.allowDrawersToOpen && item.children && item.children.length
                  ? 'true'
                  : item.children && item.children.length
                  ? 'false'
                  : null,"matrix-field":item.linksMatrix[0],"button-attrs":item.navigationClickThroughUrl
                  ? { unstyle: true, reset: true, href: item.linksMatrix[0].element[0].uri }
                  : item.children && item.children.length
                  ? { unstyle: true, reset: true, href: null }
                  : { unstyle: true, reset: true }},on:{"onClick":function($event){!item.navigationClickThroughUrl ? _vm.toggleDropdown(childIndex) : null}}}):_vm._e(),_vm._v(" "),(_vm.activeNavIndex === childIndex && _vm.allowDrawersToOpen)?_c('div',{staticClass:"c_global_header__navigation__dropdown c_box--shadow--center"},[_c('Navigation',{attrs:{"nav-entries":item.children,"is-sub-nav":true,"section":item.linksMatrix}})],1):_vm._e()],1)})],2)]):(_vm.navigationEntries && ['sm', 'md'].includes(_vm.$mq))?_c('NavigationMobile',{attrs:{"aria-label":"Main menu mobile","content-section":_vm.getContentSection,"logo-link-object":_vm.navigationCurrentSectionLink || null,"nav-entries":_vm.navigationEntries}},[_c('ul',{staticClass:"c_global_header__navigation_and_links",attrs:{"slot":"logo"},slot:"logo"},[_c('li',[_c('span',{staticClass:"c_global_header__logo"},[_c('Button',{staticClass:"c_global_header__logo__icon",attrs:{"href":"/","unstyle":"","reset":""}},[_c('IconSVG',{attrs:{"handle":"logo_kodak","alt":_vm.$t('Navigate to Home'),"replacements":{
                    'Kodak Logo': _vm.$t('Kodak Logo'),
                  }}})],1)],1)]),_vm._v(" "),(
              _vm.navigationCurrentSectionLink &&
              _vm.navigationCurrentSectionLink.linksMatrix.length &&
              _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText
            )?_c('li',{staticClass:"c_navigation__top_lvl_item"},[_c('LinkField',{staticClass:"c_navigation__single_item c_navigation__top_lvl_item__link",class:{
                'c_header--3': _vm.useLargeContentSectionHeader(
                  _vm.parseNavLinkField(_vm.navigationCurrentSectionLink.linksMatrix[0]).labelText
                ),
              },attrs:{"matrix-field":_vm.navigationCurrentSectionLink.linksMatrix[0],"button-attrs":{ unstyle: true, reset: true }}})],1):_vm._e()])]):_vm._e()],1),_vm._v(" "),_c('Navigation',{directives:[{name:"show",rawName:"v-show",value:(['lg'].includes(_vm.$mq)),expression:"['lg'].includes($mq)"}],staticClass:"c_global_header__navigation c_global_header__navigation__utility",attrs:{"aria-label":_vm.$t('Utility menu'),"nav-entries":_vm.utilityNavigationCurrentSectionLinks,"search-toggle-visible":true}})],1),_vm._v(" "),(_vm.showSearchToggle)?_c('SearchWidget',{attrs:{"id":"global-search-box","navigation":true,"search-action":_vm.getSearchPath ? _vm.getSearchPath : null,"search-input-id":"global-search-query","search-hidden":true,"search-results-visible":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }