<template>
  <div class="c_navigation_mobile_drawer" :is="elementType">
    <li>
      <LinkField
        class="c_navigation_mobile_drawer__navigation_item c_navigation_mobile_drawer__navigation_item--mobile"
        v-if="
          parent &&
          !parent.icon &&
          ((parent.entry && parseLinkField(parent, 'superTable').valid) ||
            (parent.linksMatrix && parent.linksMatrix.length) ||
            (children && children.length))
        "
        :matrix-field="parent.linksMatrix && parent.linksMatrix.length ? parent.linksMatrix[0] : null"
        :super-table-field="parent.entry && parseLinkField(parent, 'superTable').valid ? parent : null"
        :aria-expanded="showDrawer ? 'true' : 'false'"
        :button-attrs="
          children && children.length ? { unstyle: true, reset: true, href: null } : { unstyle: true, reset: true }
        "
        @onClick="toggleDrawer"
      >
        <span
          class="c_navigation_mobile_drawer__navigation_item__text"
          v-if="(parent.linksMatrix && parent.linksMatrix.length) || parent.entry || parent.title"
          v-html="
            parent.linksMatrix && parent.linksMatrix.length && !parent.title
              ? parseLinkField(parent.linksMatrix[0], 'matrix').labelText
              : parent.entry
              ? parseLinkField(parent, 'superTable').labelText
              : parent.title
              ? parent.title
              : null
          "
        >
        </span>
        <IconSVG
          class="c_navigation_mobile_drawer__navigation_item__arrow"
          v-if="children && children.length"
          handle="arrow_right"
          aria-hidden="true"
          stroke-width="5px"
        />
      </LinkField>
      <LinkField
        class="c_navigation_mobile_drawer__navigation_item c_navigation_mobile_drawer__navigation_item--icon_link"
        v-else-if="parent && parent.navigationLink && parent.navigationLink.length && parent.typeHandle == 'iconLink'"
        :super-table-field="parent.navigationLink[0]"
        :button-attrs="{ unstyle: true, reset: true }"
      >
        <img
          class="c_navigation_mobile_drawer__navigation_item--icon_link__icon"
          v-if="parent.icon && parent.icon[0]"
          :alt="parent.icon[0].title"
          :src="parent.icon[0].url"
        />
        <span
          class="c_text--graphik_semibold"
          v-if="parseLinkField(parent.navigationLink[0], 'superTable').labelText"
          >{{ parseLinkField(parent.navigationLink[0], 'superTable').labelText }}</span
        >
      </LinkField>
      <transition name="slide">
        <Drawer
          element-type="ul"
          :active="showDrawerComputed"
          ref="child-drawer"
          :style="[drawerOverflow]"
          v-if="showDrawer && children && returnChildren(parent, children)"
        >
          <button
            :aria-label="$t('Back')"
            class="c_navigation_mobile_drawer__back__button c_text--graphik_semibold"
            @click="closeDrawer()"
          >
            <IconSVG
              class="c_navigation_mobile_drawer__back__button__icon"
              stroke-color-var="white"
              height="10"
              width="6"
              stroke-width="5px"
              handle="arrow_left"
              aria-hidden="true"
            />
            <span>{{ $t('Back') }}</span>
          </button>
          <ul
            v-if="drawerHeader && drawerHeader.title"
            :key="`${drawerHeader.title}-${sectionTitle}`"
            class="c_navigation_mobile_drawer"
          >
            <li class="c_navigation_mobile_drawer__section_top c_navigation_mobile_drawer__sub_drawer">
              <Button
                class="c_text--graphik_semibold c_navigation_mobile_drawer__navigation_item c_navigation_mobile_drawer__navigation_item--mobile"
                unstyle
                reset
                :href="drawerHeader.uri || null"
                >{{ drawerHeader.title }} Home
              </Button>
            </li>
          </ul>
          <NavigationMobileDrawer
            v-for="(child, childIndex) in children"
            element-type="li"
            class="c_navigation_mobile_drawer__sub_drawer"
            :class="childIndex == children.length - 1 ? 'c_navigation_mobile_drawer__sub_drawer--last' : ''"
            :key="childIndex"
            :section-title="contentSection"
            :parent="returnParent(child)"
            :children="returnChildren(child)"
          />
        </Drawer>
      </transition>
    </li>
  </div>
</template>

<script>
import { parseLinkField, returnNavigationChildren, generateUuid } from 'JS/global.js';
import { mapState } from 'vuex';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Drawer from 'Components/drawer/Drawer.vue';
import LinkField from 'Components/link_field/LinkField.vue';
export default {
  components: {
    Button,
    IconSVG,
    Drawer,
    LinkField,
    NavigationMobileDrawer: () => import('Components/navigation/NavigationMobileDrawer.vue'),
  },
  data() {
    return {
      showDrawer: false,
      uuid: null,
    };
  },
  props: {
    children: {
      type: Array,
    },
    contentSection: null,
    elementType: {
      type: String,
      default: 'div',
    },
    parent: {
      type: Object,
    },
    sectionTitle: {
      type: String,
    },
  },
  computed: {
    showDrawerComputed() {
      return this.showMobileNav ? this.showDrawer : false;
    },
    drawerHeader() {
      if (this.parent && this.parent?.linksMatrix && this.parent?.linksMatrix[0]?.element[0]?.title) {
        return {
          title: this.parent?.linksMatrix[0]?.element[0]?.title,
          uri: this.parent?.linksMatrix[0]?.element[0]?.uri || null,
        };
      }
      return null;
    },
    drawerOverflow() {
      const lastItem = this.globalCurrentMobileDrawerTree?.length - 1 || 0;
      return this.globalCurrentMobileDrawerTree[lastItem] === this.uuid
        ? { '--navigation-mobile-drawer-overflow': 'auto' }
        : { '--navigation-mobile-drawer-overflow': 'hidden' };
    },
    ...mapState({
      showMobileNav: (state) => state.global.showMobileNav,
      globalCurrentMobileDrawerTree: (state) => state.global.globalCurrentMobileDrawerTree,
    }),
  },
  methods: {
    parseLinkField,
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
      if (this.showDrawer) {
        this.$nextTick(() => {
          this.$refs?.['child-drawer']?.$el?.scrollIntoView(true);
          this.$store.commit('global/updateGlobalCurrentMobileDrawerTree', this.uuid);
        });
      }
    },
    returnChildren(functionParent, functionChildren) {
      return returnNavigationChildren(functionParent, functionChildren);
    },
    returnParent(content = null) {
      let parent = {};
      if (content) {
        parent =
          content.linksMatrix && content.linksMatrix.length
            ? content
            : content.navigationLinks && content.navigationLinks.length
            ? content.navigationLinks[0]
            : (content.navigationLink && content.navigationLink.length) || content.entry || content.title
            ? content
            : content.element
            ? { linksMatrix: [content] }
            : content.urlText && content.buttonLabel
            ? { linksMatrix: [content] }
            : null;
      }
      return parent;
    },
    closeDrawer() {
      this.showDrawer = false;
      this.$store.commit('global/reduceGlobalCurrentMobileDrawerTree');
    },
  },
  mounted() {
    this.uuid = generateUuid();
  },
  updated() {
    if (!this.showMobileNav) {
      this.showDrawer = false;
    }
  },
  watch: {
    $route() {
      this.showDrawer = false;
    },
  },
};
</script>

<style lang="scss">
.c_navigation_mobile_drawer {
  $self: &;
  --nav-mobile-drawer--item--padding-top: #{px_to_rem(20)};
  --nav-mobile-drawer--item--padding-left-right: 5vw;
  --navigation-item--border-color: #{$color_white};
  --navigation-item--color: #{$color_black};
  --navigation-item--hover-color: #{$color_red};

  .c_drawer {
    top: 0;
    height: 100%;
    min-height: calc(100vh - #{px_to_rem(120)});
    overflow: var(--navigation-mobile-drawer-overflow);
    .c_navigation_item {
      --navigation-item--border-color: #{$color_white};
    }
    .c_drawer {
      top: 0;
      height: 100%;
    }
  }
  @at-root #{$self}--last {
    --navigation-item--border-color: transparent;
  }
  @at-root #{$self}__section {
    $section: &;
    @at-root #{$section}_title {
      background: $color_gray_235;
      text-transform: capitalize;
      box-sizing: border-box;
      padding: var(--nav-mobile-drawer--item--padding-top) var(--nav-mobile-drawer--item--padding-left-right);
    }
    @at-root #{$section}_top {
      display: flex;
      align-items: center;
    }
    @at-root #{$section}_home {
      display: flex;
      align-items: center;
      align-self: stretch;
      border-bottom: px_to_rem(1) solid var(--navigation-item--border-color);
    }
  }
  @at-root #{$self}__back {
    @at-root #{&}__button {
      appearance: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: $color_white;
      background: $color_black;
      border: none;
      width: 100%;
      height: px_to_rem(60);
      padding: 0 5vw;
      box-sizing: border-box;
      &:hover,
      &:focus {
        color: $color_red;
      }
      @at-root #{&}__icon {
        height: px_to_rem(10);
        flex: 0 0 px_to_rem(6);
        margin-right: px_to_rem(12);
        svg {
          vertical-align: top;
        }
      }
    }
  }
  @at-root #{$self}__sub_drawer {
    padding: 0 var(--nav-mobile-drawer--item--padding-left-right);
    @at-root #{&}--last {
      --navigation-item--border-color: transparent;
    }
  }
  #{$self}__navigation_item {
    $nav_item: &;
    border-bottom: px_to_rem(1) solid var(--navigation-item--border-color);
    color: var(--navigation-item--color);
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: px_to_rem(20) 0;
    &:hover,
    &:focus {
      color: var(--navigation-item--hover-color);
    }
    @at-root #{&}__arrow {
      height: px_to_rem(10);
      width: px_to_rem(10);
      svg {
        vertical-align: top;
      }
    }

    @at-root #{&}__text {
      padding-right: px_to_rem(20);
    }

    @at-root #{&}--icon_link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: var(--nav-mobile-drawer--item--padding-top) 0;
      color: $color_black;
      text-decoration: none;
      &:hover,
      &:focus {
        color: $color_red;
      }
      @at-root #{&}__icon {
        max-width: px_to_rem(35);
        max-height: px_to_rem(35);
        margin-right: px_to_rem(15);
      }
    }
  }
  @at-root #{$self}__navigation {
    padding: var(--nav-mobile-drawer--item--padding-left-right);
    box-sizing: border-box;
    @at-root #{$self} #{&} {
      padding: 0 var(--nav-mobile-drawer--item--padding-left-right);
    }
  }
}
</style>
