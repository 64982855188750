import { landingPageContentGql } from '../../matrix_blocks/landingPageContentGql.js';
import { linksMatrixGql } from '../../link_field/linksMatrixGql.js';

const pageGql = `id
title
... on productCategories_Category {
  headerImages {
    title
    ... on headerImages_Asset {
      pageHeader {
        srcset
        srcsetWebp
      }
    }
  }
  sidebarNavigationLinks: ${linksMatrixGql(2)}
  filters: productFilters(orderBy: "lft asc") {
    title
    slug
    level
    parent {
      slug
    }
  }
  filterPageOverviewSubhead
  filterPageOverviewDescription
  links: ${linksMatrixGql()}
  ${landingPageContentGql}
  sortByDefault
  sortingAttributes {
    attribute
    label
    order
    sortValuesNumerically
  }
  sortByOptions
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}
parent {
  title
  uri
  ... on productCategories_Category {
    breadcrumbLink {
      uri
    }
  }
  parent {
    title
    uri
    ... on productCategories_Category {
      breadcrumbLink {
        uri
      }
    }
    parent {
      title
      uri
      ... on productCategories_Category {
        breadcrumbLink {
          uri
        }
      }
      parent {
        title
        uri
        ... on productCategories_Category {
          breadcrumbLink {
            uri
          }
        }
        parent {
          title
          uri
          ... on productCategories_Category {
            breadcrumbLink {
              uri
            }
          }
        }
      }
    }
  }
}`;

export const pageQuery = `query($site: String, $uri: String) {
  category(site: [$site], uri: [$uri]) {
    ${pageGql}
  }
}`;

export const productFilterGenerateQuery = function (sitesString = `$site`) {
  return `query {
    categories(
      site: [${sitesString}]
      group: ["productCategories"]
    ) {
      groupHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};

export const resultsQuery = `query($id: QueryArgument, $site: String) {
  entries(
    productFilterPages: [$id]
    orderBy: "title asc"
    section: "product"
    site: [$site]
    type: ["productPage", "productPage2022"]
  ) {
    title
    url
    filterByDate: postDate  @formatDateTime (format: "D M d Y H:i:s O")
    ... on product_productPage_Entry {
      entryAttributes {
        attribute
        value
      }
      metaDescription: relatedEntryDescription
      featuredAsset: productBrochure {
        title
        url
      }
      icons {
        title
        url
        width
        height
      }
      specificationsTab: children(title:"Specifications") {
        url
      }
      metaImage: productFeaturedImage {
        title
        ... on images_Asset {
          square: aspectRatio11 {
            srcset
            srcsetWebp
          }
          landscape: filterLandscape {
            srcset
            srcsetWebp
          }
        }
        ... on productImages_Asset {
          square: filterSquare {
            srcset
            srcsetWebp
          }
          landscape: filterLandscape {
            srcset
            srcsetWebp
          }
        }
      }
      productFilters(level: 2) {
        slug
        parent {
          slug
        }
      }
      productFilterPages {
        title
      }
    }
    ... on product_productPage2022_Entry {
      entryAttributes {
        attribute
        value
      }
      metaDescription: relatedEntryDescription
      metaImage: productFeaturedImage {
        title
        ... on images_Asset {
          square: aspectRatio11 {
            srcset
            srcsetWebp
          }
          landscape: filterLandscape {
            srcset
            srcsetWebp
          }
        }
        ... on productImages_Asset {
          square: filterSquare {
            srcset
            srcsetWebp
          }
          landscape: filterLandscape {
            srcset
            srcsetWebp
          }
        }
      }
      productFilters(level: 2) {
        slug
        parent {
          slug
        }
      }
      productFilterPages {
        title
      }
    }
  }
}`;
