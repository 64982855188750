export const relatedProductsGql = `... on landingPageContent_relatedProducts_BlockType{
    typeHandle
    header
    relatedProducts {
        typeHandle
        title
        uri
        ... on product_productPage_Entry {
          productFeaturedImage {
            ... on productImages_Asset {
              title
              filterSquare {
                srcset
                srcsetWebp
              }
            }
          }
          relatedEntryDescription
        }
      }
}`;
