import { productTabRelatedResourcesGql } from './../pages/product_tab/productTabRelatedResourcesGql.js';

export const relatedResources2023Gql = `... on landingPageContent2023_relatedResources_BlockType {
  typeHandle
  pageTitle
  displayInHeaderQuicklinks
  displayFilter
  relatedResource {
    ... on pdfs_Asset {
      ${productTabRelatedResourcesGql}
    }
    ... on videos_Asset {
      ${productTabRelatedResourcesGql}
    }
    ... on images_Asset {
      ${productTabRelatedResourcesGql}
    }
    ... on zipFiles_Asset {
      ${productTabRelatedResourcesGql}
    }
  }
}`;
