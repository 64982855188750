export const soundCloudGql = `... on landingPageContent_embedSoundcloud_BlockType {
  typeHandle
  height
  podcastHref
  podcastName
  trackHref
  trackId
  trackTitle
  width
}`;
