<template>
  <Button
    :is="componentType === 'arrow' ? 'ArrowLink' : 'Button'"
    class="c_link_field"
    v-bind="buttonAttributes"
    :class="{ 'c_button_2022 c_button_2022__primary': buttonAttrs?.styling === 'default2023' }"
    @onClick="clickThroughAction"
    ><slot></slot
  ></Button>
</template>

<script>
import { parseLinkField } from 'JS/global.js';
import ArrowLink from 'Components/arrow_link/ArrowLink.vue';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    ArrowLink,
    Button,
    IconSVG,
  },
  data() {
    return {};
  },
  props: {
    buttonAttrs: Object,
    componentType: { type: String, default: 'button' }, // arrow, button
    matrixField: Object,
    superTableField: Object,
  },
  computed: {
    buttonAttributes() {
      let attrs = {};

      if (this.matrixField) {
        attrs = parseLinkField(this.matrixField, 'matrix');
      } else if (this.superTableField) {
        attrs = parseLinkField(this.superTableField, 'superTable');
      }

      if (Object.keys(attrs).length && attrs.valid) {
        if (this.buttonAttrs) {
          Object.assign(attrs, this.buttonAttrs);
        }
        if (this.componentType === 'arrow') {
          return { linkField: attrs };
        }

        return attrs;
      } else if (this.buttonAttrs && !Object.keys(attrs).length) {
        return this.buttonAttrs;
      }
      return null;
    },
  },
  methods: {
    clickThroughAction(event) {
      this.$emit('onClick', event);
    },
  },
};
</script>
