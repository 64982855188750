import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const textContentCardsGql = `... on landingPageContent_textContentCards_BlockType{
  typeHandle
  eyebrow
  header
  text
  ${linksSuperTableGql('textContentCardsButton')}
  textAlignment
  colors
  cardType
  cards{
    ... on cards_BlockType{
        header
        descriptor
        secondaryDescriptor
        image{
            title
            url
            ... on landingPageImages_Asset{
                caption
                lpGalleryPortrait{
                    srcset
                    srcsetWebp
                }
                lpImageGridLandscape{
                    srcset
                    srcsetWebp
                }
            }
        }
        ${linksSuperTableGql()}
    }
  }
}`;
