import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const linkedImageGridGql = `... on landingPageContent_linkedImageGrid_BlockType{
    typeHandle
    colors
    images: linkedImageGridImages{
        ... on linkedImageGridImages_BlockType{
            image{
                ... on landingPageImages_Asset{
                    title
                    lpImageGridLandscape{
                        srcsetWebp
                        srcset
                    }
                }
            }
            ${linksSuperTableGql()}
            description
            color
            alignment
        }
    }
}`;
