<template>
  <nav class="c_navigation_mobile" v-if="navEntries" :aria-label="$t('Primary Navigation - Mobile')">
    <slot name="logo" />
    <div class="c_navigation_mobile__toggles">
      <GlobalSearchToggle
        v-show="!showMobileNav"
        toggle-class="c_navigation_mobile__nav_button_search"
        toggle-icon-height="25"
        toggle-icon-width="25"
      />
      <Button
        class="c_navigation_mobile__nav_button"
        aria-controls="mobile-nav-drawer"
        :aria-expanded="showMobileNav ? 'true' : 'false'"
        :aria-label="$t('Toggle Menu')"
        @onClick="toggleMobileNav"
        unstyle
        reset
      >
        <IconSVG v-if="showMobileNav" handle="close" height="25" width="30" stroke-width="3px" aria-hidden="true" />
        <IconSVG v-else handle="hamburger" height="25" width="30" aria-hidden="true" />
      </Button>
    </div>
    <Drawer
      id="mobile-nav-drawer"
      class="c_navigation_mobile__nav_drawer"
      :active="showMobileNav"
      :aria-expanded="showMobileNav ? 'true' : 'false'"
      :aria-hidden="showMobileNav ? 'fale' : 'true'"
      aria-live="polite"
      role="region"
      v-cloak
    >
      <ul class="c_navigation_mobile__nav_drawer__navigation">
        <li v-if="currentSectionHeader.title && currentSectionHeader.uri">
          <ul class="c_navigation_mobile_drawer">
            <li class="c_navigation_mobile_drawer__section_top">
              <nuxt-link
                class="c_navigation_mobile_drawer__navigation_item c_navigation_mobile_drawer__navigation_item--mobile"
                :to="localePath(`/${currentSectionHeader.uri}`)"
              >
                <strong class="c_navigation_mobile_drawer__navigation_item__text c_text--graphik_semibold">{{
                  currentSectionHeader.title
                }}</strong>
              </nuxt-link>
            </li>
          </ul>
        </li>
        <li v-for="(topLvlItem, topLvlIndex) in navEntries" :key="topLvlIndex">
          <NavigationMobileDrawer
            element-type="ul"
            :class="topLvlIndex == navEntries.length - 1 ? 'c_navigation_mobile_drawer--last' : ''"
            :content-section="contentSection"
            :section-title="topLvlItem.typeHandle == 'singleItem' ? topLvlItem.title : contentSection"
            :parent="topLvlItem"
            :children="returnTopNavChildren(topLvlItem)"
          />
        </li>
      </ul>
    </Drawer>
  </nav>
</template>

<script>
import { parseLinkField, returnNavigationChildren } from 'JS/global.js';
import { mapState } from 'vuex';
import Button from 'Components/button/Button.vue';
import Drawer from 'Components/drawer/Drawer.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import NavigationMobileDrawer from 'Components/navigation/NavigationMobileDrawer.vue';
import GlobalSearchToggle from 'Components/search/GlobalSearchToggle.vue';

export default {
  components: {
    Button,
    Drawer,
    IconSVG,
    NavigationMobileDrawer,
    GlobalSearchToggle,
  },
  data() {
    return {
      openNavDrawers: [],
    };
  },
  props: {
    contentSection: {
      type: String,
    },
    isSubNav: {
      type: Boolean,
      default: false,
    },
    logoLinkObject: null,
    navEntries: Array,
  },
  computed: {
    currentSectionHeader() {
      if (this.logoLinkObject?.linksMatrix[0]?.element[0]) {
        return {
          title: `${this.logoLinkObject?.linksMatrix[0]?.element[0]?.title} ${this.$t('Home')}`,
          uri: this.logoLinkObject?.linksMatrix[0]?.element[0]?.uri,
        };
      }
      if (this.parent?.linksMatrix[0]?.element?.length) {
        return {
          title: `${this.parent?.linksMatrix[0]?.element[0]?.title} ${this.$t('Home')}`,
          uri: this.parent?.linksMatrix[0]?.element[0]?.uri,
        };
      }
      return { title: this.$t('Home'), uri: '/' };
    },
    ...mapState({
      allowDrawersToOpen: (state) => state.global.allowDrawersToOpen,
      showMobileNav: (state) => state.global.showMobileNav,
    }),
  },
  methods: {
    useLargeContentSectionHeader(linkText) {
      if (linkText.length > 24) {
        return true;
      }
      return false;
    },
    parseLinkField,
    toggleMobileNav() {
      this.$store.commit('global/updatedSearchOpen', false);
      this.$store.commit('global/updateShowMobileNav', !this.showMobileNav);
      this.$store.commit('global/changeAllowDrawersToOpen', this.showMobileNav);
      this.$store.commit('global/resetGlobalCurrentMobileDrawerTree');
    },
    returnTopNavChildren(topLvlItem) {
      if (topLvlItem.children) {
        return topLvlItem.children;
      } else if (
        topLvlItem.navigationColumns ||
        topLvlItem.navigationLinks ||
        topLvlItem.navigationColumnsWithImages ||
        topLvlItem.navigationIconLinks ||
        topLvlItem.linksMatrix
      ) {
        return returnNavigationChildren(topLvlItem);
      } else {
        return null;
      }
    },
  },
  watch: {
    $route() {
      this.$store.commit('global/updateShowMobileNav', false);
      this.$store.commit('global/changeAllowDrawersToOpen', false);
      this.$store.commit('global/resetGlobalCurrentMobileDrawerTree');
    },
  },
};
</script>

<style lang="scss">
.c_navigation_mobile {
  $self: &;
  --navigation-mobile--padding: 5vw;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ul,
  ul li {
    list-style-type: none;
  }

  .c_drawer {
    --drawer--max-width: 100vw;
  }

  @at-root #{$self}__toggles {
    display: flex;
    align-items: center;
  }

  @at-root #{$self}__nav_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    width: px_to_rem(48);
    height: px_to_rem(48);
    color: $color_black;
    svg {
      vertical-align: top;
    }
    &:hover,
    &:focus {
      color: $color_red;
    }
  }

  @at-root #{$self}__nav_button_search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    width: px_to_rem(48);
    height: px_to_rem(48);
    margin-right: px_to_rem(5);
  }

  @at-root #{$self}__nav_drawer {
    top: px_to_rem(60);
    right: calc(-1 * var(--wrapper-padding, 5vw));
    width: 100vw;
    height: calc(100vh - #{px_to_rem(60)});
    @at-root #{&}__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      height: auto;
      padding: var(--wrapper-padding, 1vw) var(--wrapper-padding, 5vw);
      background: $color_yellow;
      @include mq($mq_sm_to_md) {
        min-height: px_to_rem(40);
      }
      @at-root #{&}__controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      @at-root #{&}__back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: $color_black;
        width: 100%;
        > button {
          &:not(:last-of-type) {
            display: none;
          }
        }
      }
      @at-root #{&}__search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        justify-self: flex-end;
        width: px_to_rem(48);
        height: px_to_rem(48);
      }
      @at-root #{&}__close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        justify-self: flex-end;
        color: $color_black;
        width: px_to_rem(48);
        height: px_to_rem(48);
        &:hover,
        &:focus {
          color: $color_red;
        }
      }
    }
    @at-root #{&}__navigation {
      position: relative;
      padding: 0 var(--navigation-mobile--padding);
      height: 100%;
      & > li:last-of-type {
        & > .c_navigation_mobile_drawer > .c_navigation_item {
          --navigation-item--border-color: transparent;
        }
      }
    }
  }
  @at-root #{$self}__mask {
    position: fixed;
    display: none;
    opacity: 0;
    background: rgba($color_black_raw, 0.5);
    z-index: 300;
    transition: opacity 0.5s ease;
    @at-root #{&}--active {
      display: block;
      opacity: 1;
    }
  }
  @at-root #{$self}__locales {
    margin-top: px_to_rem(40);
  }
  @at-root #{$self}__utility {
    $utility: &;

    @at-root #{$utility}_menu {
      margin-top: px_to_rem(40);
    }
    @at-root #{$utility}_item {
      text-decoration: none;
    }
  }
}
</style>
