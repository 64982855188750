<template>
  <div v-if="matrixBlocks.length">
    <div class="sp__block" v-for="(block, blockIndex) in matrixBlocks" :key="blockIndex">
      <section v-if="block.typeHandle === 'basicText'">
        <LPText
          :button-link-field="block.button[0] || null"
          :eyebrow="block.eyebrow || null"
          eyebrow-style="regular"
          :subheader="block.subheader || null"
          :text="block.text || null"
          :text-alignment="null"
          :primary-cta-btn="null"
          :primary-cta-btn-label="null"
          :primary-cta-btn-aria-label="null"
          :primary-cta-btn-theme="null"
          :primary-cta-btn-open-in-a-new-window="null"
        />
      </section>
      <section v-else-if="block.typeHandle === 'plainText'">
        <div class="c_lp_text" v-if="block.text">
          <div class="c_lp_text__text c_text" v-html="block.text"></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LPText: () => import('Components/lp_text/LPText.vue'),
  },
  props: {
    matrixBlocks: Array,
  },
};
</script>

<style lang="scss">
.sp__block {
  $self: &;
  --sp-block-min-height: 400px;
  --sp-block-padding: 3rem;
  padding: var(--sp-block-padding-top, var(--sp-block-padding, 0)) 0
    var(--sp-block-padding-bottom, var(--sp-block-padding, 0));
  position: relative;
}
</style>
