var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.useRouterLink)?_c(_vm.elementTypeComputed,{tag:"span",class:{
    c_button: _vm.styleAsButton,
    'c_button--pointer': _vm.styleAsButton && _vm.href,
    'c_button--outline': _vm.outline,
    'c_button--reset': _vm.reset,
    'c_button--small': _vm.small,
  },style:(_vm.color ? { '--button-color': `var(--color-${_vm.color})` } : null),attrs:{"aria-label":_vm.ariaLabel || _vm.labelText || null,"href":_vm.formattedHref ? _vm.localePath(_vm.formattedHref) : null,"target":_vm.newWindow ? '_blank' : _vm.target || null,"title":_vm.title || _vm.ariaLabel || _vm.labelText || null,"to":_vm.formattedTo || null,"rel":_vm.newWindow || _vm.target ? 'noopener' : null},nativeOn:{"click":function($event){return _vm.clickThroughAction.apply(null, arguments)}}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.labelText))]}),_vm._v(" "),(_vm.arrow)?_c('IconSVG',{attrs:{"handle":"chevron_right"}}):_vm._e()],2):_c(_vm.elementTypeComputed,{tag:"span",class:{
    c_button: _vm.styleAsButton,
    'c_button--pointer': _vm.styleAsButton && _vm.href,
    'c_button--outline': _vm.outline,
    'c_button--reset': _vm.reset,
    'c_button--small': _vm.small,
  },style:(_vm.color ? { '--button-color': `var(--color-${_vm.color})` } : null),attrs:{"aria-label":_vm.ariaLabel || _vm.labelText || null,"href":_vm.formattedHref,"target":_vm.newWindow ? '_blank' : _vm.target || null,"title":_vm.title || _vm.ariaLabel || _vm.labelText || null,"rel":_vm.newWindow || _vm.target ? 'noopener' : null},on:{"click":_vm.clickThroughAction}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.labelText))]}),_vm._v(" "),(_vm.arrow)?_c('IconSVG',{attrs:{"handle":"chevron_right"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }