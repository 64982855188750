<template>
  <div :is="elementType" class="c_drawer" :class="isActive ? 'c_drawer--active' : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    elementType: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    isActive() {
      return this.$store.state.global.allowDrawersToOpen ? this.active : false;
    },
  },
  methods: {},
  updated() {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_drawer {
  $self: &;
  --drawer--max-width: #{px_to_rem(600)};
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: var(--drawer--max-width);
  transition: right 0.5s ease;
  transform: translate3d(100%, 0, 0);
  transition: visibility 0s 0.6s, transform 0.5s ease;
  z-index: 500;
  background: $color_gray_235;
  overflow-y: scroll;
  border-box: box-sizing;
  visibility: hidden;
  @at-root #{&}--active {
    transform: translate3d(0, 0, 0);
    transition: visibility 0s 0s, transform 0.5s ease;
    visibility: visible;
  }
  .slide-enter-active &,
  .slide-leave-active & {
    transition: right 0.5s;
  }
  .slide-enter-to,
  .slide-leave {
    right: 0;
  }
  .slide-enter,
  .slide-leave-to {
    right: -100%;
  }
}
</style>
