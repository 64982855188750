export const videoPlayer2023Gql = `... on landingPageContent2023_videoPlayer_BlockType {
  typeHandle
  colors
  header
  text
  pageTitle
  displayInHeaderQuicklinks
  videoDuration
  buttonText
  image {
    ... on landingPageVideos_Asset {
      id
      title
      videoId
      videoSource
      lpBackgroundWide {
        srcsetWebp
        srcset
      }
    }
    ... on images_Asset {
      title
      videoId
      videoSource
      lpImageTextUncropped {
        srcset
        srcWebp
      }
    }
    ... on videos_Asset {
      title
      videoId
      videoSource
      lpBackgroundWide {
        srcset
        srcWebp
      }
    }
  }
  backgroundVideo {
    id
    ... on videoUploads_Asset {
      url
      size
      extension
    }
    ... on videos_Asset {
      url
      size
      extension
    }
  }
}`;
