export const productTabCompareGql = `... on product_compareTab_Entry {
  tabTitle
  tabHeader
  tableTitle
  comparisonLabelsParsed
  comparisonTable {
    ... on comparisonTable_column_BlockType {
      product {
        title
        ... on product_productPage_Entry {
          productFeaturedImage {
            ... on productImages_Asset {
              title
              filterSquare {
                srcset
                srcsetWebp
              }
            }
          }
        }
      }
      rowsParsed
    }
  }
}`;
