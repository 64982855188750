export const basicTextGql = `... on landingPageContent_basicText_BlockType {
  typeHandle
  textBlocks {
    ... on textBlocks_BlockType {
      eyebrow
      subheader
      text
      textAlignment
      custom
      buttonLabel
      ariaLabel
      openInANewWindow
      primaryCtaBtn {
        ariaLabel
        customText
        target
        text
        title
        type
        url
      }
      primaryCtaBtnLabel
      primaryCtaBtnAriaLabel
      primaryCtaBtnTheme
      primaryCtaBtnOpenInANewWindow
      entry {
        url
      }
      asset {
        url
      }
      productCategory {
        url
      }
    }
  }
  columns
  colors
}`;
