var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"c_navigation_full_width"},[(_vm.iconColumns)?_c('li',[_c('Grid',{staticClass:"c_navigation_full_width__icon_columns",attrs:{"template-columns":`repeat(${_vm.iconColumns.length}, minmax(auto, 150px))`,"gap":"15px","justify":"center"}},_vm._l((_vm.iconColumns),function(item,iconIndex){return _c('li',{key:iconIndex,staticClass:"c_navigation_full_width__icon_columns__column"},[(item.navigationLink.length && item.icon.length)?_c('LinkField',{staticClass:"c_navigation_full_width__icon_columns__link",attrs:{"super-table-field":item.navigationLink[0],"button-attrs":{ unstyle: true, reset: true }}},[(item.icon[0].url)?_c('img',{staticClass:"c_navigation_full_width__icon_columns__icon",attrs:{"src":item.icon[0].url,"alt":item.icon[0].title}}):_vm._e(),_vm._v(" "),(_vm.parseLinkField(item.navigationLink[0], 'superTable').labelText)?_c('p',[_vm._v("\n            "+_vm._s(_vm.parseLinkField(item.navigationLink[0], 'superTable').labelText)+"\n          ")]):_vm._e()]):_vm._e()],1)}),0)],1):_vm._e(),_vm._v(" "),(_vm.columnsWithImages)?_c('li',[_c('Grid',{staticClass:"c_navigation_full_width__columns_with_images",attrs:{"columns":_vm.columnsWithImages.length,"column-width":"33.33%"}},_vm._l((_vm.columnWithNavigationLinks(_vm.columnsWithImages)),function(column,columnIndex){return _c('Grid',{key:columnIndex,staticClass:"c_navigation_full_width__columns_with_images__column c_text--left",class:[
          columnIndex === 0 ? 'c_navigation_full_width__columns_with_images__column--first' : '',
          columnIndex === _vm.columnsWithImages.length - 1
            ? 'c_navigation_full_width__columns_with_images__column--last'
            : '',
        ],attrs:{"columns":2,"element-type":"li"}},[_c('ul',{staticClass:"c_navigation_full_width__list"},_vm._l((_vm.columnNavigationLinksValid(column)),function(link,linkIndex){return _c('li',{key:linkIndex},[_c('LinkField',{staticClass:"c_navigation_full_width__list__link c_navigation__link",attrs:{"super-table-field":link,"button-attrs":{ unstyle: true, reset: true },"element-type":"li"}},[(_vm.parseLinkField(link, 'superTable').labelText)?_c(linkIndex === 0 ? 'strong' : 'span',{tag:"span",class:linkIndex === 0 ? 'c_text--graphik_semibold' : ''},[_vm._v(_vm._s(_vm.parseLinkField(link, 'superTable').labelText))]):_vm._e()],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"c_navigation_full_width__columns_with_images__column__image c_text--right"},[(column.image[0].url)?_c('img',{staticClass:"c_navigation_full_width__columns_with_images__column__image--svg",attrs:{"alt":column.image[0].title,"src":column.image[0].url}}):_c('MediaImage',{attrs:{"background":"","sources":_vm.formatSources(column.image[0]),"alt":column.image[0].title}})],1)])}),1)],1):_vm._e(),_vm._v(" "),(_vm.columns)?_c('li',[_c('Grid',{staticClass:"c_navigation_full_width__columns",attrs:{"element-type":"ul","columns":_vm.columns.length}},_vm._l((_vm.columnWithNavigationLinks(_vm.columns)),function(column,columnIndex){return _c('li',{key:columnIndex,staticClass:"c_navigation_full_width__columns__column",class:[
          columnIndex === 0
            ? 'c_text--left c_navigation_full_width__columns__column--first'
            : columnIndex === _vm.columns.length - 1 && _vm.columns.length <= 5
            ? 'c_text--right'
            : _vm.columns.length <= 5
            ? 'c_text--center'
            : 'c_text--left',
          columnIndex === _vm.columns.length - 1
            ? 'c_navigation_full_width__columns__column--no_border c_navigation_full_width__columns__column--last'
            : '',
        ]},[_c('ul',{staticClass:"c_navigation_full_width__list c_text--left"},_vm._l((_vm.columnNavigationLinksHasHref(column)),function(link,linkIndex){return _c('li',{key:linkIndex},[_c('LinkField',{staticClass:"c_navigation_full_width__list__link c_navigation__link",class:linkIndex == 0 ? 'c_text--graphik_semibold' : '',attrs:{"super-table-field":link,"button-attrs":{ unstyle: true, reset: true }}},[(_vm.parseLinkField(link, 'superTable').labelText)?_c(linkIndex == 0 ? 'strong' : 'span',{tag:"span"},[_vm._v(_vm._s(_vm.parseLinkField(link, 'superTable').labelText))]):_vm._e()],1)],1)}),0)])}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }