export const productTabMultiColumnTableGql = `... on product_multiColumnTableTab_Entry {
  tabTitle
  tabHeader
  multiColumnTable {
    ... on multiColumnTable_twoColumnRows_BlockType {
      typeHandle
      table: twoColumnTable {
        column1 @markdown(flavor: "gfm-comment")
        column2 @markdown(flavor: "gfm-comment")
      }
    }
    ... on multiColumnTable_threeColumnRows_BlockType {
      typeHandle
      table: threeColumnTable {
        column1 @markdown(flavor: "gfm-comment")
        column2 @markdown(flavor: "gfm-comment")
        column3 @markdown(flavor: "gfm-comment")
      }
    }
    ... on multiColumnTable_fiveColumnRows_BlockType {
      typeHandle
      labels {
        label1
        label2
      }
      table: fiveColumnTable {
        column1 @markdown(flavor: "gfm-comment")
        column2 @markdown(flavor: "gfm-comment")
        column3 @markdown(flavor: "gfm-comment")
        column4 @markdown(flavor: "gfm-comment")
        column5 @markdown(flavor: "gfm-comment")
      }
    }
    ... on multiColumnTable_header_BlockType {
      typeHandle
      text @markdown(flavor: "gfm-comment")
    }
    ... on multiColumnTable_disclosureRow_BlockType {
      typeHandle
      information
    }
  }
}`;
