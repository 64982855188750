<template>
  <nav v-if="(filteredNavEntries.length || showSearchToggle) && showNav">
    <ul class="c_navigation" :class="isSubNav ? ['c_wrapper', 'c_navigation--sub_nav'] : ''">
      <li class="c_navigation__top_lvl_item" v-if="isSubNav">
        <Button
          class="c_navigation__item c_navigation__top_lvl_item__link c_button--reset c_navigation__top_lvl_item__link"
          aria-expanded="true"
          :aria-label="section[0].ariaLabel"
          :title="section[0].ariaLabel"
          :href="section[0].element[0].uri"
        >
          <label>{{ section[0].buttonLabel }} {{ $t('Home') }}</label>
        </Button>
      </li>
      <li
        v-for="(item, itemIndex) in filteredNavEntries"
        :key="itemIndex"
        class="c_navigation__top_lvl_item"
        :class="[
          activeItem === itemIndex ? 'c_navigation__top_lvl_item--active' : '',
          item.typeHandle === 'singleColumn' ? 'c_navigation__top_lvl_item--single_column' : '',
        ]"
      >
        <LinkField
          v-if="
            (item.typeHandle === 'singleItem' || item.typeHandle === 'utilityItem') &&
            item.linksMatrix &&
            item.linksMatrix[0]
          "
          class="c_navigation__single_item c_navigation__top_lvl_item__link"
          :class="activeItem === itemIndex ? 'c_navigation__top_lvl_item__link--active' : ''"
          :matrix-field="item.linksMatrix[0]"
          :button-attrs="{ unstyle: true, reset: true }"
          @onClick="topNavClicked(itemIndex)"
        />
        <Button
          v-else-if="
            (item.typeHandle === 'singleColumn' && item.linksMatrix && item.linksMatrix.length) ||
            (item.typeHandle === 'fullWidth' &&
              ((item.navigationColumns && item.navigationColumns.length) ||
                (item.navigationColumnsWithImages && item.navigationColumnsWithImages.length))) ||
            (item.typeHandle === 'fullWidthIcons' &&
              ((item.navigationColumns && item.navigationColumns.length) ||
                (item.navigationIconLinks && item.navigationIconLinks.length))) ||
            (item.typeHandle === 'section2022' &&
              ((item.navigationColumns && item.navigationColumns.length) ||
                (item.navigationIconLinks && item.navigationIconLinks.length)))
          "
          @onClick="topNavClicked(itemIndex)"
          class="c_navigation__item c_navigation__top_lvl_item__link"
          :class="activeItem === itemIndex ? 'c_navigation__top_lvl_item__link--active' : ''"
          :aria-expanded="activeItem === itemIndex ? 'true' : 'false'"
          :id="'topLvl--' + item.title + '--' + itemIndex"
          :href="null"
          :label-text="item.title"
          unstyle
          reset
        />
        <div
          :hidden="activeItem !== itemIndex || !allowDrawersToOpen"
          class="c_navigation__dropdown c_box--shadow--center"
          :class="isSubNav && item.typeHandle !== 'singleColumn' ? 'c_navigation__dropdown--break_out' : ''"
        >
          <NavigationSingleColumn
            v-if="item.typeHandle === 'singleColumn'"
            class="c_navigation__single_column"
            element-type="ul"
            :menu-items="item.linksMatrix"
          />
          <NavigationFullWidth
            v-if="item.typeHandle === 'fullWidth'"
            element-type="ul"
            class="c_navigation__full_width c_wrapper"
            :columns="item.navigationColumns"
            :columns-with-images="item.navigationColumnsWithImages"
            :image-optimized-fields="['uiNavigationImage']"
          />
          <NavigationFullWidth
            v-if="item.typeHandle === 'fullWidthIcons'"
            element-type="ul"
            class="c_navigation__full_width_icons c_wrapper"
            :columns="item.navigationColumns"
            :icon-columns="item.navigationIconLinks"
            :image-optimized-fields="['uiNavigationImage']"
          />
          <NavigationSection
            v-if="item.typeHandle === 'section2022'"
            element-type="ul"
            class="c_wrapper"
            :columns="item.navigationColumns"
            :icon-columns="item.navigationIconLinks"
            :featured-content="item.navigationFeaturedContent[0] || null"
            :image-optimized-fields="['uiNavigationImage', 'relatedEntries']"
            :layout="item.navigationColumnLayout || '1'"
          />
        </div>
      </li>
      <li
        class="c_navigation__top_lvl_item c_navigation__top_lvl_item--border"
        v-show="searchToggleVisible"
        v-if="showSearchToggle && !isSubNav"
      >
        <GlobalSearchToggle class="c_navigation__top_lvl_item__link" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { log } from 'JS/global.js';
import { mapState } from 'vuex';
import Button from 'Components/button/Button.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import NavigationSection from 'Components/navigation/NavigationSection.vue';
import NavigationSingleColumn from 'Components/navigation/NavigationSingleColumn.vue';
import NavigationFullWidth from 'Components/navigation/NavigationFullWidth.vue';
import GlobalSearchToggle from 'Components/search/GlobalSearchToggle.vue';
import { locale } from '@/nuxtGenerateRoutes.js';

export default {
  name: 'Navigation',
  components: {
    Button,
    LinkField,
    NavigationSection,
    NavigationSingleColumn,
    NavigationFullWidth,
    GlobalSearchToggle,
  },
  data() {
    return {
      activeItem: null,
    };
  },
  props: {
    ariaLabel: {
      type: String,
      default: null,
      required: false,
    },
    isSubNav: {
      type: Boolean,
      default: false,
    },
    navEntries: {
      type: Array,
      required: true,
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    section: {
      type: Array,
      default: null,
    },
    searchToggleVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredNavEntries() {
      const unfilteredNavEntries = this.navEntries || [];
      return unfilteredNavEntries.filter(
        (item) =>
          item?.navigationColumns?.length ||
          item?.navigationIconLinks?.length ||
          item?.navigationColumnsWithCopy ||
          (item?.linksMatrix && item?.linksMatrix[0]?.element?.length) ||
          (item?.linksMatrix && item?.linksMatrix[0]?.urlText) ||
          item?.urlText
      );
    },
    getSearchPath() {
      if (this.$config.craftGlobals?.search) {
        const localeId = Object.keys(locale).find((key) => locale[key] === this.$i18n.locale);
        const itemsForSite = this.$config.craftGlobals.search.filter((item) => {
          return item.siteId.toString() === localeId.toString();
        });
        return `/${this.$i18n?.locale}/${itemsForSite[0]?.uri}/`;
      }
      return null;
    },
    showSearchToggle() {
      if (this.getSearchPath && this.getSearchPath === this.$route.path) {
        return false;
      }
      return true;
    },
    ...mapState({
      allowDrawersToOpen: (state) => state.global.allowDrawersToOpen,
      showMobileNav: (state) => state.global.showMobileNav,
    }),
  },
  methods: {
    initialActiveItem() {
      if (this.isSubNav) {
        this.activeItem = 0;
      }
    },
    topNavClicked(topLevelIndex, event) {
      log('top level index', topLevelIndex);
      this.closeSearch();
      if (this.activeItem !== topLevelIndex) {
        this.activeItem = topLevelIndex;
      } else {
        this.activeItem = null;
      }
      this.$emit('topNavClicked', event);
    },
    closeSearch() {
      this.$store.commit('global/updatedSearchOpen', false);
      this.$store.commit('global/changeAllowDrawersToOpen', true);
    },
  },
  mounted() {
    this.initialActiveItem();
    this.$on('globlal-search-toggled', () => {
      this.activeItem = null;
    });
  },
  watch: {
    $route() {
      this.activeItem = null;
    },
  },
};
</script>

<style lang="scss">
.c_navigation {
  $self: &;
  --navigation--font-size: #{px_to_rem(16)};
  --navigation-item--color: #{$color_black};
  --navigation-item--hover-color: #{$color_red};
  --navigation-secondary-item--color: #{$color_black};
  --navigation-secondary-item--border-color: #{$color_black};
  --navigation-secondary-item--hover-color: #{$color_red};
  .app--navigation-dark & {
    --navigation-secondary-item--color: #{$color_white};
    --navigation-secondary-item--border-color: #{$color_white};
    --navigation-secondary-item--hover-color: #{$color_red};
  }
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: px_to_rem(24);
  height: 100%;

  @at-root #{$self}--sub_nav {
    display: flex;
    flex-flow: row nowrap;
  }
  @at-root #{$self}__top_lvl_item {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 max-content;
    align-items: center;
    @at-root #{$self}--sub_nav #{&} {
      flex: 0 1 16.667%;
      padding: px_to_rem(15) 0;
    }

    @at-root #{&}--single_column {
      position: relative;
    }

    #{&}__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-decoration: none;
      @include font('graphik_medium');
      font-size: px_to_rem(18);
      line-height: px_to_rem(21);
      width: max-content;
      max-width: px_to_rem(175);
      height: 100%;
      box-sizing: border-box;

      @include mq($mq_md_to_lg) {
        max-width: max-content;
      }
      .c_global_header__navigation_and_links--secondary & {
        @include font('graphik_regular');
        font-size: px_to_rem(16);
      }
      .c_global_header__navigation__utility & {
        font-size: px_to_rem(14);
      }
      & > a {
        color: $color_black;
        text-decoration: none;
      }
      &:hover,
      &:focus {
        border-bottom: px_to_rem(4) solid;
        border-color: $color_white;
      }
      .c_global_header__navigation_and_links--secondary & {
        color: var(--navigation-secondary-item--color);
        &:hover,
        &:focus {
          border-color: var(--navigation-secondary-item--border-color);
        }
      }
      @at-root #{&}--active {
        border-bottom: px_to_rem(4) solid;
        border-color: $color_red;
        &:hover,
        &:focus {
          border-color: $color_red;
        }
        .c_global_header__navigation_and_links--secondary & {
          border-bottom: px_to_rem(4) solid;
          border-color: $color_yellow;
          &:hover,
          &:focus {
            border-color: $color_yellow;
          }
        }
      }
      @at-root #{$self}--sub_nav #{&} {
        width: 100%;
        border-bottom: 0;
        margin: 0;
        border-left: px_to_rem(4) solid $color_white;
        padding: px_to_rem(10) 0 px_to_rem(10) px_to_rem(15);
        font-size: px_to_rem(14);
        &:hover,
        &:focus {
          border-color: $color_black;
        }
        @at-root #{&}--active {
          border-color: $color_red;
          &:hover,
          &:focus {
            border-color: $color_red;
          }
        }
      }
    }
    @at-root #{&}--border {
      border-left: solid px_to_rem(2);
      padding-left: px_to_rem(24);
    }
  }
  #{$self}__link {
    text-decoration: none;
    font-size: var(--navigation--font-size);
    line-height: px_to_rem(22.5);
    color: var(--navigation-item--color);
    &:hover,
    &:focus {
      color: var(--navigation-item--hover-color);
    }
  }
  @at-root #{$self}__dropdown {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    visibility: visible;
    width: max-content;
    min-width: 100%;
    max-width: 100vw;
    background: $color_gray_240;
    z-index: 500;
    &[hidden] {
      display: none;
      visibility: hidden;
    }
    @at-root #{$self}__top_lvl_item--single #{&} {
      width: auto;
    }
    @at-root #{&}--break_out {
      width: 100vw;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
}
</style>
