import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const textIconListGql = `... on landingPageContent_textIconList_BlockType{
    typeHandle
    eyebrow
    header
    text
    textColumns
    icons{
        ... on icons_BlockType{
            icon{
                title
                url
            }
            label
            ${linksSuperTableGql()}
        }
    }
    colors
}`;
