<template>
  <ul class="c_navigation_full_width">
    <li v-if="iconColumns">
      <Grid
        class="c_navigation_full_width__icon_columns"
        :template-columns="`repeat(${iconColumns.length}, minmax(auto, 150px))`"
        gap="15px"
        justify="center"
      >
        <li
          class="c_navigation_full_width__icon_columns__column"
          v-for="(item, iconIndex) in iconColumns"
          :key="iconIndex"
        >
          <LinkField
            class="c_navigation_full_width__icon_columns__link"
            v-if="item.navigationLink.length && item.icon.length"
            :super-table-field="item.navigationLink[0]"
            :button-attrs="{ unstyle: true, reset: true }"
          >
            <img
              class="c_navigation_full_width__icon_columns__icon"
              v-if="item.icon[0].url"
              :src="item.icon[0].url"
              :alt="item.icon[0].title"
            />
            <p v-if="parseLinkField(item.navigationLink[0], 'superTable').labelText">
              {{ parseLinkField(item.navigationLink[0], 'superTable').labelText }}
            </p>
          </LinkField>
        </li>
      </Grid>
    </li>
    <li v-if="columnsWithImages">
      <Grid
        :columns="columnsWithImages.length"
        column-width="33.33%"
        class="c_navigation_full_width__columns_with_images"
      >
        <Grid
          :columns="2"
          element-type="li"
          class="c_navigation_full_width__columns_with_images__column c_text--left"
          :class="[
            columnIndex === 0 ? 'c_navigation_full_width__columns_with_images__column--first' : '',
            columnIndex === columnsWithImages.length - 1
              ? 'c_navigation_full_width__columns_with_images__column--last'
              : '',
          ]"
          v-for="(column, columnIndex) in columnWithNavigationLinks(columnsWithImages)"
          :key="columnIndex"
        >
          <ul class="c_navigation_full_width__list">
            <li v-for="(link, linkIndex) in columnNavigationLinksValid(column)" :key="linkIndex">
              <LinkField
                class="c_navigation_full_width__list__link c_navigation__link"
                :super-table-field="link"
                :button-attrs="{ unstyle: true, reset: true }"
                element-type="li"
              >
                <span
                  :is="linkIndex === 0 ? 'strong' : 'span'"
                  v-if="parseLinkField(link, 'superTable').labelText"
                  :class="linkIndex === 0 ? 'c_text--graphik_semibold' : ''"
                  >{{ parseLinkField(link, 'superTable').labelText }}</span
                >
              </LinkField>
            </li>
          </ul>
          <div class="c_navigation_full_width__columns_with_images__column__image c_text--right">
            <img
              class="c_navigation_full_width__columns_with_images__column__image--svg"
              v-if="column.image[0].url"
              :alt="column.image[0].title"
              :src="column.image[0].url"
            />
            <MediaImage background v-else :sources="formatSources(column.image[0])" :alt="column.image[0].title" />
          </div>
        </Grid>
      </Grid>
    </li>
    <li v-if="columns">
      <Grid class="c_navigation_full_width__columns" element-type="ul" :columns="columns.length">
        <li
          class="c_navigation_full_width__columns__column"
          :class="[
            columnIndex === 0
              ? 'c_text--left c_navigation_full_width__columns__column--first'
              : columnIndex === columns.length - 1 && columns.length <= 5
              ? 'c_text--right'
              : columns.length <= 5
              ? 'c_text--center'
              : 'c_text--left',
            columnIndex === columns.length - 1
              ? 'c_navigation_full_width__columns__column--no_border c_navigation_full_width__columns__column--last'
              : '',
          ]"
          v-for="(column, columnIndex) in columnWithNavigationLinks(columns)"
          :key="columnIndex"
        >
          <ul class="c_navigation_full_width__list c_text--left">
            <li v-for="(link, linkIndex) in columnNavigationLinksHasHref(column)" :key="linkIndex">
              <LinkField
                class="c_navigation_full_width__list__link c_navigation__link"
                :class="linkIndex == 0 ? 'c_text--graphik_semibold' : ''"
                :super-table-field="link"
                :button-attrs="{ unstyle: true, reset: true }"
              >
                <span :is="linkIndex == 0 ? 'strong' : 'span'" v-if="parseLinkField(link, 'superTable').labelText">{{
                  parseLinkField(link, 'superTable').labelText
                }}</span>
              </LinkField>
            </li>
          </ul>
        </li>
      </Grid>
    </li>
  </ul>
</template>

<script>
import { parseLinkField } from 'JS/global.js';

import Button from 'Components/button/Button.vue';
import Grid from 'Components/grid/Grid.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import NavigationItem from 'Components/navigation/NavigationItem.vue';

export default {
  name: 'NavigationFullWidth',
  components: {
    Button,
    Grid,
    LinkField,
    MediaImage,
    NavigationItem,
  },
  props: {
    columnsWithImages: Array,
    columns: Array,
    elementType: {
      type: String,
      default: 'div',
    },
    iconColumns: Array,
    imageOptimizedFields: Array,
  },
  computed: {},
  methods: {
    columnWithNavigationLinks(column) {
      return column.filter((link) => link.navigationLinks?.length);
    },
    columnNavigationLinksHasHref(column) {
      return column.navigationLinks.filter((link) => parseLinkField(link, 'superTable').href);
    },
    columnNavigationLinksValid(column) {
      return column.navigationLinks.filter((link) => parseLinkField(link, 'superTable').valid);
    },
    formatSources(image) {
      const returnArray = [];
      for (let i = 0; i < this.imageOptimizedFields.length; i++) {
        returnArray.push({ srcset: image[this.imageOptimizedFields[i]].srcsetWebp, type: 'webp' });
        returnArray.push({ srcset: image[this.imageOptimizedFields[i]].srcset });
      }
      return returnArray;
    },
    parseLinkField,
  },
};
</script>

<style lang="scss">
.c_navigation_full_width {
  $self: &;
  margin: 0 auto;
  padding: px_to_rem(25) var(--wrapper-padding-right, var(--wrapper-padding, 5vw)) px_to_rem(25)
    var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
  @at-root #{$self}__icon_columns {
    padding-bottom: px_to_rem(25);
    @at-root #{&}__column {
      text-align: center;
      //word-break: break-word;
    }
    #{&}__link {
      text-align: center;
      display: inline-block;
      text-decoration: none;
      color: var(--navigation-item--color);
      &:hover,
      &:focus {
        color: var(--navigation-item--hover-color);
      }
    }
    @at-root #{&}__icon {
      width: px_to_rem(40);
      max-height: px_to_rem(50);
      margin: 0 auto;
      padding-bottom: px_to_rem(10);
      @include mq($mq_nav_1) {
        width: px_to_rem(60);
        max-height: px_to_rem(70);
      }
    }
    @at-root #{&}__button {
      text-decoration: none;
      color: $color_black;
      display: block;
      font-size: px_to_rem(14);
      &:hover,
      &:focus {
        color: $color_red;
      }
      @include mq($mq_nav_1) {
        font-size: inherit;
      }
    }
  }
  @at-root #{$self}__columns_with_images {
    padding-bottom: px_to_rem(25);
    @at-root #{&}__column {
      $column: &;
      $column_padding: px_to_rem(20);
      border-right: px_to_rem(1) solid $color_gray_191;
      .c_navigation_full_width__list {
        width: 100%;
        padding-left: $column_padding;
        box-sizing: border-box;
      }
      @at-root #{&}--first .c_navigation_full_width__list {
        padding-left: 0;
      }
      @at-root #{&}--last {
        border: 0;
      }
      @at-root #{&}__image {
        min-height: px_to_rem(150);
        padding-left: $column_padding;
        @at-root #{&}--svg {
          width: 100%;
          padding-right: $column_padding;
          box-sizing: border-box;
          @at-root #{$column}--last #{&} {
            padding-right: 0;
            width: calc(100% - #{$column_padding});
          }
        }
      }
    }
  }
  @at-root #{$self}__columns {
    padding-top: px_to_rem(25);
    border-top: px_to_rem(1) solid $color_gray_191;
    @at-root #{&}__column {
      border-right: px_to_rem(1) solid $color_gray_191;
      padding: 0 px_to_rem(15);
      @at-root #{&}--first {
        padding-left: 0;
      }
      @at-root #{&}--last {
        padding-right: 0;
      }
      @at-root #{&}--no_border {
        border: 0;
      }
    }
  }

  @at-root #{$self}__list {
    display: inline-block;
    margin: 0 auto;
    font-size: var(--navigation--font-size);
    @at-root #{&}__link {
      display: inline-block;
      text-decoration: none;
      padding-bottom: px_to_rem(10);
      @at-root #{&}--last {
        padding-bottom: 0;
      }
    }
  }
}
</style>
