export const linksSuperTableGql = function (handle = '', limit = '') {
  return `${handle}${limit !== '' ? `(limit: ${limit})` : ''}${handle !== '' ? ` {` : ''}
  ${handle !== '' ? `... on ${handle}_BlockType {` : ''}
    entry {
      title
      uri
      ... on product_contentTab_Entry {
        tabTitle
      }
      ... on product_tableTab_Entry {
        tabTitle
      }
      ... on product_multiColumnTableTab_Entry {
        tabTitle
      }
      ... on product_compareTab_Entry {
        tabTitle
      }
      ... on product_relatedTab_Entry {
        tabTitle
      }
    }
    productCategory {
      title
      uri
    }
    asset {
      title
      uri: url
    }
    custom
    buttonLabel
    ariaLabel
    openInANewWindow
  ${handle !== '' ? `}` : ''}
${handle !== '' ? `}` : ''}`;
};
