<template>
  <ul class="c_navigation_section" :style="[columnContentLayout.styles]">
    <li v-if="iconColumns && iconColumns.length" class="c_navigation_section__item">
      <Grid element-type="ul" class="c_navigation_section__icon_columns" gap="8px">
        <li
          class="c_navigation_section__icon_columns__column"
          v-for="(item, iconIndex) in iconColumns"
          :key="iconIndex"
        >
          <LinkField
            class="c_navigation_section__icon_columns__link"
            v-if="item.navigationLink.length && item.icon.length"
            :super-table-field="item.navigationLink[0]"
            :button-attrs="{ unstyle: true, reset: true }"
          >
            <span
              v-if="parseLinkField(item.navigationLink[0], 'superTable').labelText"
              class="c_navigation_section__icon_columns__label"
            >
              {{ parseLinkField(item.navigationLink[0], 'superTable').labelText }}
            </span>
            <img
              class="c_navigation_section__icon_columns__icon"
              v-if="item.icon[0].url"
              :src="item.icon[0].url"
              :alt="item.icon[0].title"
            />
          </LinkField>
        </li>
      </Grid>
    </li>
    <li v-if="columns && columns.length" class="c_navigation_section__item">
      <Grid
        class="c_navigation_section__columns"
        element-type="ul"
        :columns="4"
        :gap="featuredContent ? '0 1rem' : null"
      >
        <li class="c_navigation_section__columns__links">
          <Grid element-type="ul" :columns="columnContentLayout.raw || 4" gap="0">
            <template v-for="(column, columnIndex) in columns">
              <li
                class="c_navigation_section__column"
                :class="{
                  'c_navigation_section__column--border-right': columnHasBorder(columnIndex + 1),
                }"
                :key="`content-${columnIndex}`"
              >
                <ul class="c_navigation_section__list c_text--left">
                  <p
                    v-if="column.eyebrow"
                    class="c_navigation_section__column__eyebrow c_text--graphik_semibold"
                    :class="[{ 'c_navigation_section__column__eyebrow--large': column.eyebrowSize === '1' }]"
                  >
                    {{ column.eyebrow }}
                  </p>
                  <p v-if="column.copy" class="c_navigation_section__column__copy">{{ column.copy }}</p>
                  <li v-for="(link, linkIndex) in columnNavigationLinksHasHref(column)" :key="linkIndex">
                    <LinkField
                      class="c_navigation_section__list__link c_navigation__link"
                      :class="[{ 'c_text--graphik_semibold': link.bolded }]"
                      :super-table-field="link"
                      :button-attrs="{ unstyle: true, reset: true }"
                    >
                      <span v-if="parseLinkField(link, 'superTable').labelText">
                        {{ parseLinkField(link, 'superTable').labelText }}
                      </span>
                    </LinkField>
                  </li>
                </ul>
              </li>
              <li
                v-if="columnHasDivider(columnIndex + 1, columns.length)"
                class="c_navigation_section__divider"
                role="presentation"
                :key="`divider-${columnIndex}`"
              >
                <hr />
              </li>
            </template>
          </Grid>
        </li>
        <navigation-featured-content
          v-if="featuredContent"
          element="li"
          :content="featuredContent"
          :image-optimized-fields="imageOptimizedFields"
        ></navigation-featured-content>
      </Grid>
    </li>
  </ul>
</template>

<script>
import { parseLinkField } from 'JS/global.js';

import Grid from 'Components/grid/Grid.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import NavigationFeaturedContent from './NavigationFeatureContent.vue';

export default {
  name: 'NavigationSection',
  components: {
    Grid,
    LinkField,
    NavigationFeaturedContent,
  },
  props: {
    columns: Array,
    elementType: {
      type: String,
      default: 'div',
    },
    iconColumns: Array,
    imageOptimizedFields: Array,
    featuredContent: {
      type: Object,
      default: () => {},
    },
    layout: {
      type: String,
      default: '1',
    },
  },
  computed: {
    columnContentLayout() {
      if (this.featuredContent) {
        switch (this.layout) {
          case '2':
            return {
              styles: { '--navigation-section-column-span': 'span 2', '--navigation-section-feature-span': 'span 2' },
              raw: 2,
            };
          default:
            return {
              styles: { '--navigation-section-column-span': 'span 3', '--navigation-section-feature-span': 'span 1' },
              raw: 3,
            };
        }
      }
      return { styles: { '--navigation-section-column-span': 'span 4' }, raw: 4 };
    },
  },
  methods: {
    columnNavigationLinksHasHref(column) {
      return column.navigationLinks.filter((link) => parseLinkField(link, 'superTable').href);
    },
    parseLinkField,
    columnHasBorder(index) {
      if (index && this.columnContentLayout?.raw) {
        if (index % this.columnContentLayout?.raw === 0) {
          return false;
        }
      }
      return true;
    },
    columnHasDivider(index, indexLength) {
      if (index && indexLength > index && this.columnContentLayout?.raw) {
        if (index % this.columnContentLayout?.raw === 0) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.c_navigation_section {
  $self: &;
  margin: 0 auto;
  padding: px_to_rem(22) var(--wrapper-padding-right, var(--wrapper-padding, 5vw)) px_to_rem(22)
    var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
  @at-root #{$self}__item {
    &:not(:last-of-type) {
      margin-bottom: px_to_rem(22);
    }
  }
  @at-root #{$self}__icon_columns {
    grid-auto-rows: 1fr;
    justify-content: space-between;
    overflow: auto;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    @include mq(1280) {
      grid-template-columns: repeat(8, minmax(auto, 135px));
    }
    @at-root #{&}__column {
      text-align: center;
      box-sizing: border-box;
    }
    @at-root #{&}__link {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      text-decoration: none;
      background: $color_white;
      border-radius: px_to_rem(12);
      color: var(--navigation-item--color);
      height: 100%;
      min-width: 135px;
      padding: px_to_rem(10);
      &:hover,
      &:focus {
        color: var(--navigation-item--hover-color);
      }
      @include mq(1280) {
        min-height: 190px;
      }
    }
    @at-root #{&}__label {
      text-align: center;
      @include font('graphik_medium');
      font-size: px_to_rem(18);
      line-height: px_to_rem(24);
      width: 100%;
    }
    @at-root #{&}__icon {
      align-self: flex-end;
      width: px_to_rem(40);
      max-height: px_to_rem(50);
      margin: 1rem auto 0 auto;
      @include mq($mq_nav_1) {
        width: px_to_rem(60);
        max-height: px_to_rem(70);
      }
    }
    @at-root #{&}__button {
      text-decoration: none;
      color: $color_black;
      display: block;
      font-size: px_to_rem(14);
      &:hover,
      &:focus {
        color: $color_red;
      }
      @include mq($mq_nav_1) {
        font-size: inherit;
      }
    }
  }
  @at-root #{$self}__columns {
    @at-root #{&}__links {
      grid-column: var(--navigation-section-column-span);
      margin: 0 -1.5rem;
      > ul {
        height: 100%;
      }
    }
  }

  @at-root #{&}__column {
    $column: &;
    position: relative;
    padding: px_to_rem(16) px_to_rem(24);
    box-sizing: border-box;
    // border-right: px_to_rem(1) solid $color_gray_191;
    @at-root #{&}--border-right {
      border-right: px_to_rem(1) solid $color_gray_191;
    }
    @at-root #{&}__eyebrow {
      font-size: px_to_rem(16);
      line-height: normal;
      margin: 0 0 1.5625rem 0;
      @at-root #{&}--large {
        font-size: px_to_rem(18);
      }
    }
    @at-root #{&}__copy {
      font-size: px_to_rem(16);
      line-height: px_to_rem(26);
      margin-bottom: 1rem;
    }
  }

  @at-root #{&}__divider {
    display: grid;
    align-items: center;
    grid-column: var(--navigation-section-column-span);
    margin: 0 px_to_rem(24);
  }

  @at-root #{$self}__list {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    font-size: var(--navigation--font-size);
    z-index: 2;
    @at-root #{&}__link {
      display: inline-block;
      text-decoration: none;
      padding-bottom: px_to_rem(10);
    }
  }
}
</style>
