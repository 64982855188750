export const videoBannerGql = `... on landingPageContent_videoBanner_BlockType{
    typeHandle
    colors
    header
    text
    image{
        ... on landingPageVideos_Asset{
            title
            videoId
            videoSource
            lpBackgroundWide{
                srcsetWebp
                srcset
            }
        }
    }
    backgroundVideo {
        id
            ... on videoUploads_Asset{
                    url
                    size
                    extension
            }
    }
}`;
