import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const imageCardGridGql = `... on landingPageContent_imageCardGrid_BlockType{
    typeHandle
    alignment
    colors
    image{
        title
        ... on landingPageImages_Asset{
            lpBackgroundSquare{
                srcset
                srcsetWebp
            }
        }
    }
    cards: imageCardGridCards{
        ... on imageCardGridCards_BlockType{
            image{
                title
                ... on landingPageImages_Asset{
                    lpBackgroundSquare{
                        srcset
                        srcsetWebp
                    }
                }
            }
            header
            ${linksSuperTableGql()}
        }
    }
}`;
