export const cSPromoBlock2023Gql = `... on landingPageContent2023_cSPromoBlock_BlockType {
  typeHandle
  displayTitle
  customerStoryStyle
  customerStory {
    ... on blog_customerStory_Entry {
      cardTitle
      cardDescription
      location
      customerStorySecondaryTitle
      customerStorySecondaryDescription
      uri
      customerStoryImage {
        ... on images_Asset {
          uncropped1920w {
            srcWebp
            srcset
            src
          }
        }
      }
    }
  }
}`;
