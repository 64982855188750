import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';
import { linksMatrixGql } from '../link_field/linksMatrixGql.js';

export const blogAndPressReleasesGql = `... on landingPageContent_blogAndPressReleases_BlockType{
    typeHandle
    header
    colors
    includeBlogPosts
    includePressReleases
    blogCategories {
		id
		title
  	}
  	pressReleaseCategories {
  		id
  		title
  	}
  	productCategories{
  		id
  		title
    }
    overridePostFeed:featuredPostOverride{
      id
      title
      uri
      type:typeHandle
      ...on blog_blog_Entry{
        eyebrowDate:postDate @formatDateTime(format: "F j, Y")
        summary:articleSummary
        image:blogFeaturedImage{
          ... on blogNews_Asset{
            title
            images:relatedEntries{
              srcset
              srcsetWebp
            }
          }
          ... on images_Asset{
            title
            images:lpImageGridSquare {
              srcset
              srcsetWebp
            }
          }
        }
      }
      ...on pressRelease_pressRelease_Entry{
        eyebrowDate:postDate @formatDateTime(format: "F j, Y")
        summary:articleSummary
        image:blogFeaturedImage{
          ... on blogNews_Asset{
            title
            images:relatedEntries{
              srcset
              srcsetWebp
            }
          }
          ... on images_Asset{
            title
            images:lpImageGridSquare {
              srcset
              srcsetWebp
            }
          }
        }
      }
      ... on product_productPage_Entry{
        summary:productOverviewDescription
        image:productFeaturedImage{
          ... on productImages_Asset{
            title
            images:galleryThumbnail{
              srcset
              srcWebp
            }
          }
          ... on images_Asset{
            title
            images:lpImageGridSquare {
              srcset
              srcsetWebp
            }
          }
        }
      }
      ... on product_productPage2022_Entry{
        summary:relatedEntryDescription
        image:productFeaturedImage{
          ... on productImages_Asset{
            title
            images:galleryThumbnail{
              srcset
              srcWebp
            }
          }
          ... on images_Asset{
            title
            images:lpImageGridSquare {
              srcset
              srcsetWebp
            }
          }
        }
      }
      ... on landingPage_landingPage_Entry{
        summary:relatedEntryDescription
        image:landingPageFeaturedImage{
          ... on landingPageImages_Asset{
            title
            images:lpImageGridLandscape{
              srcset
              srcWebp
            }
          }
        }
      }
      ... on landingPage_landingPage2023_Entry{
        summary:relatedEntryDescription
        image:landingPageFeaturedImage{
          ... on landingPageImages_Asset{
            title
            images:lpImageGridLandscape{
              srcset
              srcWebp
            }
          }
        }
      }
      ... on event_eventV2_Entry{
        eyebrowDate: eventStartDateTime @formatDateTime(format: "F j, Y")
        summary: relatedEntryDescription
        eventOverviewLinks: ${linksMatrixGql('', 2)}
        image: eventOverviewImage{
          ... on images_Asset{
            title
            images:lpImageGridSquare {
              srcset
              srcsetWebp
            }
          }
        }
      }
      ... on blog_customerStory_Entry{
        eyebrowDate:postDate @formatDateTime(format: "F j, Y")
        summary: relatedEntryDescription
        image:blogFeaturedImage {
          ... on images_Asset {
            title
            images:lpImageGridSquare {
              srcsetWebp
              srcset
            }
          }
        }
      }
    }
    ${linksSuperTableGql('blogAndPressReleasesButton')}
}`;
