<template>
  <div
    class="c_search_widget c_box"
    :class="{
      'c_box--black': navigation,
      'c_search_widget--hidden': searchHidden,
      'c_search_widget--visible': getSearchState && searchHidden,
    }"
  >
    <form
      :action="searchAction ? `${searchAction}` : null"
      :method="searchAction ? 'GET' : null"
      class="c_search_widget__form c_wrapper"
      @submit="validateForm"
    >
      <div class="c_search_widget__hidden">
        <label :for="honeypotName">{{ $t('Honeypot') }}</label>
        <input type="text" :id="honeypotName" :name="honeypotName" v-model="honeypot" tabindex="-1" ref="honeypot" />
      </div>
      <div class="c_search_widget__content">
        <label :for="searchInputId" class="c_search_widget__label">{{
          getGlobalCopy && getGlobalCopy[0].searchFormLabel ? getGlobalCopy[0].searchFormLabel : $t('Search')
        }}</label>
        <input
          type="search"
          name="q"
          :id="searchInputId"
          :placeholder="
            getGlobalCopy && getGlobalCopy[0].searchFormPlaceholder
              ? getGlobalCopy[0].searchFormPlaceholder
              : $t('Search Placeholder')
          "
          v-model="query"
          required
          class="c_search_widget__input"
          ref="searchInput"
          v-focus="getSearchState"
        />
      </div>
      <button
        v-if="!searchAction"
        type="submit"
        :value="
          getGlobalCopy && getGlobalCopy[0].searchFormButtonLabel
            ? getGlobalCopy[0].searchFormButtonLabel
            : $t('Search')
        "
        class="c_search_widget__submit c_button"
        @click.prevent="sendQuery()"
      >
        <IconSVG
          tag="span"
          class="c_search_widget__icon"
          stroke-color-var="white"
          :height="'25'"
          :width="'25'"
          stroke-width="4px"
          handle="search"
          aria-hidden="true"
          :replacements="{ Search: $t('Search') }"
        /><span class="c_search_widget__submit__label">{{
          getGlobalCopy && getGlobalCopy[0].searchFormButtonLabel
            ? getGlobalCopy[0].searchFormButtonLabel
            : $t('Search')
        }}</span>
      </button>
      <button
        v-else
        type="submit"
        :value="
          getGlobalCopy && getGlobalCopy[0].searchFormButtonLabel
            ? getGlobalCopy[0].searchFormButtonLabel
            : $t('Search')
        "
        class="c_search_widget__submit c_button"
        @click.prevent="sendQueryAsLocalStorage()"
      >
        <IconSVG
          tag="span"
          class="c_search_widget__icon"
          stroke-color-var="white"
          :height="'25'"
          :width="'25'"
          stroke-width="4px"
          handle="search"
          aria-hidden="true"
          :replacements="{ Search: $t('Search') }"
        /><span class="c_search_widget__submit__label">{{
          getGlobalCopy && getGlobalCopy[0].searchFormButtonLabel
            ? getGlobalCopy[0].searchFormButtonLabel
            : $t('Search')
        }}</span>
      </button>
    </form>
    <div role="region" id="search-results" aria-live="polite" class="c_search_widget__results">
      <div class="c_search_widget__results_success" v-if="pageResults">
        <SearchResult
          v-for="(item, index) in pageResults"
          :key="index"
          :name="item.name"
          :url="item.url"
          :snippet="item.snippet"
        />
        <Flex class="c_search_widget__pagination" v-if="pages && pages > 1">
          <button
            v-show="currentPage > 1"
            aria-controls="search-results"
            :aria-label="$t('Previous page')"
            @click="paginate('previous')"
            class="c_search_widget__pagination__indicator"
          >
            &lt;
          </button>
          <button
            v-for="(n, index) in pages"
            aria-controls="search-results"
            :aria-label="$t('Search page') + ` ${n}`"
            @click="paginate('single', index, n)"
            :key="index"
            :class="{ 'c_search_widget__pagination__indicator--current': currentPage == n }"
            class="c_search_widget__pagination__indicator"
          >
            {{ n }}
          </button>
          <button
            v-show="currentPage < pages"
            aria-controls="search-results"
            :aria-label="$t('Next page')"
            @click="paginate('next')"
            class="c_search_widget__pagination__indicator"
          >
            &gt;
          </button>
        </Flex>
      </div>
      <div class="c_search_widget__results_error">
        <MatrixBlockSearchPageAfterContent v-show="errorContent && errorContentVisible" :matrix-blocks="errorContent" />
        <MatrixBlockSearchPageAfterContent
          v-show="noResultsContent && noResultsContentVisible"
          :matrix-blocks="noResultsContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { log } from 'JS/global.js';
import axios from 'axios';
import { defineComponent } from '@nuxtjs/composition-api';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import SearchResult from 'Components/search/SearchResult.vue';
import Flex from 'Components/flex/Flex.vue';
import MatrixBlockSearchPageAfterContent from 'Components/matrix_blocks/MatrixBlockSearchPageAfterContent.vue';

export default defineComponent({
  name: 'SearchWidget',
  components: {
    Flex,
    IconSVG,
    SearchResult,
    MatrixBlockSearchPageAfterContent,
  },
  data() {
    return {
      honeypot: '',
      searchResponse: null,
      query: '',
      previousQuery: '',
      customConfigId: process.env.bingCustomConfigId || null,
      subscriptionKey: process.env.bingSubscriptionKey || null,
      currentPage: 1,
      defaultOffsetInterval: 10,
      currentOffsetStart: 0,
      currentOffsetEnd: this.defaultOffsetInterval,
      currentOffsetInterval: this.defaultOffsetInterval,
      errorContentVisible: false,
      noResultsContentVisible: false,
    };
  },
  props: {
    navigation: { type: Boolean, required: false, default: false },
    searchAction: { type: String, required: false, default: null },
    searchInputId: { type: String, required: true },
    searchHidden: { type: Boolean, required: false, default: false },
    searchResultsVisible: { type: Boolean, required: false, default: true },
    searchCount: { type: Number, required: false, default: 50 },
    errorContent: { type: Array, required: false, default: () => [] },
    noResultsContent: { type: Array, required: false, default: () => [] },
  },
  setup() {},
  computed: {
    getGlobalCopy() {
      if (this.$i18n.locale) {
        return this.$config?.craftGlobals?.search?.filter((item) => item.language === this.$i18n.locale);
      }
      return this.$config?.craftGlobals?.search?.filter((item) => item.language === 'en');
    },
    getSearchState() {
      return this.$store.state.global.searchOpen;
    },
    honeypotName() {
      return `fuzzy-${Math.floor(Math.random() * 1000)}`;
    },
    validateHoneypot() {
      if (this.honeypot !== '' || this.$refs.honeypot?.value !== '') {
        return false;
      }
      return true;
    },
    searchMarket() {
      if (this.$i18n.locale) {
        switch (this.$i18n.locale) {
          case 'ja':
            return 'ja-JP';
          case 'ko':
            return 'ko-KR';
          case 'zh':
            return 'zh-CN';
          default:
            return 'en-US';
        }
      }
      return 'en-US';
    },
    pageResults() {
      if (this.searchResultsVisible && this.searchResponse?.value?.length) {
        return this.searchResponse?.value.slice(this.currentOffsetStart, this.currentOffsetEnd);
      }
      return [];
    },
    pages() {
      if (this.searchResponse?.value?.length) {
        const pages = this.searchResponse?.value?.length / this.currentOffsetInterval;
        if (pages > 5) {
          return 5;
        } else {
          return Math.ceil(pages);
        }
      }
      return 1;
    },
  },
  directives: {
    focus: {
      update(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },
  watch: {
    query(input) {
      if (!this.searchAction) {
        if (input !== '') {
          this.query = input;
        } else {
          this.clearSearchResults();
        }
      }
    },
    honeypot(val) {
      this.honeypot = val;
    },
  },
  methods: {
    getQueryFromLocalStorage() {
      const q = localStorage?.getItem('searchQuery');
      if (q?.length) {
        this.query = q;
        this.sendQuery();
      }
    },
    disableHoneypotField() {
      if (this.$refs.honeypot) {
        this.$refs.honeypot.disabled = true;
      }
    },
    validateForm(e) {
      if (!this.validateHoneypot) {
        e.preventDefault();
        return false;
      }
      this.disableHoneypotField();
      return true;
    },
    setErrorMessageState(error = false, noResults = false) {
      this.errorContentVisible = error;
      this.noResultsContentVisible = noResults;
    },
    clearSearchResults() {
      this.searchResponse = {};
      this.currentPage = 1;
      this.currentOffsetStart = 0;
      this.currentOffsetEnd = this.defaultOffsetInterval;
      this.currentOffsetInterval = this.defaultOffsetInterval;
      this.errorContentVisible = false;
      this.noResultsContentVisible = false;
    },
    generateApiCall() {
      const endpoint = 'https://api.bing.microsoft.com/v7.0/custom/search';
      if (this.customConfigId && this.subscriptionKey && this.query) {
        return `${endpoint}?q=${encodeURIComponent(this.query)}&count=${
          this.searchCount
        }&textDecorations=true&textFormat=HTML&customconfig=${this.customConfigId}&mkt=${this.searchMarket}`;
      } else {
        return null;
      }
    },
    newQuery() {
      if (this.query !== this.previousQuery) {
        return true;
      }
      return false;
    },
    sendQueryAsLocalStorage() {
      // Check if the browser is client-side
      if (process.client) {
        // Set localStorage item equal to the search query
        localStorage.setItem('searchQuery', this.query);

        // Store const path to the search page
        const newPath = `/${this.$i18n.locale}/search/`;

        // If the current path is not the search page, redirect to the search page
        if (this.$router.path !== newPath) {
          this.$router.push({ path: newPath });
        }
        // Get the query from localStorage and submit to axios
        this.getQueryFromLocalStorage();
      }
    },
    sendQuery() {
      if (this.newQuery()) {
        this.clearSearchResults();
        this.$store.commit('global/changeAllowDrawersToOpen', true);
        const apiCall = this.generateApiCall();
        if (apiCall) {
          axios({
            url: apiCall,
            method: 'get',
            data: null,
            headers: {
              'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            },
          })
            .then((result) => {
              if (result?.data?.webPages) {
                this.previousQuery = this.query;
                this.searchResponse = result?.data?.webPages || {};
                this.setErrorMessageState(false, false);
                // Set 'searchQuery' value back to an empty string
                localStorage.setItem('searchQuery', '');
              } else {
                this.setErrorMessageState(false, true);
              }
            })
            .catch((errorMessage) => {
              log(errorMessage);
              this.setErrorMessageState(true, false);
            });
        }
      }
    },
    paginate(type, index, n) {
      if (type && type !== '') {
        switch (type) {
          case 'next':
            this.currentPage += 1;
            this.currentOffsetStart += this.currentOffsetInterval;
            this.currentOffsetEnd += this.currentOffsetInterval;
            this.$nextTick(() => {
              window.scrollTo(0, 0);
              this.$refs.searchInput.focus({ preventScroll: true });
            });
            break;
          case 'previous':
            this.currentOffsetStart -= this.currentOffsetInterval;
            this.currentOffsetEnd -= this.currentOffsetInterval;
            this.currentPage -= 1;
            this.$nextTick(() => {
              window.scrollTo(0, 0);
              this.$refs.searchInput.focus({ preventScroll: true });
            });
            break;
          case 'single':
            if (index !== undefined && n !== undefined) {
              this.currentPage = n;
              this.currentOffsetStart = this.currentOffsetInterval * index;
              this.currentOffsetEnd = this.currentOffsetInterval * index + this.currentOffsetInterval;
              this.$nextTick(() => {
                window.scrollTo(0, 0);
                this.$refs.searchInput.focus({ preventScroll: true });
              });
            }
            break;
          default:
            break;
        }
      }
    },
  },
  created() {},
  mounted() {
    // Check if 'searchQuery' exists in localStorage and has a value
    // If so, submit the query
    this.getQueryFromLocalStorage();
  },
});
</script>

<style lang="scss">
.c_search_widget {
  position: relative;
  background: $color_white;
  padding: px_to_rem(20) var(--wrapper-padding, 5vw);
  .c_global_header & {
    width: 100%;
    max-width: 100%;
    background: $color_black;
    box-sizing: border-box;
    z-index: 99;
  }
  @at-root #{&}--hidden {
    position: absolute;
    top: 100%;
    transition: visibility 0s 5s, opacity 0.5s ease;
    opacity: 0;
    visibility: hidden;
  }
  @at-root #{&}--visible {
    opacity: 1;
    transition: visibility 0s 0s, opacity 0.5s ease;
    visibility: visible;
  }
  @at-root #{&}__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0;
    @include mq($mq_sm_to_md) {
      justify-content: space-between;
    }
  }
  @at-root #{&}__hidden {
    @include hide_text;
    position: absolute;
    height: 0;
    width: 0;
  }
  @at-root #{&}__content {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 0 1 calc(100% - 72px - 1rem);
    @include mq($mq_sm_to_md) {
      flex: 0 1 calc(100% - 142px - 1rem);
    }
    input[type='search'] {
      height: px_to_rem(43);
      border-color: $color_gray_86;
      border-radius: 0;
      padding: 1rem 3rem 1rem 1rem;
      transition: all ease 300ms;
      &::placeholder {
        color: $color_black;
        opacity: 1;
      }
      .c_global_header & {
        color: $color_black;
        background: $color_white;
        border: none;
        border-bottom: solid thin $color_yellow;
        border-radius: 0;
        padding: 0 px_to_rem(12);
        width: 100%;
        &::placeholder {
          color: $color_black;
          opacity: 1;
        }
        &:focus {
          outline: solid $color_red;
        }
      }
    }
  }
  @at-root #{&}__label {
    color: $color_black;
    font-size: px_to_rem(22);
    width: 100%;
    margin-bottom: 1rem;
    .c_global_header & {
      color: $color_white;
    }
  }
  @at-root #{&}__icon {
    position: relative;
    @include mq($mq_grid_start) {
      height: 1rem !important;
      width: 1rem !important;
      margin-right: 1rem;
    }
    :hover > &,
    :focus > & {
      --stroke-color: var(--color-black) !important;
    }
    .c_global_header & {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
    }
    > svg {
      overflow: visible;
    }
  }
  @at-root #{&}__submit {
    align-self: flex-end;
    width: max-content;
    padding: px_to_rem(8) px_to_rem(20);
    @include mq($mq_grid_start) {
      padding: var(--button-pt, 0.85em) var(--button-px, 20px) var(--button-pb, 0.7em);
    }
    #{&}__label {
      text-indent: -9999vw;
      @include mq($mq_grid_start) {
        text-indent: initial;
      }
    }
  }
  @at-root #{&}__pagination {
    justify-content: center;
    @include mq($mq_grid_start) {
      justify-content: flex-end;
    }
    #{&}__indicator {
      font-size: px_to_rem(20);
      width: px_to_rem(45);
      height: px_to_rem(50);
      background: transparent;
      border: none;
      padding: 0;
      &--current {
        background: $color_red;
      }
    }
  }
}
</style>
