export const productTabTableGql = `... on product_tableTab_Entry {
  tabTitle
  tabHeader
  simpleTable {
    ... on simpleTable_row_BlockType {
      typeHandle
      header @markdown
      information
    }
    ... on simpleTable_disclosureRow_BlockType {
      typeHandle
      information
    }
  }
}`;
