import { landingPageContentGql } from '../../matrix_blocks/landingPageContentGql.js';
import { linksMatrixGql } from '../../link_field/linksMatrixGql.js';

const pageGql = `id
title
... on event_Category {
  breadcrumbLink {
    uri
  }
  featuredEvent(limit: 1) {
    title
    url
    ... on event_event_Entry {
      eventStartDateTime @formatDateTime(format:"F j")
      eventLocationCityState
      relatedEntryDescription
      eventRegistrationUrl
      eventRegistrationLabel
      featuredImage: eventFeaturedImage {
        ... on event_Asset {
          title
          filterPageFeaturedImage {
            srcsetWebp
            srcset
          }
        }
      }
    }
  }
  sidebarNavigationLinks: ${linksMatrixGql(2)}
  filters: eventFilters(orderBy: "lft asc") {
    title
    slug
    level
    parent {
      slug
    }
  }
  filterPageOverviewSubhead
  filterPageOverviewDescription
  links: ${linksMatrixGql()}
  searchFieldPlaceholder
  relatedProductCategories(orderBy: "lft asc") {
    id
    level
  }
  ${landingPageContentGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const pageQuery = `query($site: String, $uri: String) {
  category(site: [$site], uri: [$uri]) {
    ${pageGql}
  }
}`;

export const eventFilterGenerateQuery = function (sitesString = `$site`) {
  return `query {
    categories(
      site: [${sitesString}]
      group: ["event"]
    ) {
      groupHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};

export const resultsQuery = `query($id: QueryArgument, $orderBy:String, $site: String, $filterStartDateFrom: [QueryArgument], $filterEndDateFrom: [QueryArgument]) {
  entries(
    eventFilterPages: [$id]
    orderBy: $orderBy
    section: "event"
    site: [$site]
    type: "event"
    eventStartDateTime: $filterStartDateFrom
    eventEndDateTime: $filterEndDateFrom
  ) {
    title
    url
    ... on event_event_Entry {
      metaImage: eventFeaturedImage {
        title
        ... on event_Asset {
          square: filterSquare {
            srcset
            srcsetWebp
          }
          landscape: relatedEntries {
            srcset
            srcsetWebp
          }
        }
      }
      filterByDate: eventStartDateTime @formatDateTime (format: "D M d Y H:i:s O")
      sortByDate: eventStartDateTime @formatDateTime (format: "U")
      endDate: eventEndDateTime @formatDateTime (format: "D M d Y H:i:s O")
      startDate: eventStartDateTime @formatDateTime (format: "D M d Y H:i:s O")
      time: eventStartDateTime @formatDateTime (format: "F j, g:i A")
      eventTimezone: eventStartDateTime @formatDateTime (format: "e")
      location: eventLocationCityState
      price: eventPrice
      dateAndTimeDisplay
      timezone
      eventRegistrationUrl
      eventRegistrationLabel
      eventFilters(level: 2) {
        slug
        parent {
          slug
        }
      }
      eventFilterPages {
        title
      }
    }
  }
}`;
