import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const featuredCardCardsGql = `... on landingPageContent_featuredCardCards_BlockType {
    typeHandle
    header
    text
    alignment
    colors
    image{
        title
        url
        ... on landingPageImages_Asset{
            lpBackgroundSquare{
                srcset
                srcsetWebp
            }
        }
    }
    ${linksSuperTableGql('featureCardCardsButton')}
    secondaryCards{
        ... on secondaryCards_BlockType{
            header
            ${linksSuperTableGql()}
            image{
                title
                url
                ... on landingPageImages_Asset{
                    lpImageGridLandscape{
                        srcset
                        srcsetWebp
                    }
                }
            }
        }
    }
}`;
