import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const imageTextTabsGql = `... on landingPageContent_imageTextTabs_BlockType{
    typeHandle
    colors
    tabs: imageTextTabs{
        ... on imageTextTabs_BlockType{
            tabTitle
            text
            alignment
            image{
                ... on landingPageImages_Asset{
                    title
                    lpImageTextUncropped{
                        srcset
                        srcsetWebp
                    }
                }
            }
        }
    }
    buttons: ${linksSuperTableGql('imageTextTabs')}

}`;
