<template>
  <div :is="elementType" class="c_navigation_item" :class="index && index == 0 ? 'c_navigation_item--first' : ''">
    <Button
      class="c_navigation_item__link"
      :href="!hasAction ? JSON.parse(buttonLinkField).href : null"
      :aria-label="buttonLinkField ? JSON.parse(buttonLinkField).ariaLabel : null"
      :new-window="buttonLinkField ? JSON.parse(buttonLinkField).newWindow : null"
      @onClick="navClickThroughAction"
      unstyle
      reset
    >
      <slot>
        <strong
          class="c_navigation_item__link__text c_text--graphik_semibold"
          v-if="addStrongTag"
          v-html="labelText || JSON.parse(buttonLinkField).labelText"
        ></strong>
        <span
          class="c_navigation_item__link__text"
          v-else
          v-html="labelText || JSON.parse(buttonLinkField).labelText"
        ></span>
      </slot>
      <IconSVG class="c_navigation_item__link__arrow" v-if="showArrow" handle="arrow_right" stroke-width="5px" />
    </Button>
  </div>
</template>

<script>
import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
export default {
  components: {
    Button,
    IconSVG,
  },
  data() {
    return {};
  },
  props: {
    addStrongTag: {
      type: Boolean,
      default: false,
    },
    buttonLinkField: String,
    elementType: {
      type: String,
      default: 'div',
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
    index: null,
    labelText: null,
    showArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navClickThroughAction(event) {
      log('Nav Button Event', event);
      this.$emit('onNavClick', event);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_navigation_item {
  $self: &;
  --navigation-item--border-color: #{$color_white};
  --navigation-item--color: #{$color_black};
  --navigation-item--hover-color: #{$color_red};
  border-bottom: px_to_rem(1) solid var(--navigation-item--border-color);

  #{$self}__link {
    color: var(--navigation-item--color);
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--navigation-item--hover-color);
    }
    @at-root #{&}__arrow {
      height: px_to_rem(10);
      width: px_to_rem(10);
      svg {
        vertical-align: top;
      }
    }
  }
  @at-root #{$self}--mobile {
    @at-root #{&} #{$self}__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: px_to_rem(20) 0;
      @at-root #{&}__text {
        padding-right: px_to_rem(20);
      }
    }
  }
}
</style>
