<template>
  <div class="error_404">
    <Flex center>
      <div class="c_wrapper">
        <Grid element-type="div" :columns="$mq | mq({ sm: 1, md: 2, lg: 2 })" gap="14px">
          <Flex center>
            <div id="404_container">
              <h1 class="c_header--8 c_text--graphik_bold">404</h1>
              <h2 class="c_header--3 c_text--graphik_bold">Page not found</h2>
              <hr class="error_404-divider" />
              <p>
                Sorry, we can't find the page you are looking for. Return to
                <Button
                  class="c_asset_button c_button--reset c_asset_button--red"
                  href="/"
                  aria-label="homepage"
                  label-text="homepage"
                  unstyle
                />.
              </p>
            </div>
          </Flex>
          <div>
            <Flex center>
              <IconSVG handle="404" />
            </Flex>
          </div>
        </Grid>
      </div>
    </Flex>
  </div>
</template>

<script>
import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import Grid from 'Components/grid/Grid.vue';
import Flex from 'Components/flex/Flex.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  name: 'Error404',
  components: {
    Button,
    Grid,
    Flex,
    IconSVG,
  },
  data() {
    return {};
  },
  // Let Retour handle the 404 error
  //   async fetch({ redirect }) {
  //     const request = await this.$craft({
  //       query: `query retourGql($uri: String) {
  //   retour(uri: $uri) {
  //     redirectDestUrl
  //   }
  // }`,
  //       variables: {
  //         uri: `/${this.$route.params.path}`,
  //       },
  //     });
  //
  //     if (request.data.retour) {
  //       if (request.data.retour.redirectDestUrl) {
  //         log('Redirecting', request.data.retour.redirectDestUrl);
  //         redirect(request.data.retour.redirectDestUrl);
  //       }
  //     }
  //   },
  head() {
    return {
      title: 'Eastman Kodak Company | Error 404',
      meta: [
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },
  props: {
    error: Object,
  },
  mounted() {
    log('Error page loaded');
  },
};
</script>
