import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const contentIconCardsGql = `... on landingPageContent_contentIconCards_BlockType{
  typeHandle
  eyebrow
  header
  text
  ${linksSuperTableGql('contentIconCardsButton')}
  iconCards{
    ... on iconCards_BlockType{
      icon{
        ... on svgIcons_Asset{
          title
          url
        }
      }
      header
      iconText
      ${linksSuperTableGql()}
    }
  }
  iconCardBorder
  colors
  layout
}`;
