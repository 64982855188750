import settings from 'JS/automated/settings.js';
import format from 'date-fns/format';
import * as zhCN from 'date-fns/locale/zh_cn';
import ko from 'date-fns/locale/ko';
import ja from 'date-fns/locale/ja';

// LOGGING FUNCTIONS
export function dir(...args) {
  logger('dir', args);
}
export function error(...args) {
  logger('error', args);
}
export function log(...args) {
  logger('log', args);
}
export function warn(...args) {
  logger('warn', args);
}
function logger(type = 'log', args) {
  const spirit = '🎞';

  if (settings.devMode) {
    for (let i = 0; i < args.length; i++) {
      const spacer = i > 0 ? '◉ ' : '';
      switch (type) {
        case 'dir':
          if (typeof args[i] === 'string') {
            // eslint-disable-next-line no-console
            console.dir(spacer + spirit + ' ' + args[i]);
          } else {
            // eslint-disable-next-line no-console
            console.dir(args[i]);
          }
          break;
        case 'error':
          // eslint-disable-next-line no-console
          console.error(spacer + spirit, args[i]);
          break;
        case 'log':
          if (['object', 'array'].includes(typeof args[i])) {
            // console.table(args[i]);
            // eslint-disable-next-line no-console
            console.log(spacer + spirit, args[i]);
          } else {
            // eslint-disable-next-line no-console
            console.log(spacer + spirit, args[i]);
          }
          break;
        case 'warn':
          // eslint-disable-next-line no-console
          console.warn(spacer + spirit, args[i]);
          break;
      }
    }
  }
}

// UTILITY FUNCTIONS
export function addClass(el, className) {
  if (!hasClass(el, className)) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  }
}
export function classToggle(selector, getClass) {
  const query = document.querySelectorAll(selector);
  query.forEach((el) => {
    if (hasClass(el, getClass)) {
      removeClass(el, getClass);
    } else {
      addClass(el, getClass);
    }
  });
}
export function countriesByCurrency(countries, currency) {
  return countries.filter((item) => item.currency === currency);
}
export function gaTrack(category, action, label) {
  if (!settings.devMode) {
    if (typeof ga === 'function') {
      window.ga('send', 'event', category, action, label);
    } else {
      warn('Google Analytics is not set up.');
    }
  } else {
    log('GA Tracking Preview: ', category, action, label);
  }
}
export function hasClass(el, className) {
  if (el.classList) {
    return el.classList.contains(className);
  } else {
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
  }
}

export function parseLinkField(field, type = 'matrix') {
  const attrs = {
    valid: false,
  };

  if (typeof field === 'undefined' || field == null) {
    return attrs;
  }

  if (type === 'matrix') {
    if (field.element) {
      if (field.element.length) {
        attrs.labelText =
          field.element[field.element.length - 1].tabTitle || field.element[field.element.length - 1].title;
        attrs.href = field.element[field.element.length - 1].uri;
        attrs.valid = true;
      }
    } else if (field.urlText) {
      attrs.href = field.urlText;
      attrs.labelText = field.urlText;
      attrs.valid = true;
    }
    if (field.buttonLabel) {
      attrs.labelText = field.buttonLabel;
    }
    attrs.ariaLabel = field.ariaLabel || attrs.labelText || null;
    attrs.newWindow = field.openInANewWindow || null;
  } else if (type === 'superTable') {
    if (field.entry && field.entry.length) {
      attrs.labelText = field.entry[0].tabTitle || field.entry[0].title;
      attrs.href = field.entry[0].uri;
      attrs.valid = true;
    } else if (field.productCategory && field.productCategory.length) {
      attrs.labelText = field.productCategory[field.productCategory.length - 1].title;
      attrs.href = field.productCategory[field.productCategory.length - 1].uri;
      attrs.valid = true;
    } else if (field.asset && field.asset.length) {
      attrs.labelText = field.asset[0].title;
      attrs.href = field.asset[0].uri;
      attrs.valid = true;
    } else if (field.custom) {
      attrs.href = field.custom;
      attrs.labelText = field.custom;
      attrs.valid = true;
    }
    if (field.buttonLabel) {
      attrs.labelText = field.buttonLabel;
    }
    attrs.ariaLabel = field.ariaLabel || attrs.labelText || null;
    attrs.newWindow = field.openInANewWindow || null;
  }

  return attrs;
}
// function ready(fn) {
//   if (document.readyState !== 'loading') {
//     fn();
//   } else {
//     document.addEventListener('DOMContentLoaded', fn);
//   }
// }
export function removeClass(el, className) {
  if (hasClass(el, className)) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  }
}

export function selectedCategoriesFromCategoriesField(categories) {
  // Used when GraphQL has this structure:
  // relatedProductCategories(orderBy: "lft asc") {
  //   id
  //   level
  // }

  const selectedCategories = [];

  log('Getting selected categories', categories);
  for (let i = 0; i < categories.length; i++) {
    const current = categories[i];
    const next = categories[i + 1] || false;
    if (next) {
      if (current.level >= next.level) {
        selectedCategories.push(current.id);
      }
    } else {
      selectedCategories.push(current.id);
    }
  }
  log('Selected categories results', selectedCategories);

  return selectedCategories;
}
export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
export function snake(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '_') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

// Global Navigation Functions
export function concatChildren(currentArray, concatArray) {
  if (currentArray.length) {
    return currentArray.concat(concatArray);
  } else {
    return concatArray;
  }
}
export function returnNavigationChildren(functionParent, functionChildren) {
  let returnChildrenArray = [];

  if (functionChildren && functionChildren.length) {
    returnChildrenArray = concatChildren(returnChildrenArray, functionChildren);
  }
  if (functionParent.navigationColumnsWithImages) {
    returnChildrenArray = concatChildren(returnChildrenArray, functionParent.navigationColumnsWithImages);
  }
  if (functionParent.navigationIconLinks) {
    returnChildrenArray = concatChildren(returnChildrenArray, functionParent.navigationIconLinks);
  }
  if (functionParent.navigationColumns) {
    returnChildrenArray = concatChildren(returnChildrenArray, functionParent.navigationColumns);
  }
  if (
    functionParent.navigationLinks &&
    functionParent.navigationLinks.length &&
    functionParent.navigationLinks.length > 1
  ) {
    returnChildrenArray = concatChildren(returnChildrenArray, functionParent.navigationLinks);
  }
  if (functionParent.linksMatrix) {
    if (functionParent.linksMatrix.length > 1) {
      returnChildrenArray = concatChildren(returnChildrenArray, functionParent.linksMatrix);
    }
  }
  if (returnChildrenArray.length) {
    return returnChildrenArray;
  } else {
    return [];
  }
}

export function getFullUrl() {
  return process.client ? window.location.href : '';
}

export function translatePostDate(date, locale) {
  if (date && locale) {
    let formattedLocale = null;
    switch (locale) {
      case 'ja':
        formattedLocale = ja;
        break;
      case 'ko':
        formattedLocale = ko;
        break;
      case 'zh':
        formattedLocale = zhCN;
        break;
      default:
        break;
    }
    const month = format(date, 'MMMM', { locale: formattedLocale });
    const dayYear = format(date, 'DD, YYYY');
    return `${month} ${dayYear}`;
  }
  return null;
}

export function navigateToHash(options) {
  if (options?.hash && options?.delay && document.querySelector(options?.hash)) {
    setTimeout(() => {
      document.querySelector(options?.hash).scrollIntoView(true);
      document.querySelector(options?.hash).focus();
    }, options?.delay);
  }
}

export function generateUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export function openPdfsInNewTab(refs) {
  if (refs) {
    const links = [...refs.querySelectorAll('a')];
    links.forEach((link) => {
      const href = link.getAttribute('href');
      if (href && href.includes('.pdf')) {
        link.setAttribute('target', '_blank');
      }
    });
  }
}

// INIT FUNCTIONS
log('Global');
