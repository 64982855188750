export const linksGql = `... on landingPageContent2023_links_BlockType {
  typeHandle
  linksTitle
  linksContent
  extraTopPadding
  extraBottomPadding
  links2 {
    ... on links2_BlockType {
      customUrl
      linkLabel
      openInANewWindow
      asset {
        url
        kind
      }
      entry {
        uri
      }
    }
  }
}`;
