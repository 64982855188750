export const galleryHorizontalGql = `... on landingPageContent_galleryHorizontal_BlockType{
    typeHandle
    cards: galleryHorizontalCards{
        ... on galleryHorizontalCards_BlockType{
            header
            text
            descriptor
            image{
                title
                url
                ... on landingPageImages_Asset{
                    caption
                    lpGalleryPortrait{
                        srcset
                        srcsetWebp
                    }
                }
            }
        }
    }

}`;
