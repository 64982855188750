<template>
  <span
    :is="elementTypeComputed"
    :class="{
      c_button: styleAsButton,
      'c_button--pointer': styleAsButton && href,
      'c_button--outline': outline,
      'c_button--reset': reset,
      'c_button--small': small,
    }"
    :aria-label="ariaLabel || labelText || null"
    :href="formattedHref ? localePath(formattedHref) : null"
    :style="color ? { '--button-color': `var(--color-${color})` } : null"
    :target="newWindow ? '_blank' : target || null"
    :title="title || ariaLabel || labelText || null"
    :to="formattedTo || null"
    :rel="newWindow || target ? 'noopener' : null"
    @click.native="clickThroughAction"
    v-if="useRouterLink"
    ><slot>{{ labelText }}</slot>
    <IconSVG v-if="arrow" handle="chevron_right" />
  </span>
  <span
    :is="elementTypeComputed"
    :class="{
      c_button: styleAsButton,
      'c_button--pointer': styleAsButton && href,
      'c_button--outline': outline,
      'c_button--reset': reset,
      'c_button--small': small,
    }"
    :aria-label="ariaLabel || labelText || null"
    :href="formattedHref"
    :style="color ? { '--button-color': `var(--color-${color})` } : null"
    :target="newWindow ? '_blank' : target || null"
    :title="title || ariaLabel || labelText || null"
    :rel="newWindow || target ? 'noopener' : null"
    @click="clickThroughAction"
    v-else
    ><slot>{{ labelText }}</slot>
    <IconSVG v-if="arrow" handle="chevron_right" />
  </span>
</template>

<script>
import { log } from 'JS/global.js';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    IconSVG,
  },
  props: {
    ariaLabel: String,
    color: String,
    elementType: { type: String, default: 'button' },
    href: String,
    labelText: String,
    newWindow: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    reset: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    to: { type: [String, Object] },
    target: String,
    title: String,
    unstyle: { type: Boolean, default: false },
    arrow: { type: Boolean, default: false },
  },
  computed: {
    elementTypeComputed() {
      if (this.useRouterLink) {
        return 'nuxt-link';
      }
      return this.href ? 'a' : this.elementType;
    },
    formattedHref() {
      if (this.href) {
        // Fixed /🅰/🅱/:c:/:d:/:e:/:f:/:g:/:h:/:i:/:j:/:k:/:l:/Ⓜ/:n:/⭕/🅿/:q:/:r:/:s:/:t:/:u:/✌/:w:/❌/:y:/:z:/
        const href = this.href
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('🅰', 'g'), ':a:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('🅱', 'g'), ':b:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('Ⓜ', 'g'), ':m:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('⭕', 'g'), ':o:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('🅿', 'g'), ':p:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('✌', 'g'), ':v:')
          // eslint-disable-next-line prefer-regex-literals
          .replace(new RegExp('❌', 'g'), ':x:');

        if (href.startsWith('http://') || href.startsWith('https://') || href.startsWith('tel:')) {
          return href;
        } else {
          // return `/${this.$store.state.localeRegion}${href.startsWith('/') ? '' : '/'}${href}`;
          return `${href.startsWith('/') ? '' : '/'}${href}`;
        }
      }

      return null;
    },
    formattedTo() {
      if (this.to) {
        return this.to;
      } else if (this.formattedHref) {
        return this.localePath(this.formattedHref);
      }

      return null;
    },
    styleAsButton() {
      return this.unstyle === false && Object.keys(this.$slots).length === 0;
    },
    useRouterLink() {
      if (this.formattedHref) {
        return this.formattedHref.startsWith('/');
      }
      return false;
    },
  },
  methods: {
    clickThroughAction(event) {
      log('Button Event', event);
      this.$emit('onClick', event);
    },
  },
};
</script>
