export const galleryGridGql = `... on landingPageContent_galleryGrid_BlockType{
  typeHandle
  layout
  colors
  images{
    title
    url
    ... on landingPageImages_Asset{
      caption
      lpImageGridSquare{
        srcset
        srcsetWebp
      }
      lpImageGridLandscape{
        srcset
        srcsetWebp
      }
      lpImageTextUncropped{
        srcset
        srcsetWebp
      }
    }
  }
}`;
