import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const textAccordionGql = `... on landingPageContent_textAccordion_BlockType {
  typeHandle
  eyebrow
  subheader
  text
  textAlignment
  ${linksSuperTableGql('textAccordionButton')}
  accordion {
    ... on accordion_BlockType {
      header
      text
    }
  }
}`;
