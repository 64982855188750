<script>
// todo Replace Vue Agile with a newer carousel!
import { VueAgile } from 'vue-agile';
export default {
  extends: VueAgile,
  data() {
    return {
      currentSlidesodis: 0,
    };
  },
  computed: {
    canGoToPrev() {
      return this.settings.infinite || this.settings.loop || this.currentSlide > 0;
    },

    canGoToNext() {
      return this.settings.infinite || this.settings.loop || this.currentSlide < this.countSlides - 1;
    },
  },
  methods: {
    goTo(n, transition = true, asNav = false, loopToBeginnigOrEnd = false) {
      /*
       * CLONE of node_modules/vue-agile/Agile.vue goTo() method
       *
       * only change is in the last if statement to properly stop animation.
       *
       * */

      // Break goTo() if unagile is active
      if (this.settings.unagile) return false;

      if (!asNav && this.settings?.asNavFor?.length) {
        this.settings.asNavFor.forEach((carousel) => {
          if (carousel) {
            carousel.goTo(n, transition, true);
            carousel.$emit('updateSlideIndex', n);
          }
        });
      }

      let slideNextReal = n;
      if (n < 0) {
        slideNextReal = this.countSlides - 1;
      } else if (n >= this.countSlides) {
        slideNextReal = 0;
      }
      if (transition) {
        this.$emit('before-change', { currentSlide: this.currentSlide, nextSlide: slideNextReal });
        this.currentSlide = slideNextReal;

        if (n !== slideNextReal) {
          setTimeout(() => {
            this.goTo(slideNextReal, true, false, true);
            this.$emit('updateSlideIndex', n);
          }, this.settings.speed);
        }
      }

      let translateX = !this.settings.fade ? n * this.widthSlide * this.settings.slidesToScroll : 0;
      if (!this.settings.infinite && n === this.countSlides - 1 && loopToBeginnigOrEnd) {
        translateX = !this.settings.fade
          ? (n - this.settings.slidesToShow + 1) * this.widthSlide * this.settings.slidesToScroll
          : 0;
      } else if (!this.settings.infinite && n === 0 && loopToBeginnigOrEnd) {
        translateX = !this.settings.fade ? 0 : 0;
      }
      this.transitionDelay = transition ? this.speed : 0;

      /* MODIFICATION:
       *
       *  Modified this.infinite to this.settings.infinite to match passed in prop value
       *
       *  Modified this.slidesToShow to this.settings.slidesToShow to match passed in prop or option prop value
       *
       * */

      if (
        (this.settings.infinite ||
          this.currentSlide + this.settings.slidesToShow <= this.countSlides ||
          loopToBeginnigOrEnd) &&
        n === slideNextReal
      ) {
        this.translateX = this.settings.rtl ? translateX : -1 * translateX;
      }

      this.$emit('updateSlideIndex', slideNextReal);
    },
  },
};
</script>

<style lang="scss">
.c_vue_agile_override {
  $self: &;
}
</style>
