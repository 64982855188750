<template>
  <ul :is="elementType" class="c_navigation_single_column" v-if="filteredMenuItems.length">
    <li
      v-for="(item, index) in filteredMenuItems"
      :key="index"
      class="c_navigation_single_column__list"
      :class="[index === menuItems.length - 1 ? 'c_navigation_single_column__list--last' : '']"
    >
      <LinkField class="c_navigation__link" :matrix-field="item" :button-attrs="{ unstyle: true, reset: true }" />
    </li>
  </ul>
</template>

<script>
import LinkField from 'Components/link_field/LinkField.vue';
export default {
  name: 'NavigationSingleColumn',
  components: {
    LinkField,
  },
  data() {
    return {};
  },
  props: {
    elementType: {
      type: String,
      default: 'div',
    },
    menuItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    filteredMenuItems() {
      const unfilteredMenuItems = this.menuItems || [];
      return unfilteredMenuItems.filter((item) => item?.element?.length || item?.urlText);
    },
  },
};
</script>

<style lang="scss">
.c_navigation_single_column {
  $self: &;
  padding: px_to_rem(20);
  @at-root #{$self}__list {
    margin-bottom: px_to_rem(10);
    @at-root #{&}--last {
      margin-bottom: 0;
    }
    @at-root #{&}__link {
      font-size: var(--navigation--font-size);
    }
  }
}
</style>
