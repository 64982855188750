<template>
  <header
    class="c_global_header"
    :class="{
      'c_box--pos--relative': ['lg'].includes($mq),
      'c_global_header--section': navigationCurrentSectionLink && ['lg'].includes($mq),
    }"
  >
    <div class="skip_to_navigation visually_hidden visually_hidden--show_on_focus">
      <ul class="skip_to_navigation__nav">
        <li>
          <Button class="skip_to_navigation__nav__link" @onClick="skipToMain" unstyle reset>{{
            $t('Skip to main content')
          }}</Button>
        </li>
      </ul>
    </div>
    <div class="c_global_header__wrapper c_wrapper" :class="{ 'c_global_header__wrapper--visible': appHydrated }">
      <nav
        aria-label="Main menu"
        class="c_global_header__navigation_and_links"
        v-if="
          navigationCurrentSectionLink &&
          navigationCurrentSectionLink.linksMatrix.length &&
          parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText &&
          ['lg'].includes($mq)
        "
      >
        <ul>
          <li class="c_navigation__top_lvl_item">
            <span class="c_global_header__logo">
              <Button class="c_global_header__logo__icon" href="/" unstyle reset>
                <IconSVG
                  handle="logo_kodak"
                  :alt="$t('Navigate to Home')"
                  :replacements="{
                    'Kodak Logo': $t('Kodak Logo'),
                  }"
                ></IconSVG>
              </Button>
            </span>
          </li>
          <li class="c_navigation__top_lvl_item">
            <LinkField
              :matrix-field="navigationCurrentSectionLink.linksMatrix[0]"
              :button-attrs="{ unstyle: true, reset: true }"
              class="c_navigation__single_item c_navigation__top_lvl_item__link"
              :class="{
                'c_header--3': useLargeContentSectionHeader(
                  parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText
                ),
              }"
            />
          </li>
        </ul>
      </nav>
      <div
        class="c_global_header__navigation_and_links"
        :class="{
          'c_global_header__navigation_and_links--left':
            (navigationCurrentSectionLink &&
              navigationCurrentSectionLink.contentSection &&
              navigationCurrentSectionLink.contentSection[0].slug === getContentSection) ||
            ['sm', 'md'].includes($mq),
          'c_global_header__navigation_and_links--secondary': navigationCurrentSectionLink && ['lg'].includes($mq),
          'c_global_header__navigation_and_links--hamburger': ['sm', 'md'].includes($mq),
        }"
      >
        <Navigation
          aria-label="Section menu"
          class="c_global_header__navigation"
          v-if="
            navigationCurrentSectionLink &&
            navigationCurrentSectionLink.contentSection &&
            navigationCurrentSectionLink.contentSection[0].slug === getContentSection &&
            ['lg'].includes($mq)
          "
          :nav-entries="navigationEntries"
        />
        <nav v-else-if="navigationEntries && ['lg'].includes($mq)" aria-label="Main menu">
          <ul class="c_global_header__navigation">
            <li>
              <span class="c_global_header__logo">
                <Button class="c_global_header__logo__icon" href="/" unstyle reset>
                  <IconSVG
                    handle="logo_kodak"
                    :alt="$t('Navigate to Home')"
                    :replacements="{
                      'Kodak Logo': $t('Kodak Logo'),
                    }"
                  ></IconSVG>
                </Button>
              </span>
            </li>
            <li
              class="c_navigation__top_lvl_item"
              v-if="
                navigationCurrentSectionLink &&
                navigationCurrentSectionLink.linksMatrix.length &&
                parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText
              "
            >
              <LinkField
                :matrix-field="navigationCurrentSectionLink.linksMatrix[0]"
                :button-attrs="{ unstyle: true, reset: true }"
                class="c_navigation__single_item c_navigation__top_lvl_item__link"
                :class="{
                  'c_header--3': useLargeContentSectionHeader(
                    parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText
                  ),
                }"
              />
            </li>
            <li class="c_navigation__top_lvl_item" v-for="(item, childIndex) in navigationEntries" :key="childIndex">
              <LinkField
                v-if="item.typeHandle === 'singleItem' && item.linksMatrix && item.linksMatrix[0]"
                class="c_global_header__navigation__single_item c_navigation__top_lvl_item__link"
                :class="
                  activeNavIndex === childIndex && allowDrawersToOpen ? 'c_navigation__top_lvl_item__link--active' : ''
                "
                :aria-expanded="
                  activeNavIndex === childIndex && allowDrawersToOpen && item.children && item.children.length
                    ? 'true'
                    : item.children && item.children.length
                    ? 'false'
                    : null
                "
                :matrix-field="item.linksMatrix[0]"
                :button-attrs="
                  item.navigationClickThroughUrl
                    ? { unstyle: true, reset: true, href: item.linksMatrix[0].element[0].uri }
                    : item.children && item.children.length
                    ? { unstyle: true, reset: true, href: null }
                    : { unstyle: true, reset: true }
                "
                @onClick="!item.navigationClickThroughUrl ? toggleDropdown(childIndex) : null"
              />
              <div
                v-if="activeNavIndex === childIndex && allowDrawersToOpen"
                class="c_global_header__navigation__dropdown c_box--shadow--center"
              >
                <Navigation :nav-entries="item.children" :is-sub-nav="true" :section="item.linksMatrix" />
              </div>
            </li>
          </ul>
        </nav>
        <NavigationMobile
          v-else-if="navigationEntries && ['sm', 'md'].includes($mq)"
          aria-label="Main menu mobile"
          :content-section="getContentSection"
          :logo-link-object="navigationCurrentSectionLink || null"
          :nav-entries="navigationEntries"
        >
          <ul class="c_global_header__navigation_and_links" slot="logo">
            <li>
              <span class="c_global_header__logo">
                <Button class="c_global_header__logo__icon" href="/" unstyle reset>
                  <IconSVG
                    handle="logo_kodak"
                    :alt="$t('Navigate to Home')"
                    :replacements="{
                      'Kodak Logo': $t('Kodak Logo'),
                    }"
                  ></IconSVG>
                </Button>
              </span>
            </li>
            <li
              class="c_navigation__top_lvl_item"
              v-if="
                navigationCurrentSectionLink &&
                navigationCurrentSectionLink.linksMatrix.length &&
                parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText
              "
            >
              <LinkField
                :matrix-field="navigationCurrentSectionLink.linksMatrix[0]"
                :button-attrs="{ unstyle: true, reset: true }"
                class="c_navigation__single_item c_navigation__top_lvl_item__link"
                :class="{
                  'c_header--3': useLargeContentSectionHeader(
                    parseNavLinkField(navigationCurrentSectionLink.linksMatrix[0]).labelText
                  ),
                }"
              />
            </li>
          </ul>
        </NavigationMobile>
      </div>
      <Navigation
        v-show="['lg'].includes($mq)"
        :aria-label="$t('Utility menu')"
        class="c_global_header__navigation c_global_header__navigation__utility"
        :nav-entries="utilityNavigationCurrentSectionLinks"
        :search-toggle-visible="true"
      />
    </div>
    <SearchWidget
      id="global-search-box"
      :navigation="true"
      :search-action="getSearchPath ? getSearchPath : null"
      search-input-id="global-search-query"
      :search-hidden="true"
      :search-results-visible="false"
      v-if="showSearchToggle"
    />
  </header>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log, parseLinkField, slugify } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import Navigation from 'Components/navigation/Navigation.vue';
import NavigationMobile from 'Components/navigation/NavigationMobile.vue';
import SearchWidget from 'Components/search/SearchWidget.vue';
import { mapState } from 'vuex';
import { locale } from '@/nuxtGenerateRoutes.js';

export default defineComponent({
  name: 'GlobalHeader',
  components: {
    Button,
    IconSVG,
    LinkField,
    Navigation,
    NavigationMobile,
    SearchWidget,
  },
  data() {
    return {
      searchActive: false,
    };
  },
  props: {},
  fetchKey() {
    return `${this.$i18n?.locale || 'default'}-${this.$route.params?.contentSection}`;
  },
  setup() {
    const state = reactive({
      activeNavIndex: null,
    });

    return { ...toRefs(state) };
  },
  computed: {
    getSearchPath() {
      if (this.$config.craftGlobals?.search) {
        const localeId = Object.keys(locale).find((key) => locale[key] === this.$i18n.locale);
        const itemsForSite = this.$config.craftGlobals.search.filter((item) => {
          return item.siteId.toString() === localeId.toString();
        });
        return `/${this.$i18n?.locale}/${itemsForSite[0]?.uri}/`;
      }
      return null;
    },
    showSearchToggle() {
      if (this.getSearchPath && this.getSearchPath === this.$route.path) {
        return false;
      }
      return true;
    },
    getContentSection() {
      let returnSlug = null;
      if (this.$route.params?.contentSection) {
        if (this.$route.params.contentSection !== 'global') {
          returnSlug = this.$route.params.contentSection;
        }
      }
      return returnSlug;
    },
    headerDataGlobalUi() {
      if (this.$config.craftGlobals?.globalUi?.globalNavigation) {
        const localeId = Object.keys(locale).find((key) => locale[key] === this.$i18n.locale);
        const itemsForSite = this.$config.craftGlobals.globalUi.globalNavigation.filter((item) => {
          return item.siteId.toString() === localeId.toString();
        });
        return itemsForSite;
      }

      return [];
    },
    headerDataHeaders() {
      if (this.$config.craftGlobals?.headers) {
        const localeId = Object.keys(locale).find((key) => locale[key] === this.$i18n.locale);
        return this.$config.craftGlobals.headers.filter((item) => item.siteId.toString() === localeId.toString());
      }

      return [];
    },
    navigationEntries() {
      if (this.$route.params?.contentSection && this.navigationCurrentSectionLink?.children) {
        if (this.$route.params.contentSection !== 'global') {
          return this.navigationCurrentSectionLink.children.filter(this.filterNavEntries);
        }
      }
      if (this.headerDataGlobalUi?.globalNavigation) {
        return this.headerDataGlobalUi.globalNavigation.filter(this.filterNavEntries);
      }
      if (this.headerDataGlobalUi?.length) {
        return this.headerDataGlobalUi.filter(this.filterNavEntries);
      }
      return [];
    },
    navigationCurrentSectionLink() {
      if (this.headerDataHeaders?.length && this.$route.params?.contentSection) {
        for (let e = 0; e < this.headerDataHeaders.length; e++) {
          const el = this.headerDataHeaders[e];
          if (
            el.contentSection?.[0]?.title &&
            this.$route.params?.contentSection &&
            this.$route.params?.contentSection !== 'global'
              ? el.contentSection[0].slug === this.$route.params.contentSection
              : false
          ) {
            return el;
          }
        }
      }
      return null;
    },
    utilityNavigationCurrentSectionLinks() {
      if (this.headerDataHeaders?.length) {
        const globalLinks = this.headerDataHeaders.filter(
          (item) => item?.typeHandle === 'utilityItem' && !item?.contentSection[0]
        );
        const sectionLinks = this.headerDataHeaders.filter(this.filterUtilityEntries);
        if (sectionLinks.length) {
          return sectionLinks;
        } else {
          return globalLinks;
        }
      }
      return [];
    },
    ...mapState({
      allowDrawersToOpen: (state) => state.global.allowDrawersToOpen,
      appHydrated: (state) => state.global.appHydrated,
      globalDarkTheme: (state) => state.global.globalDarkTheme,
      getSearchState: (state) => state.global.searchOpen,
    }),
  },
  methods: {
    slugify,
    useLargeContentSectionHeader(linkText) {
      // Logic which will determine whether or not to use the larger size header text. True indicates this should be used, false indicates it should not be used.
      let useLargeContentSectionClass = true;
      if (linkText.length > 24) {
        useLargeContentSectionClass = false;
      }
      return useLargeContentSectionClass;
    },
    parseNavLinkField(field, fieldType) {
      return parseLinkField(field, fieldType);
    },
    filterNavEntries(item) {
      return (
        item?.navigationColumns?.length ||
        item?.navigationColumnsWithImages?.length ||
        item?.navigationIconLinks?.length ||
        item?.navigationColumnsWithCopy ||
        item?.url ||
        item?.urlText ||
        (item?.linksMatrix && item?.linksMatrix[0]?.urlText) ||
        (item?.linksMatrix && item?.linksMatrix[0]?.element?.length)
      );
    },
    filterUtilityEntries(item) {
      if (this.$route.params?.contentSection !== undefined) {
        return (
          item?.typeHandle === 'utilityItem' &&
          item?.contentSection?.length &&
          item?.contentSection[0]?.slug === this.$route?.params?.contentSection
        );
      }
      return null;
    },
    skipToMain() {
      window.scrollTo(0, document.querySelector('.app__view').offsetTop);
      document.querySelector('.app__view').focus();
    },
    closeSearch() {
      this.$store.commit('global/updatedSearchOpen', false);
      this.$store.commit('global/changeAllowDrawersToOpen', true);
    },
    toggleDropdown(toggleIndex, event) {
      log('toggle dropdown fired');
      this.closeSearch();
      if (this.activeNavIndex !== toggleIndex) {
        this.activeNavIndex = toggleIndex;
      } else {
        this.activeNavIndex = null;
      }
    },
  },
  watch: {
    $route() {
      this.activeNavIndex = null;
    },
  },
  mounted() {
    this.$on('globlal-search-toggled', () => {
      this.activeNavIndex = null;
    });
  },
});
</script>

<style lang="scss">
.c_global_header {
  $self: &;

  position: relative;
  background-color: $color_yellow;
  z-index: 999;

  .app--mobile-navigation-open & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  }
  @at-root #{$self}--section {
    background: linear-gradient(180deg, $color_yellow 50%, $color_white 50%);
    border-bottom: solid 0.5px $color_gray_191;
    .app--navigation-dark & {
      background: linear-gradient(180deg, $color_yellow 50%, $color_black 50%);
    }
  }

  .skip_to_navigation {
    position: absolute;
    top: $ui_masthead_height;
    left: 0;
    right: 0;
    z-index: 50;
    @include mq($mq_md_to_lg) {
      top: 100%;
    }
    @at-root #{&}.visually_hidden--show_on_focus {
      position: absolute;
    }
    @at-root #{&}__nav {
      display: inline-block;
      background: $color_red_179;
      padding: px_to_rem(5) px_to_rem(15);
      border-radius: 0 0 $border_radius $border_radius;
      color: $color_white;
      @at-root #{&}__link {
        text-decoration: underline;
      }
    }
  }

  @at-root #{$self}__wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    height: 100%;
    min-height: px_to_rem(61);
    opacity: 0;
    @at-root #{&}--visible {
      opacity: 1;
    }
  }
  @at-root #{$self}__logo {
    $logo: &;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-self: center;
    width: px_to_rem(46);
    height: px_to_rem(40);
    max-width: 100%;
    max-height: 100%;
    color: $color_red;
    text-align: center;
    text-decoration: none;
    @include mq($mq_md_to_lg) {
      width: px_to_rem(40);
      height: px_to_rem(40);
    }
    @at-root #{&}__mobile {
      @at-root #{$logo}__header {
        &:not(:last-child) {
          display: none;
        }
      }
    }
    @at-root #{&}__wrapper {
      display: flex;
      align-items: center;
      grid-column: 1 / span 2;
      grid-row: 1;
      height: px_to_rem(40);
      padding-left: var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
      @include mq($mq_sm_to_md) {
        grid-column: 1 / span 2;
        height: $ui_masthead_height;
      }
    }
    @at-root #{&}__icon {
      height: 100%;
      display: inline-block;
      .c_icon_svg {
        width: auto;
        height: 100%;
      }
      svg {
        width: auto;
        height: 100%;
        vertical-align: top;
      }
      // &:hover {
      //   opacity: 0.9;
      // }
    }
    @at-root #{&}__header {
      color: $color_black;
      text-decoration: none;
      font-size: px_to_rem(16);
      line-height: px_to_rem(21);
      transition: 0.15s ease-in-out;
      &:hover {
        color: $color_red;
      }
    }
  }
  @at-root #{$self}__loader_container {
    display: none;
    height: 100%;
    width: 50px;
    position: relative;
  }
  @at-root #{$self}__loader_container.active {
    display: inline-block;
  }
  @at-root #{$self}__navigation_and_links {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0;
    @at-root #{$self}__navigation_and_links & {
      overflow: auto;
    }
    > ul {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: px_to_rem(28);
    }
    @at-root #{&}--left {
      justify-content: space-between;
    }
    @at-root #{&}--hamburger {
      grid-column: span 12;
      grid-row: 1;
      padding: 0;
      justify-content: space-between;
    }
    @include mq($mq_md_to_lg) {
      position: initial;
      grid-column: 1 / span 9;
      grid-row: 1;
      height: px_to_rem(61);
      padding: 0;
      overflow: auto;
    }
    @at-root #{&}--secondary {
      grid-column: 1 / span 13;
      grid-row: 2;
      justify-content: flex-start;
      background: transparent;
      height: px_to_rem(65);
      padding: 0;
      overflow: visible;
    }
  }
  @at-root #{$self}__navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: px_to_rem(28);
    height: auto;
    @at-root #{&}__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      background: $color_gray_235;
      z-index: 500;
    }
    @at-root #{&}__utility {
      position: relative;
      grid-column: 10 / span 3;
      grid-row: 1;
      justify-self: flex-end;
      max-width: 100%;
      height: 50%;
      padding: 0;
      overflow: auto;
    }
  }
  @at-root #{$self}__quick_links {
    display: none; //Hide for now until functionality is built (by Kodak)
    //display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: px_to_rem(30);
    padding-left: px_to_rem(10);
    @at-root button#{&}__link {
      height: 100%;
      margin-left: px_to_rem(10);
      color: $color_black;
      &:first-of-type {
        margin-left: 0;
      }
      &:hover,
      &:focus {
        color: $color_red;
      }
      @at-root #{&}--button {
        height: auto;
        border-radius: $border_radius;
        font-size: px_to_rem(13);
        padding: px_to_rem(3) px_to_rem(7);
        border: px_to_rem(2) solid currentColor;
      }
    }

    @at-root #{&}__icon {
      height: 100%;
      .c_icon_svg {
        height: 100%;
        svg {
          vertical-align: top;
        }
      }
    }
  }
  @at-root #{$self}__search_box {
    position: absolute;
    top: 100%;
    width: 100%;
    background: $color_white;
    padding: px_to_rem(20) px_to_rem(40);
    transition: opacity 0.4s ease-in;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    @at-root #{&}--visible {
      opacity: 1;
      visibility: visible;
      z-index: 99;
    }
    @at-root #{&}_form {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      @include mq($mq_sm_to_md) {
        justify-content: space-between;
      }
    }
    @at-root #{&}_content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex: 0 1 100%;
      margin-bottom: 1rem;
      @include mq($mq_sm_to_md) {
        flex: 0 1 calc(100% - 126px);
        margin-bottom: 0;
      }
      input[type='search'] {
        height: px_to_rem(53);
        border-color: $color_gray_86;
        border-radius: 0;
        &::placeholder {
          color: $color_black;
          opacity: 1;
        }
      }
    }
    @at-root #{&}_label {
      position: absolute;
      clip: rect(1px, 1px, 1px, 1px);
      overflow: hidden;
      height: 1px;
      width: 1px;
      word-wrap: normal;
    }
    @at-root #{&}_icon {
      position: absolute;
      top: 50%;
      right: 0.95em;
      transform: translateY(-50%);
    }
  }
}
</style>
