<template>
  <component
    :is="element"
    v-if="content && content.asset && content.style === '2'"
    class="c_navigation_featured_content__story"
  >
    <a
      v-if="formattedLink.uri && formattedLink.title"
      :aria-label="formattedLink.title"
      :title="formattedLink.title"
      :target="formattedLink.window ? '_blank' : null"
      :href="formattedLink.uri || null"
      class="c_navigation_featured_content__story__anchor"
    ></a>
    <div class="c_navigation_featured_content__story__image">
      <MediaImage
        v-if="content.asset[0]"
        background
        :sources="formatSources(content.asset[0])"
        :alt="content.asset[0].title"
      />
    </div>
    <div class="c_navigation_featured_content__story__content">
      <div v-show="content.eyebrow" class="c_navigation_featured_content__story__eyebrow">
        {{ content.eyebrow }}
      </div>
      <div class="c_navigation_featured_content__story__description">
        <p>
          <span v-show="content.subhead" class="c_navigation_featured_content__story__subhead">{{
            content.subhead
          }}</span>
          <br />
          <span v-show="content.header" class="c_navigation_featured_content__story__header">{{ content.header }}</span>
        </p>
      </div>
    </div>
  </component>
  <component :is="element" v-else-if="content && content.asset" class="c_navigation_featured_content__product">
    <a
      v-if="formattedLink.uri && formattedLink.title"
      :aria-label="formattedLink.title"
      :title="formattedLink.title"
      :target="formattedLink.window ? '_blank' : null"
      :href="formattedLink.uri || null"
      class="c_navigation_featured_content__product__anchor"
    ></a>
    <div>
      <div v-show="content.eyebrow" class="c_navigation_featured_content__product__eyebrow c_text--graphik_medium">
        {{ content.eyebrow }}
      </div>
      <div class="c_navigation_featured_content__product__image">
        <MediaImage v-if="content.asset[0]" :sources="formatSources(content.asset[0])" :alt="content.asset[0].title" />
      </div>
      <div class="c_navigation_featured_content__product__header">
        <p>
          <span v-show="content.header" class="c_text--graphik_medium">{{ content.header }}</span>
          <br />
          <span v-show="content.subhead">{{ content.subhead }}</span>
        </p>
      </div>
      <p v-show="content.description" class="c_navigation_featured_content__product__description">
        {{ content.description }}
      </p>
    </div>
  </component>
</template>

<script>
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  name: 'NavigationFeaturedContent',
  components: {
    MediaImage,
  },
  props: {
    element: {
      type: String,
      default: 'div',
    },
    content: {
      type: Object,
      default: () => {},
    },
    imageOptimizedFields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formattedLink() {
      const featured = this.content?.featuredItemLink[0] || {};
      if (featured) {
        if (featured?.entry?.length) {
          return {
            title: featured?.entry[0]?.title || null,
            uri: featured?.entry[0]?.uri ? this.localePath(`/${featured?.entry[0]?.uri}`) : null,
            window: featured?.openInANewWindow || false,
            local: true,
          };
        }
        if (featured?.asset?.length) {
          return {
            title: featured?.asset[0]?.title || null,
            uri: featured?.asset[0]?.uri || null,
            window: featured?.openInANewWindow || false,
            local: false,
          };
        }
        if (featured?.customUrl && this.content?.header) {
          return {
            title: this.content?.header || null,
            uri: featured?.customUrl || null,
            window: featured?.openInANewWindow || false,
            local: false,
          };
        }
      }
      return {};
    },
  },
  methods: {
    formatSources(image) {
      const returnArray = [];
      for (let i = 0; i < this.imageOptimizedFields.length; i++) {
        if (image[this.imageOptimizedFields[i]]?.srcsetWebp) {
          returnArray.push({ srcset: image[this.imageOptimizedFields[i]].srcsetWebp, type: 'webp' });
        }
        if (image[this.imageOptimizedFields[i]]?.srcset) {
          returnArray.push({ srcset: image[this.imageOptimizedFields[i]].srcset });
        }
      }
      return returnArray;
    },
  },
};
</script>

<style lang="scss">
.c_navigation_featured_content {
  $self: &;

  @at-root #{$self}__product {
    $product: &;

    position: relative;
    grid-column: var(--navigation-section-feature-span);
    align-self: flex-start;
    justify-self: flex-end;
    background: $color_gray_196_light;
    border-radius: px_to_rem(12);
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: px_to_rem(20);
    &:focus-within,
    &:focus,
    &:hover {
      outline: px_to_rem(2) solid $color_black;
    }
    @at-root #{$product}__anchor {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
    @at-root #{$product}__eyebrow {
      font-size: px_to_rem(14);
      margin-bottom: px_to_rem(18);
    }
    @at-root #{$product}__image {
      $column_padding: px_to_rem(20);
      height: 100%;
      min-height: px_to_rem(150);
      overflow: hidden;
      img {
        max-height: px_to_rem(260px);
        object-fit: cover;
      }
    }
    @at-root #{$product}__header {
      font-size: px_to_rem(16);
      line-height: px_to_rem(26);
      margin-top: px_to_rem(14);
    }
    @at-root #{$product}__subhead {
      font-size: px_to_rem(16);
    }
    @at-root #{$product}__description {
      font-size: px_to_rem(14);
      line-height: px_to_rem(20);
      margin-top: px_to_rem(7);
    }
  }
  @at-root #{&}__story {
    $story: &;

    position: relative;
    display: flex;
    grid-column: var(--navigation-section-feature-span);
    align-self: flex-start;
    justify-self: flex-start;
    background: $color_gray_196_light;
    border-radius: px_to_rem(12);
    box-sizing: border-box;
    color: $color_white;
    width: 100%;
    max-width: 100%;
    min-height: px_to_rem(300);
    padding: px_to_rem(30) px_to_rem(38);
    &:focus-within,
    &:focus,
    &:hover {
      outline: px_to_rem(2) solid $color_black;
    }
    @at-root #{$story}__anchor {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 3;
    }
    @at-root #{$story}__content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      z-index: 2;
    }
    @at-root #{$story}__eyebrow {
      align-self: flex-start;
      font-size: 1rem;
      line-height: 1;
      width: 100%;
      margin-bottom: px_to_rem(14);
    }
    @at-root #{$story}__image {
      position: absolute;
      top: 0;
      right: 0;
      $column_padding: px_to_rem(20);
      border-radius: px_to_rem(12);
      height: 100%;
      min-height: px_to_rem(150);
      width: 100%;
      filter: brightness(50%);
      overflow: hidden;
      z-index: 1;
      img {
        object-fit: cover;
      }
    }
    @at-root #{$story}__description {
      align-self: flex-end;
      width: 100%;
    }
    @at-root #{$story}__header {
      font-size: px_to_rem(28);
      line-height: px_to_rem(32);
      letter-spacing: 0.16px;
    }
    @at-root #{$story}__subhead {
      display: inline-block;
      font-size: 1rem;
      line-height: 1;
      margin-bottom: px_to_rem(14);
    }
  }
}
</style>
