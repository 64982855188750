import { landingPageContentGql } from '../../matrix_blocks/landingPageContentGql.js';
import { linksMatrixGql } from '../../link_field/linksMatrixGql.js';

const pageGql = `id
title
... on pressRelease_Category {
  featuredPost{
    ... on pressRelease_pressRelease_Entry{
      title,
      articleSummary,
      url,
      featuredImage:blogFeaturedImage{
        title
        ... on blogNews_Asset{
          filterPageFeaturedImage {
            srcsetWebp
            srcset
          }
        }
        ... on images_Asset {
          square: aspectRatio11 {
            srcset
            srcsetWebp
          }
        }
      }
    }
  }
  blogCategorySubhead
  blogCategoryDescription
  breadcrumbLink{
    uri
  }
  sidebarNavigationLinks: ${linksMatrixGql(2)}
  filters:pressReleaseFilters(orderBy:"lft asc"){
    title
    slug
    level
    parent{
      slug
    }
  }
  filterPageOverviewSubhead
  filterPageOverviewDescription
  links: ${linksMatrixGql()}
  searchFieldPlaceholder
  relatedProductCategories(orderBy: "lft asc"){
    id
    level
  }
  ${landingPageContentGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const pageQuery = `query($site: String, $uri: String) {
  category(site: [$site], uri: [$uri]) {
    ${pageGql}
  }
}`;

export const pressReleaseFilterGenerateQuery = function (sitesString = `$site`) {
  return `query {
    categories(
      site: [${sitesString}]
      group: ["pressRelease"]
    ) {
      groupHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};

export const resultsQuery = `query($id: QueryArgument, $orderBy:String, $site: String) {
  entries(
    pressReleaseFilterPages: [$id]
    orderBy: $orderBy
    section: "pressRelease"
    site: [$site]
    type: "pressRelease"
  ) {
    title
    url
    entryPostDate: postDate @formatDateTime (format: "F d, Y")
    filterByDate: postDate  @formatDateTime (format: "D M d Y H:i:s O")
    typeHandle
    ... on pressRelease_pressRelease_Entry {
      metaImage:blogFeaturedImage{
        ... on blogNews_Asset{
          title
          square: filterSquare {
            srcset
            srcsetWebp
          }
          landscape: relatedEntries {
            srcset
            srcsetWebp
          }
        }
        ... on images_Asset {
          title
          square: aspectRatio11 {
            srcset
            srcsetWebp
          }
        }
      }
      pressReleaseFilters(level: 2) {
        slug
        parent {
          slug
        }
      }
      pressReleaseFilterPages {
        title
      }
      blogContent {
        ... on blogContent_text_BlockType {
          text
        }
      }
    }
  }
}`;
