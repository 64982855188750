<template>
  <Footer class="c_global_footer" v-if="footer">
    <div class="c_wrapper">
      <Grid element-type="nav" class="c_global_footer__footer_menu">
        <div
          class="c_global_footer__footer_menu__group"
          v-for="(menuGroup, menuGroupIndex) in footer.footerMenu"
          :key="menuGroupIndex"
        >
          <Button
            v-if="$mq !== 'lg' && menuGroup.menuItems && menuGroup.menuItems[0]"
            class="c_global_footer__footer_menu__group__button c_text--graphik_bold"
            :class="[
              menuGroupIndex === 0 ? 'c_global_footer__footer_menu__group__button--first' : '',
              activeFooterMobileIndex === menuGroupIndex ? 'c_global_footer__footer_menu__group__button--active' : '',
            ]"
            @onClick="toggleFooterMobileNav(menuGroupIndex)"
            :aria-expanded="activeFooterMobileIndex === menuGroupIndex ? 'true' : 'false'"
            unstyle
            reset
          >
            <span class="c_global_footer__footer_menu__group__button__text">{{
              parseLinkField(menuGroup.menuItems[0], 'superTable').labelText
            }}</span>
            <IconSVG
              v-if="activeFooterMobileIndex !== menuGroupIndex"
              handle="arrow_down"
              height="10"
              width="20"
              aria-hidden="true"
            />
            <IconSVG v-else handle="arrow_up" height="10" width="20" aria-hidden="true" />
          </Button>
          <ul
            class="c_global_footer__footer_menu__group__list"
            :class="[
              $mq !== 'lg' ? ['c_global_footer__footer_menu__group__list--mobile'] : '',
              $mq !== 'lg' && activeFooterMobileIndex === menuGroupIndex
                ? 'c_global_footer__footer_menu__group__list--mobile--active'
                : '',
            ]"
          >
            <li
              class="c_global_footer__footer_menu__group__item"
              :class="[
                menuItemIndex === 0 ? 'c_text--graphik_semibold' : '',
                menuItemIndex === menuGroup.menuItems.length - 1
                  ? 'c_global_footer__footer_menu__group__item--last'
                  : '',
              ]"
              v-for="(menuItem, menuItemIndex) in menuGroupMenuItems(menuGroup)"
              :key="menuItemIndex"
            >
              <LinkField
                class="c_global_footer__footer_menu__group__item__link"
                :class="[menuItemIndex === 0 && $mq !== 'sm' ? 'c_text--graphik_semibold' : '']"
                :super-table-field="menuItem"
                :button-attrs="{ unstyle: true, reset: true }"
              />
            </li>
            <div class="c_global_footer__social_links__wrapper" v-if="menuGroup.socialLinks.length">
              <ul class="c_global_footer__social_links">
                <li
                  class="c_global_footer__social_links__link"
                  v-for="(socialLink, socialIndex) in menuGroup.socialLinks"
                  :key="socialIndex"
                  v-if="checkSocialLocale(socialLink)"
                >
                  <Button
                    class="c_global_footer__link"
                    :href="socialLink.socialUrl"
                    :aria-label="socialLink.ariaLabel"
                    :new-window="socialLink.openInANewWindow"
                  >
                    <IconSVG :handle="socialLink.socialLogo" :replacements="{ Logo: $t('Logo') }" />
                  </Button>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </Grid>

      <LocaleSelector v-if="['sm'].includes($mq)" />
      <Flex
        element-type="nav"
        :aria-label="$t('Footer Quick Links')"
        :direction="$mq === 'sm' ? 'column' : 'row'"
        wrap="nowrap"
        :align="$mq === 'sm' ? 'flex-start' : 'center'"
      >
        <ul class="c_global_footer__links">
          <li
            class="c_global_footer__links__link"
            v-for="(link, linkIndex) in footerLinksMatrix(footer.linksMatrix)"
            :key="linkIndex"
          >
            <LinkField
              class="c_global_footer__link c_text--graphik_semibold"
              :matrix-field="link"
              :button-attrs="{ unstyle: true, reset: true }"
            />
            <span class="c_global_footer__divider">|</span>
          </li>
          <li class="c_global_footer__links__link c_global_footer__links__link--last">
            <Button
              class="c_global_footer__link ot-sdk-show-settings c_text--graphik_semibold c_button--reset"
              unstyle
              :label-text="$t('Cookie Preferences')"
              @onClick="toggleOneTrustDisplay"
            />
          </li>
        </ul>
      </Flex>
      <Flex
        wrap="nowrap"
        direction="row"
        justify="space-between"
        align="center"
        class="c_global_footer__copyright_locale"
      >
        <p class="c_global_footer__copyright" v-if="footer.copyrightText">
          {{ footer.copyrightText.replace('[year]', new Date().getFullYear()) }}
        </p>
        <Flex
          v-if="['md', 'lg'].includes($mq)"
          direction="row"
          align="center"
          justify="center"
          class="c_global_footer__locale_selector c_text--center"
        >
          <LocaleSelector />
        </Flex>
      </Flex>
    </div>
  </Footer>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { getFullUrl, log, parseLinkField } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import Flex from 'Components/flex/Flex.vue';
import Grid from 'Components/grid/Grid.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import LocaleSelector from 'Components/locale_selector/LocaleSelector.vue';
import { locale } from '@/nuxtGenerateRoutes.js';

export default defineComponent({
  name: 'GlobalFooter',
  components: {
    Button,
    Flex,
    IconSVG,
    LinkField,
    LocaleSelector,
    Grid,
  },
  setup() {
    const state = reactive({
      activeFooterMobileIndex: null,
    });
    // useFetch(async () => {
    //   let footerData = [];
    //   if (!$preview && payload?.footers) {
    //     footerData = payload.footers;
    //   } else {
    //     const request = await $craft({
    //       apiLog: 'GlobalFooter',
    //       query: `query($site: String) { ${globalFooterGql()} }`,
    //       variables: {
    //         site: store.state.global.localeSites[app.i18n.locale].siteHandle,
    //       },
    //     });
    //     if (request?.data?.entries) {
    //       footerData = request.data.entries;
    //     }
    //   }
    //
    //   for (let e = 0; e < footerData.length; e++) {
    //     const el = footerData[e];
    //     if (el.contentSection.length) {
    //       if (el.contentSection && slugify(el.contentSection[0].title) === route.value?.params?.contentSection) {
    //         footer.value = el;
    //       } else if (el.contentSection && slugify(el.contentSection[0].title) === 'print') {
    //         footer.value = el;
    //       }
    //     }
    //   }
    // });
    //
    // return { activeFooterMobileIndex, devMode, footer };
    return { ...toRefs(state) };
  },
  computed: {
    footer() {
      if (this.$config.craftGlobals?.footers) {
        const localeId = Object.keys(locale).find((key) => locale[key] === this.$i18n.locale);
        const itemsForSite = this.$config.craftGlobals.footers.filter((item) => {
          return item.siteId.toString() === localeId.toString();
        });
        for (let e = 0; e < itemsForSite.length; e++) {
          const el = itemsForSite[e];
          if (el.contentSection.length) {
            if (el.contentSection?.[0]?.slug === this.$route?.params?.contentSection) {
              return el;
            } else if (el.contentSection?.[0]?.slug === 'print') {
              return el;
            }
          }
        }
      }

      return [];
    },
    getContentSection() {
      let returnSlug = null;
      if (this.$route?.params?.contentSection) {
        returnSlug = this.$route.params.contentSection;
      }
      return returnSlug;
    },
    getFullUrl,
  },
  methods: {
    footerLinksMatrix(linksMatrix) {
      if (linksMatrix?.length) {
        return linksMatrix.filter((link) => {
          return parseLinkField(link, 'matrix').valid;
        });
      }
      return [];
    },
    menuGroupMenuItems(menuGroup) {
      return menuGroup.menuItems.filter((menuItem) => {
        return parseLinkField(menuItem, 'superTable').valid;
      });
    },
    parseLinkField,
    toggleFooterMobileNav(menuGroupIndex) {
      if (this.activeFooterMobileIndex === menuGroupIndex) {
        this.activeFooterMobileIndex = null;
      } else {
        this.activeFooterMobileIndex = menuGroupIndex;
      }
    },
    toggleOneTrustDisplay() {
      if (window.OneTrust) {
        log('showing OneTrust info');
        window.OneTrust.ToggleInfoDisplay();
      } else {
        log('OneTrust not loaded');
      }
    },
    checkSocialLocale(socialLink) {
      const currentLocale = this.$i18n.locale;
      if (socialLink.enabledForEnglish && currentLocale === 'en') {
        return true;
      } else if (socialLink.enabledForKorean && currentLocale === 'ko') {
        return true;
      } else if (socialLink.enabledForJapanese && currentLocale === 'ja') {
        return true;
      } else if (socialLink.enabledForChinese && currentLocale === 'zh') {
        return true;
      } else {
        return false;
      }
    },
  },
});
</script>

<style lang="scss">
.c_global_footer {
  $self: &;

  padding-top: px_to_rem(50);
  padding-bottom: px_to_rem(15);
  background-color: $color_black;
  font-size: px_to_rem(14);
  color: $color_gray_191;

  @at-root #{$self}__footer_menu {
    padding-bottom: px_to_rem(40);
    order: 2;
    grid-auto-flow: row;
    @include mq($mq_md_to_lg) {
      grid-auto-flow: column;
    }
    @at-root #{&}__group {
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      display: inline-block; // work around for firefox(what!?) to prevent ul's from breaking into multiple columns.
      @include mq($mq_md_to_lg) {
        &:not(:last-child) {
          padding-right: px_to_rem(25);
        }
      }

      #{&}__button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: px_to_rem(20) 0;
        color: $color_gray_191;
        @at-root #{&}__text {
          padding-right: px_to_rem(20);
          line-height: 1.4;
        }
        @at-root #{&}--first {
          border-top: px_to_rem(1) solid $color_gray_41;
        }
        @at-root #{&}--active,
          &:hover,
          &:focus {
          color: $color_yellow;
        }
        @at-root #{&}--active {
          border-bottom-color: transparent;
        }
      }
      @at-root #{&}__list {
        @at-root #{&}--mobile {
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s ease;
          border-bottom: px_to_rem(1) solid $color_gray_41;
          @at-root #{&}--active {
            max-height: 100vh;
            height: auto;
            padding-top: px_to_rem(20);
            padding-bottom: px_to_rem(20);
          }
        }
      }
      @at-root #{&}__item {
        text-decoration: none;
        padding-bottom: px_to_rem(10);
        &:first-child {
          font-size: 1rem;
          @include mq($mq_md_to_lg) {
            padding-bottom: px_to_rem(30);
          }
        }
        @at-root #{&}--last {
          padding-bottom: 0;
        }
        @at-root #{&}__link {
          text-decoration: none;
          color: $color_gray_191;
          &:hover,
          &:focus {
            color: $color_yellow;
          }
        }
      }
      @at-root #{&}__list {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        @include mq($mq_md_to_lg) {
          height: 100%;
          margin-bottom: px_to_rem(80);
        }
      }
    }
  }

  @at-root #{$self}__link {
    text-decoration: none;
    color: $color_gray_191;

    @at-root #{&}:hover,
      #{&}:focus {
      color: $color_yellow;
    }
  }
  @at-root #{$self}__dev_links {
    margin-top: px_to_rem(50);

    @at-root #{&}__link {
      display: inline-block;
      padding: px_to_rem(10);

      &:first-child {
        padding-left: 0;
      }
    }
  }

  @at-root #{$self}__social_links {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    @at-root #{&}__wrapper {
      order: 1;
      flex: 0 0 auto;
      display: flex;
      align-items: flex-end;
      margin-top: px_to_rem(20px);
      @include mq($mq_md_to_lg) {
        margin-bottom: px_to_rem(80);
        margin-top: auto;
        order: 2;
      }
    }

    @at-root #{&}__link {
      margin: 0 px_to_rem(15) px_to_rem(15) 0;
      box-sizing: content-box;
      & > a {
        color: $color_white;
        width: px_to_rem(50);
        height: px_to_rem(50);
        background-color: $color_gray_41;
        border-radius: 50%;
        display: flex;
        align-items: center;
        svg {
          max-width: 28px;
          max-height: 32px;
        }
        .c_icon_svg {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @include mq($mq_sm_to_md) {
        max-width: px_to_rem(30);
        padding-right: px_to_rem(20);
      }
    }
  }
  @at-root #{$self}__links {
    width: 100%;
    padding-top: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      padding-top: 0;
    }
    @at-root #{&}__link {
      display: inline-block;
      padding-bottom: px_to_rem(15);
      @include mq($mq_sm_to_md) {
        padding-bottom: 0;
      }
    }
    @at-root #{$self}__divider {
      padding: 0 px_to_rem(15);
      user-select: none;
    }
  }
  @at-root #{$self}__copyright_locale {
    .c_locale_selector {
      width: 100%;
    }
    padding-top: px_to_rem(15);
    border-top: px_to_rem(1) solid $color_gray_41;
    @include mq($mq_sm_to_md) {
      margin-top: px_to_rem(15);
    }
  }
  @at-root #{$self}__copyright_container {
    background-color: $color_red_218;
    color: $color-white;
  }
}
</style>
