<template>
  <Button
    unstyle
    reset
    :class="toggleClass || null"
    @onClick="toggleSearchState()"
    aria-controls="global-search-box"
    :aria-label="getSearchState ? 'Close search form' : 'Open search form'"
    :aria-expanded="getSearchState ? 'true' : 'false'"
  >
    <IconSVG
      v-show="!getSearchState"
      class="c_global_search_toggle__icon"
      :height="toggleIconHeight"
      :width="toggleIconWidth"
      stroke-width="5px"
      handle="search"
      aria-hidden="true"
    />
    <IconSVG
      v-show="getSearchState"
      class="c_global_search_toggle__icon"
      :height="toggleIconHeight"
      :width="toggleIconWidth"
      handle="close"
      aria-hidden="true"
    />
  </Button>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default defineComponent({
  name: 'GlobalSearchToggle',
  components: {
    Button,
    IconSVG,
  },
  computed: {
    getSearchState() {
      return this.$store.state.global.searchOpen || false;
    },
  },
  props: {
    toggleClass: String,
    toggleIconHeight: { type: String, default: '18' },
    toggleIconWidth: { type: String, default: '19' },
  },
  methods: {
    toggleSearchState() {
      this.$store.commit('global/updatedSearchOpen', !this.getSearchState);
      this.$store.commit('global/updateShowMobileNav', false);
      this.$store.commit('global/changeAllowDrawersToOpen', !this.getSearchState);
    },
  },
  watch: {},
});
</script>

<style lang="scss">
.c_global_search_toggle {
  @at-root #{&}__icon {
    margin-bottom: 1px;
    > svg {
      overflow: visible;
    }
  }
}
</style>
