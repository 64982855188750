export const carouselGql = `... on landingPageContent_carousel_BlockType{
    typeHandle
    colors
    images: carouselImages{
        ... on carouselImages_BlockType{
            image{
                title
                ... on landingPageImages_Asset{
                    lpBackgroundWide{
                        srcset
                        srcsetWebp
                    }
                }
            }
            caption
        }
    }
}`;
