<template>
  <div class="c_locale_selector">
    <Button
      class="c_locale_selector__toggle"
      @onClick="$refs.overlay.show()"
      unstyle
      reset
      :aria-label="buttonAriaLabel || null"
    >
      <IconSVG
        v-show="appearance === 'default'"
        class="c_locale_selector__toggle__icon"
        handle="globe"
        aria-hidden="true"
      />
      <span
        class="c_locale_selector__toggle__title"
        :class="{ 'c_locale_selector__toggle__title--dropdown': appearance === 'dropdown' }"
        >{{ localeTitle }}</span
      >
      <span v-show="appearance === 'dropdown'" aria-hidden="true">▼</span>
    </Button>
    <Overlay show-close-button width="500px" ref="overlay">
      <div class="c_locale_selector__wrapper c_text--black" v-if="localeGroups">
        <div class="c_locale_selector__wrapper__title">{{ $t('Select Your Language') }}</div>
        <div class="c_locale_selector__groups">
          <div class="c_locale_selector__group" v-for="group in localeGroups" :key="group.title">
            <p class="c_locale_selector__group__title" v-if="group.title">{{ group.title }}</p>
            <Button
              reset
              class="c_locale_selector__button"
              @onClick="updateLocale(locale.id)"
              v-for="locale in group.locales"
              :key="locale.id"
            >
              <div class="c_locale_selector__button__wrapper">
                <div class="c_text--graphik_semibold">{{ locale.title }}</div>
                <div class="c_text--graphik_regular">{{ locale.language }}</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { defineComponent, ref, useStore } from '@nuxtjs/composition-api';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Overlay from 'Components/overlay/Overlay.vue';

export default defineComponent({
  components: {
    Button,
    IconSVG,
    Overlay,
  },
  props: {
    appearance: {
      type: String,
      default: 'default',
      required: false,
    },
  },
  setup() {
    const store = useStore();
    const languages = ref({
      en: 'English',
      ja: 'Japan',
      ko: 'Korea',
      zh: 'China',
    });
    const localeGroups = ref([]);

    Object.keys(languages.value).forEach((id) => {
      const validLocales = [];

      // If ID can be found in localeSites, add it to the list
      if (store.state.global.localeSites[id]) {
        validLocales.push({
          id,
          language: languages.value[store.state.global.localeSites[id].language],
          title: store.state.global.localeSites[id].title,
        });
      }

      // If site has at least one valid locale, display it in selector
      if (validLocales.length) {
        localeGroups.value.push({
          title: id.title,
          locales: validLocales,
        });
      }
    });

    return { languages, localeGroups };
  },
  computed: {
    buttonAriaLabel() {
      return `${this.languages[this.$i18n.locale]}, ${this.$t('Change Locale')}`;
    },
    localeTitle() {
      return this.languages[this.$i18n.locale];
    },
  },
  methods: {
    updateLocale(localeId) {
      if (this.$i18n.locale !== localeId) {
        /* const newPath = this.$route.fullPath.replace(this.$i18n.locale, localeId);
        this.$router.push(newPath === '/' ? `/${localeId}/` : newPath); */
        this.$router.push(`/${localeId}/`);
      }
      if (this.$refs.overlay) {
        this.$refs.overlay.hide();
      }
    },
  },
});
</script>

<style lang="scss">
.c_locale_selector {
  $self: &;

  @at-root #{$self}__toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0px;
    .c_global_header & {
      border-bottom: 0.25rem solid transparent;
    }
    .c_navigation_mobile__locales & {
      color: $color_gray_86;
    }
    @at-root #{&}:hover {
      color: $color_yellow;
      .c_global_header & {
        color: $color_black;
        border-bottom: 0.25rem solid $color_white;
      }
    }
    @at-root #{&}__icon {
      width: px_to_rem(25);
      box-sizing: content-box;
      padding-right: px_to_rem(10);
      padding-top: 3px;
      padding-bottom: 1px;
      padding-left: 3px;
    }
    @at-root #{&}__title {
      font-size: 1rem;
      margin-right: 20px;
      @at-root #{&}--dropdown {
        margin-right: 12px;
      }
      .c_global_header & {
        @include font('graphik_medium');
      }
    }
  }
  @at-root #{$self}__wrapper {
    padding: 45px 10vw;
    background-color: $color_white;

    @include mq($mq_sm_to_md) {
      padding: 45px 70px;
    }

    @at-root #{&}__title {
      margin-bottom: 1.7rem;
      @include font('graphik_bold');
      font-size: 1.48rem;
    }
  }
  @at-root #{$self}__groups {
    column-count: var(--column-count, 1);

    @include mq($mq_sm_to_md) {
      // @TODO re-add if more lacoles are added
      column-count: 2;
    }
  }
  @at-root #{$self}__group {
    width: 100%;
    break-inside: avoid;

    @at-root #{&} + #{&} {
      margin-top: 3rem;
    }

    @at-root #{&}__title {
      text-transform: uppercase;
      @include font('graphik_semibold');
      font-size: 1.1rem;
    }
  }
  @at-root #{$self}__button {
    margin-top: 1em !important;
    width: 100% !important;
    color: $color_black;

    @at-root #{&}__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 32px;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      padding: 12px 18px;
      background-color: $color_gray_235;
      border: none;
      border-radius: $border_radius;
      text-align: left;
      font-size: 0.8rem;
      padding-bottom: 10px;

      & > *:last-child {
        text-align: right;
      }

      &:hover {
        background-color: $color_gray_191;
      }
    }
  }
}
</style>
