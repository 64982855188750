export const disclaimerFootnoteGql = `
... on landingPageContent_disclaimerFootnote_BlockType{
  typeHandle
  disclaimerFootnote{
    ... on disclaimerFootnote_disclaimerFootnote_Entry{
      content:disclaimercontent
    }
  }
  colors
}
`;
