<template>
  <div
    class="app"
    :class="[
      isEdge15orDown ? 'app--isEdge15orDown' : '',
      { 'app--navigation-dark': globalDarkTheme },
      { 'app--mobile-navigation-open': showMobileNav && ['sm', 'md'].includes($mq) },
    ]"
  >
    <GlobalHeader />
    <main class="app__view" tabindex="-1" :class="{ 'app__view--visible': appHydrated }">
      <div
        class="c_navigation_mobile__mask c_box--mask"
        :class="showMobileNav && ['sm', 'md'].includes($mq) ? 'c_navigation_mobile__mask--active' : ''"
      ></div>
      <nuxt />
    </main>
    <GlobalFooter />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { mapState } from 'vuex';
import { faviconVueMeta } from 'JS/favicons.js';
import GlobalHeader from 'Components/global_header/GlobalHeader.vue';
import GlobalFooter from 'Components/global_footer/GlobalFooter.vue';
import { navigateToHash } from 'JS/global.js';

export default defineComponent({
  name: 'LayoutDefault',
  scrollToTop: false,
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      title: 'Kodak',
      titleTemplate: null,
      ...faviconVueMeta,
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
    };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
  },
  computed: {
    ...mapState({
      appHydrated: (state) => state.global.appHydrated,
      isEdge15orDown: (state) => state.global.isEdge15orDown,
      showMobileNav: (state) => state.global.showMobileNav,
      globalDarkTheme: (state) => state.global.globalDarkTheme,
    }),
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      if (this.$route?.hash) {
        navigateToHash({ hash: this.$route?.hash, delay: 900 });
      }
      this.$store.commit('global/updateAppHydrated', true);
      window.addEventListener(
        'message',
        ({ data }) => {
          if (data === 'livepreview' && this.$preview) {
            this.$nuxt.refresh();
          }
        },
        false
      );
    }
  },
});
</script>

<style lang="scss">
.page-enter-active {
  transition: opacity 0.3s;
}
.page-enter {
  opacity: 0;
}
.app {
  $self: &;
  position: relative;

  @at-root #{$self}__view {
    min-height: calc(100vh - #{$ui_masthead_height} - #{$ui_footer_height});
    opacity: 0;
    transition: opacity 0.4s ease-in;

    @at-root #{&}--visible {
      opacity: 1;
    }

    @at-root #{$self}--mobile-navigation-open & {
      transform: translate3d(0, #{px_to_rem(60)}, 0);
    }
  }

  @at-root #{$self}--mobile-navigation-open {
    max-height: 100vh;
    overflow: hidden;
  }

  &:not(.app--isEdge15orDown) {
    ::selection {
      background-color: rgba($color_yellow_raw, 0.5);
      color: $color_black;
    }
    .c_box--black,
    .c_box--yellow {
      ::selection {
        background-color: rgba($color_white_raw, 0.9);
        color: $color_black;
      }
    }
  }
}
</style>
