export const heroBanner2023Gql = `... on landingPageContent2023_heroBanner_BlockType {
  typeHandle
  desktopBannerHeight
  desktopBannerAspectRatio
  mobileBannerHeight
  mobileBannerAspectRatio
  indicatorType
  autoplaySlides
  slideDuration
  slide {
    ... on slide_BlockType {
      slideName
      slideIcon {
        ... on svgIcons_Asset {
          title
          url
        }
      }
      contentEyebrow
      contentHeader
      contentDescription
      primaryCtaBtn {
        ariaLabel
        customText
        target
        text
        title
        type
        url
      }
      primaryCtaBtnLabel
      primaryCtaBtnAriaLabel
      primaryCtaBtnTheme
      primaryCtaBtnOpenInANewWindow
      secondaryCtaBtn {
        ariaLabel
        customText
        target
        text
        title
        type
        url
      }
      secondaryCtaBtnLabel
      secondaryCtaBtnAriaLabel
      secondaryCtaBtnTheme
      secondaryCtaBtnOpenInANewWindow
      backgroundVideo {
        id
        ... on videoUploads_Asset {
          url
          size
          extension
        }
      }
      contentImage {
        id
        ... on images_Asset {
          title
          lpImageTextUncropped {
            src
            srcset
          }
          aspectRatio11 {
            src
            srcset
          }
        }
      }
      contentImageHeightDesktop
      contentPositionDesktop
      contentPositionMobile
      stackTextImageMobile
      colorTheme
      backgroundImageDesktop {
        ... on images_Asset {
          title
          lpImageTextUncropped {
            src
            srcset
          }
          lpBackgroundWide {
            src
            srcset
          }
        }
        ... on landingPageImages_Asset {
          title
          lpImageTextUncropped {
            src
            srcset
          }
          lpBackgroundWide {
            src
            srcset
          }
        }
      }
      backgroundImageMobile {
        ... on images_Asset {
          title
          lpImageTextUncropped {
            src
            srcset
          }
          lpBackgroundWide {
            src
            srcset
          }
        }
        ... on landingPageImages_Asset {
          title
          lpImageTextUncropped {
            src
            srcset
          }
          lpBackgroundWide {
            src
            srcset
          }
        }
      }
      backgroundOverlayOpacity
      contentBackgroundBoxEnabled
      enableTextShadows
      textShadowOffset
      textShadowBlurStrength
    }
  }
}`;
