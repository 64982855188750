export const featuredContent2023Gql = `... on landingPageContent2023_featuredContent_BlockType {
  typeHandle
  pageTitle
  displayInHeaderQuicklinks
  displayArticleSummary
  backgroundColor
  featuredContent {
    postDate
    title
    typeHandle
    ... on blog_blog_Entry {
      uri
      relatedEntryDescription
      blogFeaturedImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on blogNews_Asset {
          filterPageFeaturedImage {
            srcWebp
            srcset
          }
        }
      }
      blogContent {
        ... on blogContent_text_BlockType {
          text
        }
      }
    }
    ... on pressRelease_pressRelease_Entry {
      uri
      relatedEntryDescription
      blogContent {
        ... on blogContent_text_BlockType {
          text
        }
      }
      blogFeaturedImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on blogNews_Asset {
          filterPageFeaturedImage {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on event_event_Entry {
      uri
      eventStartDateTime
      relatedEntryDescription
      eventFeaturedImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on event_Asset {
          filterPageFeaturedImage {
            srcWebp
            srcset
          }
        }
      }
      eventsCategory {
        slug
      }
    }
    ... on event_eventV2_Entry {
      uri
      relatedEntryDescription
      eventFeaturedImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
      }
      eventsCategory {
        slug
      }
    }
    ... on product_productPage_Entry {
      uri
      relatedEntryDescription
      productFeaturedImage {
        ... on productImages_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on product_productPage2022_Entry {
      uri
      relatedEntryDescription
      productFeaturedImage {
        ... on productImages_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on landingPage_landingPage_Entry {
      uri
      relatedEntryDescription
      landingPageFeaturedImage {
        ... on landingPageImages_Asset {
          lpImageGridLandscape {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on landingPage_landingPage2023_Entry {
      uri
      relatedEntryDescription
      landingPageFeaturedImage {
        ... on landingPageImages_Asset {
          lpImageGridLandscape {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on blog_customerStory_Entry {
      uri
      relatedEntryDescription
      blogFeaturedImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
        }
        ... on blogNews_Asset {
          filterPageFeaturedImage {
            srcWebp
            srcset
          }
        }
      }
    }
  }
}`;
