export const basicImageGql = `... on landingPageContent_basicImage_BlockType {
  typeHandle
  imageType
  imageHeightRestraint
  caption
  colors
  image{
    title
    ... on landingPageImages_Asset{
      lpImageTextUncropped {
        srcset
        srcsetWebp
      }
    }
  }
}`;
