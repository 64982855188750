export const promoBlockGql = `... on landingPageContent2023_promo_BlockType {
  typeHandle
  promoEntry {
    typeHandle
    ... on event_eventV2_Entry {
      promoTabStyle
      promoCardStyle
      promoDescription
      promoTitle
      promoImage {
        ... on images_Asset {
          lpImageTextUncropped {
            optimizedImageUrls
            srcset
            srcWebp
          }
          lpImageTextUncropped {
            optimizedImageUrls
          }
        }
      }
      eventFilters {
        ... on eventFilters_Category {
          slug
          title
          parent {
            slug
          }
        }
      }
      eventRecordingUrl
      eventStartDateTime @formatDateTime(format: "F j, Y")
      linksMatrix2 {
        ... on linksMatrix2_entry_BlockType {
          buttonLabel
          ariaLabel
          entry {
            uri
          }
          openInANewWindow
        }
        ... on linksMatrix2_custom_BlockType {
          ariaLabel
          buttonLabel
          urlText
          openInANewWindow
        }
        ... on linksMatrix2_asset_BlockType {
          ariaLabel
          buttonLabel
          openInANewWindow
          asset {
            uri
          }
        }
        ... on linksMatrix2_productCategory_BlockType {
          ariaLabel
          buttonLabel
          productCategory {
            uri
          }
          openInANewWindow
        }
      }
    }
    ... on whitePaper_whitePaper_Entry {
      promoTabStyle
      promoEyebrow
      promoCardStyle
      promoDescription
      promoTitle
      promoImage {
        ... on images_Asset {
          lpImageTextUncropped {
            optimizedImageUrls
            srcset
            srcWebp
          }
          lpImageTextUncropped {
            optimizedImageUrls
          }
        }
      }
      linksMatrix2 {
        ... on linksMatrix2_entry_BlockType {
          buttonLabel
          ariaLabel
          entry {
            uri
          }
          openInANewWindow
        }
        ... on linksMatrix2_custom_BlockType {
          ariaLabel
          buttonLabel
          urlText
          openInANewWindow
        }
        ... on linksMatrix2_asset_BlockType {
          ariaLabel
          buttonLabel
          openInANewWindow
          asset {
            uri
          }
        }
        ... on linksMatrix2_productCategory_BlockType {
          ariaLabel
          buttonLabel
          productCategory {
            uri
          }
          openInANewWindow
        }
      }
    }
    ... on interactiveWebglDemo_interactiveWebGLDemo_Entry {
      promoTabStyle
      promoEyebrow
      promoCardStyle
      promoDescription
      promoTitle
      promoImage {
        ... on images_Asset {
          lpImageTextUncropped {
            optimizedImageUrls
            srcset
            srcWebp
          }
          lpImageTextUncropped {
            optimizedImageUrls
          }
        }
      }
      linksMatrix2 {
        ... on linksMatrix2_entry_BlockType {
          buttonLabel
          ariaLabel
          entry {
            uri
          }
          openInANewWindow
        }
        ... on linksMatrix2_custom_BlockType {
          ariaLabel
          buttonLabel
          urlText
          openInANewWindow
        }
        ... on linksMatrix2_asset_BlockType {
          ariaLabel
          buttonLabel
          openInANewWindow
          asset {
            uri
          }
        }
        ... on linksMatrix2_productCategory_BlockType {
          ariaLabel
          buttonLabel
          productCategory {
            uri
          }
          openInANewWindow
        }
      }
    }
  }
}`;
