export const textListGql = `... on landingPageContent_textList_BlockType{
    typeHandle
    eyebrow
    header
    text
    image{
        title
        ... on landingPageImages_Asset{
            caption
            lpImageTextUncropped{
                srcset
                srcsetWebp
            }
        }
    }
    list{
        ... on list_BlockType{
            bullet
            bulletIcon{
                title
                url
            }
            header
            text
        }
    }
    layout
    colors
}`;
