export const productTabRelatedGql = `... on product_relatedTab_Entry {
  tabTitle
  tabHeader
}
parent {
  ... on product_productPage_Entry {
    productCategories(orderBy: "lft asc") {
      id
      level
    }
  }
}`;
