import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const pageHeaderGql = `... on landingPageContent_pageHeader_BlockType {
  typeHandle
  header
  subheader
  textAlignment
  colors
  backgroundImage {
    ... on landingPageImages_Asset {
      title
      lpBackgroundSquare {
        srcset
        srcsetWebp
      }
      lpBackgroundWide {
        srcset
        srcsetWebp
      }
    }
  }
  ${linksSuperTableGql('pageHeaderButton')}
}`;
