export const videoGallery2023Gql = `... on landingPageContent2023_videoGallery_BlockType {
  typeHandle
  pageTitle
  displayInHeaderQuicklinks
  videos {
    ... on videos_Asset {
      videoId
      videoSource
      videoType
      videoDescription
      videoDuration
      galleryEnlarged {
        srcWebp
        srcset
      }
      galleryThumbnail {
        srcWebp
        srcset
      }
    }
  }
}`;
