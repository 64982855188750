import { linksMatrixGql } from '../link_field/linksMatrixGql.js';

export const textAndContent2023Gql = `... on landingPageContent2023_textContentCards_BlockType {
  typeHandle
  pageTitle
  displayInHeaderQuicklinks
  roundedCorners
  moviePosters
  colors
  cardAssets {
    ... on images_Asset {
      cardDescription
      cardTitle
      title
      altText
      lpImageTextUncropped {
        srcWebp
        srcset
      }
      ${linksMatrixGql()}
    }
  }
}`;
