<template>
  <component
    :is="tag || 'div'"
    class="c_icon_svg"
    v-bind="svgAttributes"
    v-html="svgHtml || null"
    @click="$emit('click')"
  ></component>
</template>

<script>
import * as svg from 'JS/automated/svg.js';

export default {
  props: {
    background: { type: Boolean, default: false },
    backgroundSize: String,
    color: String,
    colorVar: String,
    handle: { type: String, required: true },
    height: String,
    image: { type: Boolean, default: false },
    replacements: { type: Object },
    strokeColor: String,
    strokeColorVar: String,
    strokeWidth: String,
    tag: { type: String, default: 'div', required: false },
    width: String,
  },
  computed: {
    colorVarString() {
      return this.colorVar ? `var(--color-${this.colorVar})` : null;
    },
    strokeColorString() {
      return this.strokeColorVar ? `var(--color-${this.strokeColorVar})` : null;
    },
    svgAttributes() {
      let attrs = {
        style: {},
      };
      const classes = [];

      if (this.background) {
        attrs = {
          style: {
            backgroundImage: svg.background(this.handle, this.replacements || {}),
            backgroundSize: this.backgroundSize || null,
          },
        };
      } else if (this.image) {
        attrs = {
          is: 'img',
          src: svg.src(this.handle, this.replacements || {}),
        };
      } else {
        if (this.color || this.colorVar) {
          classes.push('c_icon_svg--color');
          attrs.style['--color'] = this.colorVarString || this.color;
        }
        if (this.strokeColor || this.strokeColorVar) {
          classes.push('c_icon_svg--stroke--color');
          attrs.style['--stroke-color'] = this.strokeColorString || this.stroke;
        }
        if (this.strokeWidth) {
          classes.push('c_icon_svg--stroke--width');
          attrs.style['--stroke-width'] = this.strokeWidth;
        }
      }

      if (this.height) {
        attrs.style.height = `${this.height}px`;
      }
      if (this.width) {
        attrs.style.width = `${this.width}px`;
      }

      if (classes.length) {
        attrs.class = classes;
      }

      return attrs;
    },
    svgHtml() {
      return !this.background && !this.image && this.handle ? svg.html(this.handle, this.replacements || {}) : null;
    },
  },
};
</script>

<style lang="scss">
.c_icon_svg {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.15s ease-in-out;

  & > svg {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  @at-root #{&}--color {
    @include svg_color(#{var(--color)}, $circle: true, $path: true, $polygon: true, $rect: true);
  }
  @at-root #{&}--stroke--color {
    @include svg_stroke($color: #{var(--stroke-color)});
  }
  @at-root #{&}--stroke--width {
    @include svg_stroke($width: #{var(--stroke-width)});
  }
}
</style>
