export const quoteSliderGql = `... on landingPageContent_quoteSlider_BlockType{
  typeHandle
  textAlignment
  colors
  slides{
    ... on slides_BlockType{
        eyebrow
        quote
        quoteAuthor
        descriptor
        imagePosition
        image{
          title
          url
          ... on landingPageImages_Asset{
            caption
            lpBackgroundSquare{
              srcset
              srcsetWebp
            }
            lpBackgroundWide{
              srcset
              srcsetWebp
            }

          }
        }
    }
  }
}`;
