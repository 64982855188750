import { log } from 'JS/global.js';

const actionItems = {};
const mutationItems = {};
const stateItems = {};

/*
 * Determine if app has hydrated and ready for browsing
 */
// USAGE: $store.state.global.appHydrated
stateItems.appHydrated = false;
// USAGE: $store.commit('global/updateAppHydrated', arg)
mutationItems.updateAppHydrated = function (state, changeBooleanTo = true) {
  state.appHydrated = changeBooleanTo;
};

/*
 * UI Drawer state
 */
// USAGE: $store.state.global.showMobileNav
stateItems.showMobileNav = false;
// USAGE: $store.commit('global/updateShowMobileNav', arg)
mutationItems.updateShowMobileNav = function (state, changeBooleanTo) {
  log('Change Show Mobile Nav To: ', changeBooleanTo);
  state.showMobileNav = changeBooleanTo;
};
// USAGE: $store.state.global.allowDrawersToOpen
stateItems.allowDrawersToOpen = true;
// USAGE: $store.commit('global/changeAllowDrawersToOpen', arg)
mutationItems.changeAllowDrawersToOpen = function (state, changeBooleanTo) {
  log('Change Allow Drawers To Open To: ', changeBooleanTo);
  state.allowDrawersToOpen = changeBooleanTo;
};

// USAGE: $store.state.global.searchOpen
stateItems.searchOpen = false;
// USAGE: $store.commit('global/updatedSearchOpen', arg)
mutationItems.updatedSearchOpen = function (state, changeBooleanTo) {
  state.searchOpen = changeBooleanTo;
};

/*
 * Browser Detect - For Edge 15 and down
 */
stateItems.browserIs = process.client ? window.navigator.userAgent : '';

/*
 * Countries, Used in the Sonora ROI Estimator form
 */
stateItems.countries = [
  // US
  { label: 'United States', currency: 'usd', locales: ['en-us'] },
  // Canada
  { label: 'Canada', currency: 'cad', locales: [] },
  // UK
  { label: 'United Kingdom', currency: 'gbp', locales: [] },
  // Asia + Pacific
  { label: 'Korea', currency: 'krw', locales: [] },
  { label: 'Japan', currency: 'jpy', locales: [] },
  { label: 'China', currency: 'cny', locales: [] },
  { label: 'Australia', currency: 'aud', locales: [] },
  { label: 'New Zealand', currency: 'nzd', locales: [] },
  { label: 'India', currency: 'inr', locales: [] },
  { label: 'Singapore', currency: 'sgd', locales: [] },
  { label: 'Malaysia', currency: 'myr', locales: [] },
  { label: 'Indonesia', currency: 'idr', locales: [] },
  { label: 'Philippines', currency: 'php', locales: [] },
  { label: 'Thailand', currency: 'thb', locales: [] },
  { label: 'Viet Nam', currency: 'vnd', locales: [] },
  // Europe
  { label: 'Austria', currency: 'eur', locales: [] },
  { label: 'Belgium', currency: 'eur', locales: [] },
  { label: 'Bulgaria', currency: 'eur', locales: [] },
  { label: 'Croatia', currency: 'eur', locales: [] },
  { label: 'Cyprus', currency: 'eur', locales: [] },
  { label: 'Czechia', currency: 'eur', locales: [] },
  { label: 'Denmark', currency: 'eur', locales: [] },
  { label: 'Estonia', currency: 'eur', locales: [] },
  { label: 'Finland', currency: 'eur', locales: [] },
  { label: 'France', currency: 'eur', locales: [] },
  { label: 'Germany', currency: 'eur', locales: [] },
  { label: 'Greece', currency: 'eur', locales: [] },
  { label: 'Hungary', currency: 'eur', locales: [] },
  { label: 'Italy', currency: 'eur', locales: [] },
  { label: 'Ireland', currency: 'eur', locales: [] },
  { label: 'Latvia', currency: 'eur', locales: [] },
  { label: 'Lithuania', currency: 'eur', locales: [] },
  { label: 'Luxembourg', currency: 'eur', locales: [] },
  { label: 'Malta', currency: 'eur', locales: [] },
  { label: 'Netherlands', currency: 'eur', locales: [] },
  { label: 'Norway', currency: 'eur', locales: [] },
  { label: 'Poland', currency: 'eur', locales: [] },
  { label: 'Portugal', currency: 'eur', locales: [] },
  { label: 'Romania', currency: 'eur', locales: [] },
  { label: 'Russia', currency: 'eur', locales: [] },
  { label: 'Slovakia', currency: 'eur', locales: [] },
  { label: 'Slovenia', currency: 'eur', locales: [] },
  { label: 'Spain', currency: 'eur', locales: [] },
  { label: 'Sweden', currency: 'eur', locales: [] },
  { label: 'Switzerland', currency: 'eur', locales: [] },
  { label: 'Turkey', currency: 'eur', locales: [] },
  // EE
  { label: 'Argentina', currency: 'wwd', locales: [] },
  { label: 'Bangladesh', currency: 'wwd', locales: [] },
  { label: 'Brazil', currency: 'wwd', locales: [] },
  { label: 'Bolivia', currency: 'wwd', locales: [] },
  { label: 'Chile', currency: 'wwd', locales: [] },
  { label: 'Colombia', currency: 'wwd', locales: [] },
  { label: 'Costa Rica', currency: 'wwd', locales: [] },
  { label: 'Cuba', currency: 'wwd', locales: [] },
  { label: 'Dominican Republic', currency: 'wwd', locales: [] },
  { label: 'Ecuador', currency: 'wwd', locales: [] },
  { label: 'El Salvador', currency: 'wwd', locales: [] },
  { label: 'French Guiana', currency: 'wwd', locales: [] },
  { label: 'Guadeloupe', currency: 'wwd', locales: [] },
  { label: 'Haiti', currency: 'wwd', locales: [] },
  { label: 'Honduras', currency: 'wwd', locales: [] },
  { label: 'Martinique', currency: 'wwd', locales: [] },
  { label: 'Mexico', currency: 'wwd', locales: [] },
  { label: 'Middle East', currency: 'wwd', locales: [] },
  { label: 'Nicaragua', currency: 'wwd', locales: [] },
  { label: 'Pakistan', currency: 'wwd', locales: [] },
  { label: 'Panama', currency: 'wwd', locales: [] },
  { label: 'Paraguay', currency: 'wwd', locales: [] },
  { label: 'Peru', currency: 'wwd', locales: [] },
  { label: 'Puerto Rico', currency: 'wwd', locales: [] },
  { label: 'Saint Barthelemy', currency: 'wwd', locales: [] },
  { label: 'Saint Martin', currency: 'wwd', locales: [] },
  { label: 'Saint Pierre and Miquelon', currency: 'wwd', locales: [] },
  { label: 'South Africa', currency: 'wwd', locales: [] },
  { label: 'Sri Lanka', currency: 'wwd', locales: [] },
  { label: 'Uraguay', currency: 'wwd', locales: [] },
  { label: 'Venezuala', currency: 'wwd', locales: [] },
];

/*
 * Currencies
 */
stateItems.currencies = {
  usd: { label: '$ US Dollar (US)', symbol: '$', iso2: 'US', locale: 'en' },
  wwd: { label: '$ US Dollar (Worldwide)', symbol: '$', iso2: 'US', locale: 'en' },
  cad: { label: '$ Canadian Dollar', symbol: '$', iso2: 'CA', locale: 'en' },
  eur: { label: '€ Euro', symbol: '€', iso2: 'DE', locale: 'de' },
  gbp: { label: '£ GB Pound', symbol: '£', iso2: 'GB', locale: 'en' },
  krw: { label: '₩ Korean Won', symbol: '₩', iso2: 'KR', locale: 'ko' },
  jpy: { label: '¥ Japanese Yen', symbol: '¥', iso2: 'JP', locale: 'ja' },
  cny: { label: '¥ Chinese Yuan', symbol: '¥', iso2: 'CN', locale: 'zh' },
  aud: { label: 'AU$ Australian Dollar', symbol: 'AU$', iso2: 'AU', locale: 'en' },
  nzd: { label: 'NZ$ New Zealand Dollar', symbol: 'NZ$', iso2: 'NZ', locale: 'en' },
  inr: { label: '₹ Indian Rupee', symbol: '₹', iso2: 'IN', locale: 'bn' },
  sgd: { label: 'S$ Singapore Dollar', symbol: 'S$', iso2: 'SG', locale: 'zh' },
  myr: { label: 'RM Malaysia Ringgit', symbol: 'RM', iso2: 'MY', locale: 'ms' },
  idr: { label: 'Rp Indonesia rupiahs', symbol: 'Rp', iso2: 'ID', locale: 'id' },
  php: { label: '₱ Philippine Pesos', symbol: '₱', iso2: 'PH', locale: 'en' },
  thb: { label: '฿ Thailand Baht', symbol: '฿', iso2: 'TH', locale: 'th' },
  vnd: { label: '₫ Viet Nam Dong', symbol: '₫', iso2: 'VN', locale: 'vi' },
};

/*
 * Locale IDs grouped as they would be displayed in the Locale Selector
 */
stateItems.localeGroups = [
  {
    ids: ['en', 'ja', 'zh', 'ko'],
  },
];

/*
 * Browser Detect - For Edge 15 and down
 */
stateItems.isEdge15orDown = process.client
  ? /(Edge\/(12|13|14|15))/.test(navigator.userAgent) || !!navigator.userAgent.match(/Trident.*rv:11\./)
  : false;

/*
 * Locale information that match up to Craft Sites, and languages in vue-i18n
 * Titles are based on how locales are displayed in the Locale Selector
 */
stateItems.localeSites = {
  en: {
    title: 'English',
    language: 'en',
    siteHandle: 'default',
    currency: 'usd',
  },
  ja: {
    title: 'Japanese',
    language: 'ja',
    siteHandle: 'japanese',
    currency: 'jpy',
    country: 'japan',
  },
  ko: {
    title: 'Korean',
    language: 'ko',
    siteHandle: 'korean',
    currency: 'krw',
    country: 'korea',
  },
  zh: {
    title: 'Chinese',
    language: 'zh',
    siteHandle: 'chinese',
    currency: 'cny',
    country: 'China',
  },
};

/*
 * Customer Locale
 * Grouped together to make it easier to edit together
 */
let localeKey = 'en';
if (process.client) {
  const localeFromStorage = localStorage.getItem('locale:code') || 'en';

  if (stateItems.localeSites[localeFromStorage]) {
    localeKey = localeFromStorage;
  }
}

stateItems.localeRegion = localeKey;
stateItems.localeLanguage = stateItems.localeSites[localeKey].language;
stateItems.localeSiteHandle = stateItems.localeSites[localeKey].siteHandle;
mutationItems.changeLocale = function (state, localeSiteKey) {
  if (stateItems.localeSites[localeSiteKey]) {
    log('Changing locale', localeSiteKey);

    // Update locale in state
    state.localeRegion = localeSiteKey;
    state.localeLanguage = stateItems.localeSites[localeSiteKey].language;
    state.localeSiteHandle = stateItems.localeSites[localeSiteKey].siteHandle;

    // Store locale into browser
    if (process.client) {
      localStorage.setItem('locale:code', localeSiteKey);
    }
  }
};

/*
 * Set global color theme for navigation and breadcrumb styling (light/dark)
 */
// USAGE: $store.state.global.globalDarkTheme -> true/false
stateItems.globalDarkTheme = false;
// USAGE: $store.commit('global/updateGlobalDarkTheme', arg)
mutationItems.updateGlobalDarkTheme = function (state, changeBooleanTo = true) {
  state.globalDarkTheme = changeBooleanTo;
};

/*
 * Set current menu tree
 */
// USAGE: $store.state.global.globalCurrentMobileDrawerTree -> Array
stateItems.globalCurrentMobileDrawerTree = [];
// USAGE: $store.commit('global/updateGlobalCurrentMobileDrawerTree', arg)
mutationItems.updateGlobalCurrentMobileDrawerTree = function (state, uuid = '') {
  if (uuid && uuid !== '') {
    state.globalCurrentMobileDrawerTree.push(uuid);
  }
};
// USAGE: $store.commit('global/reduceGlobalCurrentMobileDrawerTree')
mutationItems.reduceGlobalCurrentMobileDrawerTree = function (state) {
  if (state.globalCurrentMobileDrawerTree && state.globalCurrentMobileDrawerTree.length) {
    state.globalCurrentMobileDrawerTree.splice(-1);
  }
};
// USAGE: $store.commit('global/resetGlobalCurrentMobileDrawerTree')
mutationItems.resetGlobalCurrentMobileDrawerTree = function (state) {
  state.globalCurrentMobileDrawerTree = [];
};

export const actions = actionItems;
export const mutations = mutationItems;
export const state = () => stateItems;
