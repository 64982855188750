<template>
  <Flex
    center
    ref="overlay"
    class="c_overlay"
    :class="overlayClasses"
    @keydown.native.esc="hide"
    :style="overlayStyles"
    tabindex="-1"
    v-if="isActive"
  >
    <div class="c_overlay__mask c_box--mask">
      <div class="c_overlay__content_wrapper" :class="theme ? ['c_overlay__content_wrapper--' + theme] : ''">
        <div class="c_overlay__content" :class="contentClasses" v-if="isActive">
          <Button class="c_overlay__close" @click="hide" v-if="showCloseButton">
            <IconSVG handle="close" width="15" height="15" aria-hidden="true" />
          </Button>
          <slot></slot>
        </div>
      </div>
    </div>
  </Flex>
</template>

<script>
import Flex from 'Components/flex/Flex.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    Flex,
    IconSVG,
  },
  data() {
    return {
      isActive: false,
      triggerElement: false,
    };
  },
  props: {
    // height: { type: String, default: 'calc(100vh - 200px)' },
    rounded: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false },
    theme: { type: String, default: 'default' }, // default, full, hotspot
    // width: { type: String, default: 'calc(100vw - 240px)' },
  },
  computed: {
    contentClasses() {
      const classes = [];

      if (this.theme === 'full') {
        classes.push('c_text--center');
      }
      if (this.scrollable) {
        classes.push('c_overlay__content--scrollable');
      }

      return classes;
    },
    overlayClasses() {
      return [
        `c_overlay--${this.theme}`,
        {
          'c_overlay--active': this.isActive,
          'c_overlay--rounded': this.rounded,
        },
      ];
    },
    overlayStyles() {
      return {
        '--overlay-max-width': this.width || false,
        '--overlay-max-height': this.height || false,
      };
    },
  },
  methods: {
    hide() {
      this.isActive = false;
      this.$emit('overlayHide');
      this.triggerElement.focus();
    },
    show(newState = true) {
      this.isActive = newState;
      this.triggerElement = event.target;
      // log('trigger element', this.triggerElement);
      this.$nextTick(() => {
        // log('ref', this.$refs.overlay[0]);
        // log('element', this.$el);
        this.$el.focus();
        this.$emit('overlayShow');
      });
    },
  },
};
</script>

<style lang="scss">
.c_overlay {
  $self: &;
  margin: -50vh -50vw;
  position: fixed;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: $z_overlay;

  @at-root #{$self}__mask {
    width: 100%;
    height: 100%;
    background-color: rgba($color_black_raw, 0.9);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @at-root #{$self}__content_wrapper {
    position: relative;
    // max-width: var(--overlay-max-width);
    // max-height: var(--overlay-max-height);
    z-index: 2;
    max-width: 70vw;

    @at-root #{&}--video-lightbox {
      width: 900px;
    }

    @at-root #{$self}--default & {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
    @at-root #{$self}--hotspot & {
      width: 100%;
    }
  }
  @at-root #{$self}__content {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    .c_caption {
      flex: 0 1 100%;
    }

    .c_video {
      width: 100%;
    }

    @at-root #{$self}--default & {
      justify-content: center;
      height: 70%;
      //overflow: scroll;
      min-height: 100%;
    }
    @at-root #{$self}--image & {
      justify-content: center;
      width: var(--overlay-max-width);
      height: var(--overlay-max-height);
    }
    @at-root #{$self}--rounded & > * {
      border-radius: 8px;
    }
    @at-root #{&}--scrollable {
      max-height: calc(100vh - 40px);
      // overflow: auto;
    }
  }
  #{$self}__close {
    z-index: 3;
    align-self: flex-end;
    // margin: px_to_rem(-12);
    margin-right: px_to_rem(-16);
    margin-bottom: px_to_rem(16);
    margin-top: px_to_rem(-16);
    background: none;
    border: none;
    color: #91969b;

    &:hover {
      color: white;
      cursor: pointer;
    }
  }
}
</style>
