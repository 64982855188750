import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const galleryGql = `... on landingPageContent_gallery_BlockType{
  typeHandle
  header
  text
  ${linksSuperTableGql('galleryButton')}
  slideIndicators
  slideLayout
  overlayContent
  noGap
  colors
  galleryImages{
    ... on galleryImages_BlockType{
      header
      text
      ${linksSuperTableGql()}
      image{
        ... on landingPageImages_Asset{
          title
          caption
          url
          lpImageGridSquare{
            srcset
            srcsetWebp
          }
          lpImageGridLandscape{
            srcset
            srcsetWebp
          }
          lpGalleryPortrait{
            srcset
            srcsetWebp
          }
        }
        ... on landingPageVideos_Asset{
          title
          caption
          url
          videoId
          videoSource
          lpImageGridSquare{
            srcset
            srcsetWebp
          }
          lpImageGridLandscape{
            srcset
            srcsetWebp
          }
          lpGalleryPortrait{
            srcset
            srcsetWebp
          }
        }
      }
    }
  }
}`;
