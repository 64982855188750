import { landingPageContentGql } from '../matrix_blocks/landingPageContentGql.js';
import { landingPageContent2023Gql } from '../matrix_blocks/landingPageContent2023Gql.js';

const pageGql = `title
typeHandle
... on landingPage_landingPage_Entry {
  relatedProductCategories(orderBy: "lft asc") {
    id
    level
  }
  ${landingPageContentGql}
}
... on landingPage_landingPage2023_Entry {
  ${landingPageContent2023Gql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const landingPageGql = `entry(
  section: ["landingPage"]
  type: ["landingPage", "landingPage2023"]
  site: [$site]
  uri: [$uri]
) {
  ${pageGql}
}`;

export const landingPageGenerateGql = function (sitesString = `$site`, params = {}) {
  const offset = params.offset || '0';
  const limit = params.limit || 'null';

  console.log('Offset: ' + params.offset + ' Limit: ' + params.limit);

  return `query {
    entryCount(
      uri:":notempty:"
      section: ["landingPage"]
      site: [${sitesString}]
      limit: null
    )
    entries(
      uri:":notempty:"
      section: ["landingPage"]
      site: [${sitesString}]
      offset: ${offset}
      limit: ${limit}
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
