import { landingPageContentGql } from '../matrix_blocks/landingPageContentGql.js';
import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';
import { customerStoryGql } from './customerStoryGql.js';

const pageGql = `title
uri
id
... on blog_blog_Entry {
  entryPostDate: postDate @formatDateTime(format: "F d, Y")
  authorName
  articleSummary
  typeHandle
  blogCategory {
    ... on blog_Category {
      title
      uri
    }
  }
  relatedProductCategories(orderBy: "lft asc") {
    id
    level
  }
  blogContent {
    ... on blogContent_text_BlockType {
      typeHandle
      header
      text
    }
    ... on blogContent_image_BlockType {
      typeHandle
      caption
      imageType
      imageHeightRestraint
      image {
        title
        ... on blogNews_Asset {
          blogNewsLandscape {
            srcsetWebp
            srcset
          }
          blogNewsSquare {
            srcsetWebp
            srcset
          }
          blogNewsUncropped{
            srcsetWebp
            srcset
          }
        }
      }
    }
    ... on blogContent_blockquote_BlockType {
      typeHandle
      blockquote
      quoteByline
      quoteBylineDetails
    }
    ... on blogContent_links_BlockType {
      typeHandle
      header
      ${linksSuperTableGql('links')}
    }
    ... on blogContent_mediaContacts_BlockType {
      typeHandle
      header
      mediaContacts {
        ... on mediaContact_mediaContact_Entry {
          title
          descriptor: mediaContactDescriptor
          email
          phoneNumber
        }
      }
    }
  }
  ${landingPageContentGql}
}

${customerStoryGql}

seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const blogGql = `query($site: String, $uri: String) {
  entry(
    section: ["blog"]
    site: [$site]
    uri: [$uri]
  ) {
    ${pageGql}
  }
}`;

export const blogGenerateGql = function (sitesString = `$site`, params = {}) {
  const offset = params.offset || '0';
  const limit = params.limit || 'null';

  console.log('Offset: ' + params.offset + ' Limit: ' + params.limit);

  return `query {
    entryCount(
      uri:":notempty:"
      section: ["blog"]
      site: [${sitesString}]
      limit: null
    )
    entries(
      uri:":notempty:"
      section: ["blog"]
      site: [${sitesString}]
      offset: ${offset}
      limit: ${limit}
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
